import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import State from "./State";
import { notification } from "antd";
import Overlay from "../../atoms/Overlay";

import defaultDashboardStateData from "./defaultState.json";
import {
  initializePendo,
  isGoalsDomain,
  isOrganizationOneascent,
  isProposalPortal,
  setTenantSpecificHeadAttributes,
} from "../../utils/helpers/specialized";
import {
  getFinancialProducts,
  getSubscriptionType,
  getUserData,
  saveUserPreferences,
} from "../../utils/requests/regularApp";
import UI from "../../providers/UI";

const StateProvider = ({ children, organization }) => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [dashboardState, setDashboardState] = useState(
    defaultDashboardStateData
  );

  useEffect(() => {
    setTenantSpecificHeadAttributes(organization);
    setKeyValue("session", uuidv4());

    if (!isProposalPortal()) {
      setKeyValue("orgName", "Goals");
    }
  }, []);

  useEffect(() => {
    setKeyValue("showOverlay", true);

    setUserData().then(data => {
      if (data?._id) {
        if (isGoalsDomain()) {
          initializePendo({ id: data._id }, { id: "Goals" });
        } else if (isProposalUser(data)) {
          if (isOrganizationOneascent(data.organization?.name))
            initializePendo(
              {
                id: data.userManagement.linkedUserManagers[0].email,
                email: data.userManagement.linkedUserManagers[0].email,
                firstname: "",
                lastname: "",
                plantype: "Advisor",
                advisorview: `Client proposal: ${
                  data.personalInfo?.firstName ?? ""
                } ${data.personalInfo?.lastName ?? ""}`,
              },
              { id: `${data.organization?.name} Asset Manager` }
            );
        }

        if (data.preferences?.valueMap?.level2Map) {
          setDashboardState(prevState => ({
            ...prevState,
            investmentAssumptions: {
              ...prevState.investmentAssumptions,
              ...data.preferences.valueMap.level2Map["initial"],
            },
          }));
        }

        getFinancialProducts(data.organization?.name ?? data.orgName)
          .then(data => setKeyValue("productsList", data))
          .catch(console.log);

        if (data.userManagement?.linkedUserManagers?.length) {
          getSubscriptionType(data.userManagement.linkedUserManagers[0].email)
            .then(response => setKeyValue("managerAccess", response?.access))
            .catch(console.log);
        }

        setKeyValue("showOverlay", false);
      } else {
        navigate("/login");
      }
    });
  }, []);

  const closeModal = modalKey => {
    setDashboardState(prevState => ({
      ...prevState,
      [modalKey]: false,
    }));
  };

  const getGoalData = goalName => {
    let goalData = {};
    const level2Map = getPreferenceValue("level2Map");
    const productMap = getPreferenceValue("productMap");

    if (level2Map) {
      goalData = level2Map[goalName];
    }

    if (productMap) {
      goalData = Object.keys(productMap)
        ?.map(key => productMap[key][productMap[key].length - 1])
        ?.find(it => it.goalName === goalName);
    }

    return goalData;
  };

  const getLastUpdatedProductData = () => {
    const allSelectedProducts = getPreferenceValue("level2Map");
    let lastUpdatedProductData;

    if (allSelectedProducts) {
      Object.entries(allSelectedProducts).map(([productName, productData]) => {
        if (
          (!lastUpdatedProductData && productData.updated) ||
          productData.updated > lastUpdatedProductData?.updated
        ) {
          lastUpdatedProductData = {
            productName,
            ...productData,
          };
        }
      });
    }

    return lastUpdatedProductData;
  };

  const getPreferenceValue = searchKey =>
    dashboardState.preferences?.valueMap &&
    dashboardState.preferences.valueMap[searchKey];

  const hideLoader = () => {
    setDashboardState(prevState => ({
      ...prevState,
      loading: false,
    }));
  };

  const isProposalUser = (state = dashboardState) =>
    location.pathname.includes("proposal") ||
    state.userType === "Prospect" ||
    state.userType === "ConvertedProspect";

  const openModal = modalKey => {
    setDashboardState(prevState => ({
      ...prevState,
      [modalKey]: true,
    }));
  };

  const setUserData = () =>
    getUserData()
      .then(data => {
        setDashboardState(prevState => ({
          ...prevState,
          ...data,
        }));

        return data;
      })
      .catch(({ message }) => {
        showWarning(message);
        setTimeout(() => navigate("/login"), 3000);
      });

  const setPreferenceValue = (key, value) => {
    return saveUserPreferences({
      ...dashboardState.preferences,
      valueMap: {
        ...dashboardState.preferences.valueMap,
        [key]: value,
      },
    })
      .then(() => setUserData())
      .catch(error => console.log(error));
  };

  const setKeyValue = (key, value) =>
    setDashboardState(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const showError = errorMessage => {
    api.error({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showLoader = () => {
    setDashboardState(prevState => ({
      ...prevState,
      loading: true,
    }));
  };

  const showSuccess = successMessage => {
    api.success({
      message: successMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showWarning = errorMessage => {
    api.warning({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });
  };

  const mergedDashboardState = {
    ...dashboardState,
    closeModal,
    getLastUpdatedProductData,
    getPreferenceValue,
    getGoalData,
    hideLoader,
    isProposalUser,
    openModal,
    setKeyValue,
    setPreferenceValue,
    setUserData,
    showError,
    showLoader,
    showSuccess,
    showWarning,
  };

  return (
    <State.Provider value={[mergedDashboardState, setDashboardState]}>
      <UI state={dashboardState}>
        <Overlay loading={dashboardState.showOverlay}>{children}</Overlay>
      </UI>
      {contextHolder}
    </State.Provider>
  );
};

export default StateProvider;
