import styled from "styled-components";
import { Flex, Image, Select, Typography } from "antd";

import demo from "./images/demo.png";

const Label = styled(Typography)`
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  color: #192849;
  margin-bottom: 12px;
`;

const StyledSelect = styled(Select)`
  width: 384px;

  &.ant-select-single .ant-select-selector {
    border-color: #707070;
    border-radius: 20px;
    padding-left: 24px;
  }

  &.ant-select .ant-select-arrow {
    color: #707070;
  }
`;

const SectionReturnsRiskVolatility = ({
  ButtonsContainer,
  proposalData,
  updateProposalData,
}) => (
  <Flex gap={50} justify={"space-between"} wrap={"wrap"}>
    <div style={{ maxWidth: 570 }}>
      <h2 className="title" style={{ margin: "30px 0 22px" }}>
        Returns, Risk, Volatility...
      </h2>
      <Flex gap={50} vertical style={{ marginBottom: 70 }}>
        <div>
          <Label>
            An investment decision involves both returns and risk - the higher
            the potential for returns, the greater the risk of highly volatile
            results, including loss. What influences you the most when making an
            important investment decision?
          </Label>
          <StyledSelect
            value={proposalData.decisionInfluence}
            onChange={value => updateProposalData("decisionInfluence", value)}
            options={[
              {
                value: "I am mostly influenced by the potential loss",
                label: "I am mostly influenced by the potential loss",
              },
              {
                value: "I am equally influenced by potential loss or gain",
                label: "I am equally influenced by potential loss or gain",
              },
              {
                value: "I am mostly influenced by the potential gain",
                label: "I am mostly influenced by the potential gain",
              },
            ]}
          />
        </div>
        <div>
          <Label>
            Understanding that volatility has historically been present when
            investing, what is the maximum amount of volatility you can accept
            over a 1 year period and remain invested?
          </Label>
          <StyledSelect
            value={proposalData.volatilityTolerance}
            onChange={value => updateProposalData("volatilityTolerance", value)}
            options={[
              {
                value: "Any decrease in value would concern me",
                label: "Any decrease in value would concern me",
              },
              {
                value: "Up to 10% downside ($100,000 falling to $90,000)",
                label: "Up to 10% downside ($100,000 falling to $90,000)",
              },
              {
                value: "Up to 20% downside ($100,000 falling to $80,000)",
                label: "Up to 20% downside ($100,000 falling to $80,000)",
              },
              {
                value: "Up to 30% downside ($100,000 falling to $70,000)",
                label: "Up to 30% downside ($100,000 falling to $70,000)",
              },
              {
                value:
                  "I would not worry about performance for that short of a period",
                label:
                  "I would not worry about performance for that short of a period",
              },
            ]}
          />
        </div>
        <div>
          <Label>
            When the next downturn in the market comes, how long are you
            prepared to ride out the declining market?
          </Label>
          <StyledSelect
            value={proposalData.decliningMarketWithholdingPeriod}
            onChange={value =>
              updateProposalData("decliningMarketWithholdingPeriod", value)
            }
            options={[
              {
                value: "Less than 1 year",
                label: "Less than 1 year",
              },
              {
                value: "Between 1-5 years",
                label: "Between 1-5 years",
              },
              {
                value: "More than 5 years",
                label: "More than 5 years",
              },
            ]}
          />
        </div>
      </Flex>
      <ButtonsContainer />
    </div>
    <Image height={600} preview={false} src={demo} width={430} />
  </Flex>
);

export default SectionReturnsRiskVolatility;
