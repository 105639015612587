import { useContext } from "react";
import State from "../../../context/user/State";
import styled from "styled-components";
import UI from "../../../../../providers/UI";
import { Flex, Image } from "antd";
import ModalProfile from "../components/ModalProfile";

import mmiUserTheme from "../../../themes/user";

import logo from "../../../images/logo.png";
import { ReactComponent as Slogan } from "../../../images/slogan.svg";
import { ReactComponent as BurgerButton } from "../../../images/burger_button.svg";

const StylesContainer = styled(Flex)`
  padding: 27px 24px;

  & * {
    font-family: Poppins, sans-serif;
  }
`;

const MmiAssetManagerLayout = ({ children }) => {
  const [userState] = useContext(State);

  return (
    <UI themeConfig={mmiUserTheme}>
      <StylesContainer justify={"center"}>
        <Flex style={{ width: "100%", maxWidth: 500 }} vertical>
          <Flex
            align={"center"}
            justify={"space-between"}
            style={{
              marginBottom: 16,
            }}
          >
            <Flex align={"end"} gap={7}>
              <Image preview={false} src={logo} width={120} />
              <div style={{ marginBottom: 4 }}>
                <Slogan />
              </div>
            </Flex>
            <BurgerButton
              className={"pointer"}
              onClick={() => userState.openModal("profileModal")}
            />
          </Flex>
          {children}
        </Flex>

        <ModalProfile
          closeModal={userState.closeModal}
          open={userState.profileModal}
          openModal={userState.openModal}
        />
      </StylesContainer>
    </UI>
  );
};

export default MmiAssetManagerLayout;
