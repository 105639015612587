import { useContext } from "react";
import State from "../../context/manager/State";
import { Button, Flex, Row, theme } from "antd";
import ProspectsTable from "./components/ProspectsTable";
import AnalyticValue from "../../atoms/AnalyticValue";
import ButtonDemoMode from "../../atoms/ButtonDemoMode";

import { getCurrencyValueWithRoundSign } from "../../utils/helpers/general";
import { getManagedUsersTotalProposedAmount } from "../../utils/helpers/specialized";

const PageProspects = ({ instanceLabel = "prospect" }) => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);

  const getTotalAum = () =>
    managerState.getProspectUsers().length
      ? getManagedUsersTotalProposedAmount(managerState.getProspectUsers())
      : 0;

  return (
    <>
      <Row justify={"space-between"}>
        <Flex gap={100} align={"start"} style={{ marginBottom: 20 }}>
          <AnalyticValue
            label={`Total ${instanceLabel}s`}
            value={managerState.getProspectUsers().length}
          />
          <AnalyticValue
            label={"Total Proposed AUM"}
            value={getCurrencyValueWithRoundSign(getTotalAum())}
          />
        </Flex>
        <Flex gap={16}>
          <ButtonDemoMode
            organization={managerState.organization?.name}
            userManagerEmail={managerState._id}
          />
          <Button
            type="primary"
            size="small"
            shape="round"
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
              textTransform: "capitalize",
            }}
            onClick={() => {
              managerState.setKeyValue("activeEmail", null);
              managerState.openModal("addProspectModal");
            }}
          >
            + Add New {instanceLabel}
          </Button>
        </Flex>
      </Row>
      <ProspectsTable instanceLabel={instanceLabel} />
    </>
  );
};

export default PageProspects;
