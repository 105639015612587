import BlockedIcon from "../../../icons/BlockedIcon";
import BoxOpen from "../../../icons/BoxOpen";
import DashboardIconLarge from "../../../icons/DashboardIconLarge";
import DashboardIconSmall from "../../../icons/DashboardIconSmall";
import DocumentPage from "../../../icons/DocumentPage";
import LeadsIcon from "../../../icons/LeadsIcon";
import Proposal from "../../../icons/Proposal";
import User from "../../../icons/User";
import Users from "../../../icons/Users";

export const MENU_ITEMS = {
  access: {
    affiliate: [
      {
        Icon: DashboardIconSmall,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: LeadsIcon,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNext: [
      {
        Icon: DashboardIconSmall,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: LeadsIcon,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateNextProportal: [
      {
        Icon: DashboardIconSmall,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: LeadsIcon,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    affiliateProportal: [
      {
        Icon: DashboardIconSmall,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: LeadsIcon,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    level: [
      {
        Icon: DashboardIconLarge,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        Icon: BoxOpen,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: DocumentPage,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    levelNext: [
      {
        Icon: DashboardIconLarge,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: LeadsIcon,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        Icon: BoxOpen,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        Icon: DocumentPage,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    next: [
      {
        Icon: DashboardIconLarge,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: LeadsIcon,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    nextProporal: [
      {
        Icon: DashboardIconLarge,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: LeadsIcon,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/proposals",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
    proportal: [
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/dashboard",
        text: "Dashboard",
      },
      {
        Icon: LeadsIcon,
        path: "/next-advisor-portal/leads",
        text: "Leads",
      },
      {
        Icon: Users,
        path: "/next-advisor-portal/clients",
        text: "Clients",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/products",
        text: "Products",
      },
      {
        disabled: true,
        Icon: BlockedIcon,
        path: "/next-advisor-portal/marketing",
        text: "Marketing",
      },
    ],
  },
  astor: {
    admin: [
      {
        path: "/astor-admin-portal/advisors",
        Icon: User,
        text: "Advisors",
      },
      {
        path: "/astor-admin-portal/products",
        Icon: BoxOpen,
        text: "Products",
      },
      {
        path: "/astor-admin-portal/proposal",
        Icon: Proposal,
        text: "Proposal",
      },
      {
        path: "/astor-admin-portal/marketing",
        Icon: DocumentPage,
        text: "Marketing",
      },
      {
        path: "/astor-admin-portal/clients",
        Icon: Users,
        text: "Clients",
      },
    ],
    advisor: [
      {
        path: "/astor-advisor-portal/dashboard",
        Icon: User,
        text: "Dashboard",
      },
      {
        path: "/astor-advisor-portal/prospects",
        Icon: Users,
        text: "Prospects",
      },
      {
        path: "/astor-advisor-portal/clients",
        Icon: Users,
        text: "Clients",
      },
      {
        path: "/astor-advisor-portal/products",
        Icon: BoxOpen,
        text: "Products",
      },
      {
        path: "/astor-advisor-portal/marketing",
        Icon: DocumentPage,
        text: "Marketing",
      },
    ],
  },
  disabled: [
    {
      disabled: true,
      Icon: BlockedIcon,
      path: "/next-advisor-portal/dashboard",
      text: "Dashboard",
    },
    {
      disabled: true,
      Icon: BlockedIcon,
      path: "/next-advisor-portal/leads",
      text: "Leads",
    },
    {
      disabled: true,
      Icon: BlockedIcon,
      path: "/next-advisor-portal/proposals",
      text: "Clients",
    },
    {
      disabled: true,
      Icon: BlockedIcon,
      path: "/next-advisor-portal/products",
      text: "Products",
    },
    {
      disabled: true,
      Icon: BlockedIcon,
      path: "/next-advisor-portal/marketing",
      text: "Marketing",
    },
  ],
};
