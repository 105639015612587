import { useContext } from "react";
import State from "../../../../context/user/State";
import styled from "styled-components";
import { Flex, Modal } from "antd";

import { ReactComponent as CloseIcon } from "../../../../images/blue_light_close.svg";

const StyledModal = styled(Modal)`
  .ant-modal .ant-modal-content {
    border-radius: 12px;
  }

  .ant-modal-title {
    line-height: 34px;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    color: #546170;
    margin-bottom: 45px;
  }
`;

const ActionItem = styled.div`
  font-size: 16px;
  color: #21409a;
  text-align: start;
  margin-bottom: 25px;
  cursor: pointer;
`;

const ModalCompetitiveInsight = ({
  activeAssetManagerId,
  handleClose,
  updateAssetManagerId,
  open,
  setActiveAssetManagerId,
}) => {
  const [userState] = useContext(State);
  const advisorData = userState.leagues?.find(
    it => it._id === updateAssetManagerId
  );

  const handleAdd = () => {
    setActiveAssetManagerId(updateAssetManagerId);
    handleClose();
  };

  const handleRemove = () => {
    setActiveAssetManagerId("");
    handleClose();
  };

  return (
    <StyledModal
      closeIcon={<CloseIcon />}
      footer={
        <Flex align={"start"} vertical>
          {activeAssetManagerId &&
          activeAssetManagerId === updateAssetManagerId ? (
            <ActionItem onClick={handleRemove}>
              Remove from
              <br />
              <b>Competitive Insight</b>
            </ActionItem>
          ) : (
            <ActionItem onClick={handleAdd}>
              Add <b>Competitive Insight</b> to
              <br />
              widget below
            </ActionItem>
          )}
          {advisorData?.url && (
            <ActionItem onClick={() => window.open(advisorData.url)}>
              Visit public website
            </ActionItem>
          )}
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={advisorData?.companyName}
      width={342}
    />
  );
};

export default ModalCompetitiveInsight;
