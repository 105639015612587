import Proposal from "../../proposal/templates/Proposal";

import GoalsIcon from "../../../icons/GoalsIcon";

export default [
  {
    path: "/bluepoint-proposal",
    Component: Proposal,
    Icon: GoalsIcon,
    text: "Goals",
  },
];
