import styled from "styled-components";
import { Button, Modal, Row, theme } from "antd";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-body {
    height: 60vh;
    overflow: auto;
  }
`;

const ModalDisclosures = ({ open, handleClose }) => {
  const { token } = theme.useToken();

  return (
    <StyledModal
      open={open}
      footer={
        <Row justify="center">
          <Button
            onClick={handleClose}
            shape="round"
            style={{
              width: 191,
              fontSize: 18,
              backgroundColor: "#192849",
              color: "#FFFFFF",
            }}
          >
            OK
          </Button>
        </Row>
      }
      closeIcon={false}
      style={{ height: 400 }}
    >
      <h3 style={{ textAlign: "center", color: token.colorError }}>
        ELIMINATE
      </h3>
      <p>
        <b>Adult entertainment / Pornography</b> — The production or
        distribution of material related to pornography or similar forms of
        adult-only consumption.
      </p>
      <p>
        <b>Cannabis</b> — The production or distribution of marijuana or
        marijuana-based products.
      </p>
      <p>
        <b>Predatory Lending</b> — Lending to vulnerable individuals or
        communities at unreasonable rates.
      </p>
      <p>
        <b>Human Rights Violations</b> — Activities included but not limited to
        use of child labor, unfair employment practices, or similar oppressive
        acts.
      </p>
      <p>
        <b>Severe Ethics Controversies</b> — Companies which are embroiled in
        some form of ethical breach, which may be to their customers,
        regulators, or society at large.
      </p>
      <p>
        <b>Abortion</b> — Companies that produce, promote or sell
        abortifacients, or perform abortion procedures.
      </p>
      <p>
        <b>Alcohol</b> — The production, distribution, or supply chain of
        alcohol for consumption.
      </p>
      <p>
        <b>Gambling</b> — The production of, distribution of, supply chain of,
        or participation in games of chance.
      </p>
      <p>
        <b>Tobacco</b> — The production of, distribution of, or supply chain of
        tobacco based products.
      </p>

      <h3 style={{ textAlign: "center", color: token.colorGreen }}>ELEVATE</h3>
      <p>
        <b>Flourishing families</b> — Companies whose products or practices
        contribute to healthy dynamics and support for households and/or family
        units
      </p>
      <p>
        <b>Sanctity of life</b> — Companies whose products or practices
        contribute to life-saving strategies for all and the affirmative
        avoidance of methods by which life is ended prematurely.
      </p>
      <p>
        <b>Quality healthcare</b> — Companies whose products or practices
        contribute to health and longevity for their stakeholders, particularly
        where combatting disease or other medically suboptimal conditions
      </p>
      <p>
        <b>Education access</b> — Companies whose products or practices
        contribute to opportunities for formal and informal learning
        advancement, particularly through innovative technology or for
        underserved populations.
      </p>
      <p>
        <b>Dignifying vocations</b> — Companies whose products or practices
        contribute to well- compensating, humanizing jobs with opportunities for
        advancement and growth
      </p>
      <p>
        <b>Economic advancement</b> — Companies whose products or practices
        contribute to monetary and societal success for communities, regions,
        and/or nations.
      </p>
      <p>
        <b>Environmental stewardship</b> — Companies whose products or practices
        contribute to prioritization of natural resources, including but not
        limited to limiting air pollution, conserving lands, and/or minimizing
        waste.
      </p>
      <p>
        <b>Affordable housing</b> — Companies whose products or practices
        contribute to quality, long - term shelter solutions for low, very low,
        or extremely low-income households
      </p>
      <p>
        <b>Viewpoint diversity</b> — Companies whose products or practices
        contribute to vibrant and civil discussion, exchange, dialogue, and/or
        respect surrounding differing opinions and perspectives, particularly
        among employees.
      </p>
      <p>
        <b>Equal opportunity</b> — Companies whose products or practices
        contribute to a level playing field for stakeholders seeking to advance
        their societal or economic position, particularly for those
        traditionally underserved or underrepresented.
      </p>
      <p>
        <b>Thriving communities</b> — Companies whose products or practices
        contribute to safe conditions and/or technological/economic innovation
        that serves, rather than exploits, local communities‘ needs
      </p>
      <p>
        <b>Fair labor</b> — Companies whose products or practices contribute to
        voluntary, consensual, age-appropriate, equitably paid working
        conditions for all, particularly in supply chains.
      </p>
    </StyledModal>
  );
};

export default ModalDisclosures;
