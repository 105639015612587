import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { addDynamicScript } from "../utils/helpers/specialized";

const HUBSPOT_DOMAINS_LIST = ["www.goals.ontrajectory.com"];

const trackHubspotPageView = path => {
  //required to use global variable
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(["setPath", path]);
  _hsq.push(["trackPageView"]);
};

const Trackers = ({ children }) => {
  const locationFromHook = useLocation();

  useEffect(() => {
    // if (HUBSPOT_DOMAINS_LIST.includes(location.hostname)) {
    // initialize Hubspot only in Goals Prod env
    addDynamicScript({
      async: true,
      id: "hs-script-loader",
      src: "//js.hs-scripts.com/22416949.js",
    });
    // }
  }, []);

  useEffect(() => {
    // if (HUBSPOT_DOMAINS_LIST.includes(location.hostname)) {
    trackHubspotPageView(locationFromHook.pathname);
    // }
  }, [locationFromHook]);

  return children;
};

export default Trackers;