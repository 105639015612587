import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../context/user/State";
import styled from "styled-components";
import { Flex, Layout, theme } from "antd";
import MenuGoalsUser from "../components/MenuGoalsUser";
import MenuSidebar from "../../../molecules/MenuSidebar";
import MenuSidebarSub from "../components/MenuSidebarSub";
import ModalContactUs from "../../../organisms/ModalContactUs";
import ModalGoalsUserSettings from "../../../organisms/ModalGoalsUserSettings";
import ModalPennyTerms from "../../../organisms/ModalPennyTerms";
import ModalSupport from "../components/ModalSupport";
import SectionAskAdvice from "../../../organisms/SectionAskAdvice";

import BurgerIcon from "./images/burger.svg";
import CloseIcon from "./images/close_icon.svg";
import { ReactComponent as OtLogoShort } from "../../../images/ot_logo_short.svg";
import { ReactComponent as IconSupport } from "./images/icon_support.svg";

const StyledLayout = styled(Layout)`
  &.ant-layout {
    background: ${({ token }) => token.layoutBackground};
    height: 100vh;
    min-height: 100vh;
  }

  .ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: unset;
  }

  & .ant-layout-header {
    align-items: end;
    background: ${({ token }) => token.layoutBackground};
    display: flex;
    height: 80px;
    padding-bottom: 14px;

    .title {
      color: #1b5568;
      font-size: 20px;
    }
  }

  & .ant-layout-content {
    min-height: 100%;
    padding: 32px;
  }

  &.ant-layout {
    .ant-layout-sider {
      background: ${({ token }) => token.sidebarBackground};
      background-position: left;
      padding: 30px 0 72px;

      .ant-layout-sider-zero-width-trigger {
        top: 30px;
        transition: all 500ms;

        svg {
          display: none;
        }
      }

      &:not(.ant-layout-sider-collapsed) .ant-layout-sider-zero-width-trigger {
        right: 38px;
        background: url(${CloseIcon}) no-repeat center;
      }

      &.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger {
        left: 14px;
        background: url(${BurgerIcon}) no-repeat center;
      }

      .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &.ant-layout.ant-layout-has-sider > .ant-layout,
  &.ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: auto;
    overflow-x: auto;
    padding: 0;
  }

  @media screen and (max-width: ${({ token }) => token.screenMD + "px"}) {
    .ant-layout-header {
      height: fit-content;
      padding: 24px 14px 14px;
      justify-content: center;
    }

    .ant-layout-content {
      padding: 24px 32px;
    }

    .ant-layout-sider {
      position: fixed;
      height: 100vh;
      z-index: 2;

      .ant-layout-sider-children {
        padding-top: 70px;
      }
    }
  }

  @media screen and (max-width: ${({ token }) => token.screenXSMin + "px"}) {
    &.ant-layout {
      height: initial;
    }

    .ant-layout-content {
      padding: 24px 20px;
    }
  }
`;

const GoalsLayout = ({ routes, children }) => {
  const { token } = theme.useToken();
  const [userState, setUserState] = useContext(State);
  const isTablet = useMediaQuery({ maxWidth: token.screenMD });

  const handleSupportButtonClick = () => {
    userState.setKeyValue("openModalSupport", true);
  };

  return (
    <StyledLayout token={token}>
      <Layout.Sider
        breakpoint={"md"}
        collapsedWidth={"0"}
        width={isTablet ? 112 : 80}
      >
        <Flex
          align={"center"}
          gap={isTablet ? 28 : 40}
          style={{ width: "100%" }}
          vertical
        >
          {!isTablet && <OtLogoShort />}
          <Flex style={{ width: "100%" }} vertical>
            <MenuSidebar itemsLayoutVertical routes={routes} />
            {isTablet && <MenuSidebarSub state={userState} />}
          </Flex>
        </Flex>
        <Flex align={"center"} gap={46} justify={"center"} vertical>
          <Flex
            align={"center"}
            className={"pointer"}
            gap={4}
            justify={"center"}
            onClick={handleSupportButtonClick}
            vertical
          >
            <IconSupport />
            <span style={{ color: "#9CABAB", fontWeight: 500 }}>Support</span>
          </Flex>
          <MenuGoalsUser />
        </Flex>
      </Layout.Sider>
      <Layout.Content>{children}</Layout.Content>

      <ModalContactUs />
      <ModalGoalsUserSettings />
      <ModalPennyTerms
        onCancel={() => userState.setKeyValue("openModalPennyTerms", false)}
        open={userState.openModalPennyTerms}
      />
      <ModalSupport
        onCancel={() => userState.setKeyValue("openModalSupport", false)}
        open={userState.openModalSupport}
      />
      <SectionAskAdvice setState={setUserState} state={userState} />
    </StyledLayout>
  );
};

export default GoalsLayout;
