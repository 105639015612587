import styled from "styled-components";
import { Button, Flex, Modal } from "antd";
import CardSponsor from "../CardSponsor";

import { ReactComponent as CloseIcon } from "../../../../images/blue_light_close.svg";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    background: linear-gradient(180deg, #001141 0%, #081c4f 100%);
    border-radius: 12px;
    padding: 16px 16px 4px;
  }

  &.ant-modal .ant-modal-header {
    background: transparent;
  }

  .ant-modal-title {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 48px;
  }

  &.ant-modal .ant-modal-body {
    max-height: 60vh;
    overflow: auto;
  }
`;

const ModalSponsorsList = ({ handleClose, open, sponsorsList }) => (
  <StyledModal
    closeIcon={<CloseIcon />}
    footer={
      <Flex justify={"center"}>
        <Button
          onClick={handleClose}
          shape={"round"}
          size={"small"}
          style={{
            fontFamily: "Poppins, sans-serif",
            fontSize: 16,
            color: "#4EABE9",
          }}
          type={"text"}
        >
          CLOSE
        </Button>
      </Flex>
    }
    onCancel={handleClose}
    open={open}
    title={"All Sponsors"}
    width={342}
  >
    <Flex gap={40} vertical>
      {sponsorsList?.map((it, index) => (
        <CardSponsor key={index} sponsorData={it} />
      ))}
    </Flex>
  </StyledModal>
);

export default ModalSponsorsList;
