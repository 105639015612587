import { useContext } from "react";
import State from "../../../../context/user/State";
import styled from "styled-components";
import { Layout } from "antd";
import ContainerHelpButtons from "../../../../organisms/SectionAskAdvice/components/ContainerHelpButtons";

const HeaderStyled = styled(Layout.Header)`
  align-items: end;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: 668px) {
    border-bottom: 1px solid #c9c8c8;
  }
`;

const Header = ({ children, hideNavbar, style }) => {
  const [userState] = useContext(State);

  const handleAskButtonClick = activeTabName => {
    userState.setKeyValue("openModalAskAdvice", true);
    userState.setKeyValue("sectionAskAdviceActiveTab", activeTabName);
  };

  return (
    <HeaderStyled style={style}>
      <div>{children}</div>
      {!hideNavbar && (
        <ContainerHelpButtons
          handleButtonClick={handleAskButtonClick}
          isAdvisorButtonActive={
            userState.openModalAskAdvice &&
            userState.sectionAskAdviceActiveTab === "advisor"
          }
          isAiButtonActive={
            userState.openModalAskAdvice &&
            userState.sectionAskAdviceActiveTab !== "advisor"
          }
        />
      )}
    </HeaderStyled>
  );
};

export default Header;
