import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import State from "../../../../context/user/State";
import { Empty, Layout } from "antd";
import Header from "../../components/Header";
import ModalDeleteGoal from "../../components/ModalDeleteGoal";
import ModalGoalSettings from "../../../../organisms/ModalGoalSettings";
import ModalMonthlyExpenses from "../../components/ModalMonthlyExpenses";
import ModalOtherGrowthTypes from "../../components/ModalOtherGrowthTypes";
import ModalProductDetails from "../../../../organisms/ModalProductDetails";
import ModalUpdateProgress from "../../components/ModalUpdateProgress";
import SkeletonGoal from "./components/SkeletonGoal";
import WorkspaceGrowthTypeCompare from "../../components/WorkspaceGrowthTypeCompare";
import WorkspaceEmergencySavings from "./components/WorkspaceEmergencySavings";
import WorkspaceGoal from "./components/WorkspaceGoal";

import {
  deleteGoal,
  getLiveAssessment,
  getLiveSimulation,
  updateGoal2progress,
} from "../../../../utils/requests/regularApp";
import { getPercentValue } from "../../../../utils/helpers/general";
import { getRecommendedProductId } from "../../../proposal/pages/PageProposalGoal/helpers";
import { DEFAULT_ADVISOR_FEE } from "../../../../utils/constants";
import { getGoalIdByGoalName } from "../../../../utils/helpers/specialized";
import { getSavedProgressValuesByGoalId } from "../../helpers";

const PageGoal = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const goalName = id.replaceAll("_", " ").replaceAll("%25", "%");
  const [userState, setUserState] = useContext(State);
  const [goalData, setGoalData] = useState();
  const [loading, setLoading] = useState(false);
  const [calculationsData, setCalculationsData] = useState();
  const [simulationsData, setSimulationsData] = useState();
  const [progressValues, setProgressValues] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [compareMode, setCompareMode] = useState(false);
  const [isCompareChartDirty, setIsCompareChartDirty] = useState(false);
  const goalId = getGoalIdByGoalName(
    userState.getPreferenceValue("productMap"),
    goalName
  );

  useEffect(() => {
    userState.setKeyValue("compareProducts", []);
    userState.setKeyValue("selectedProduct", null);
  }, []);

  useEffect(() => {
    if (goalData && id !== "Emergency_Savings") {
      updateTrajectoryData();
    } else {
      userState.hideLoader();
      setLoading(false);
    }
  }, [goalData]);

  useEffect(() => {
    if (userState.getPreferenceValue("productMap") && !goalData) {
      setGoalData(userState.getGoalData(goalName));

      setProgressValues(
        getSavedProgressValuesByGoalId(goalId, userState.productScenariosMap)
      );
    }
  }, [userState, goalName]);

  useEffect(() => {
    if (goalData && !userState.selectedProduct) {
      const recommendedProductId =
        userState.selectedProduct ??
        goalData.recommendedProductId ??
        goalData.productId ??
        getRecommendedProductId({
          organization: "goals",
          productsList: userState.productsList,
          proposalData: goalData,
        });

      if (recommendedProductId) {
        setUserState(lastState => ({
          ...lastState,
          selectedProduct: recommendedProductId,
          compareProducts: [recommendedProductId],
        }));
      }
    }
  }, [goalData, userState]);

  const areAllProgressDatesUnic = progressArray => {
    const seenDates = new Set();

    for (const item of progressArray) {
      const dateStr = dayjs(item.date.$date).format("M/DD/YYYY");

      if (seenDates.has(dateStr)) {
        return false;
      }

      seenDates.add(dateStr);
    }

    return true;
  };

  const handleCardClick = cardId => {
    setUserState(lastState => ({
      ...lastState,
      productDetailsId: cardId,
    }));
    userState.openModal("productDetailsModal");
  };

  const handleDeleteGoal = () => {
    userState.showLoader();

    deleteGoal(
      getGoalIdByGoalName(userState.getPreferenceValue("productMap"), goalName)
    )
      .then(() => {
        userState.setUserData().then(() => {
          userState.hideLoader();
          userState.showSuccess("Goal deleted!");
          navigate("/goals");
        });
      })
      .catch(e => {
        console.log(e);
        userState.hideLoader();
      });
  };

  const saveGoalSettings = requestBody => {
    userState.showLoader();

    getLiveAssessment({
      objective: {
        ...requestBody,
      },
      organization: "goals",
      productId: requestBody.productId,
      saveAction: "PUT",
      saveId: goalId,
      activeGoal: goalId,
    })
      .then(() => {
        userState.setUserData().then(data => {
          setGoalData(
            data.preferences.valueMap.productMap[goalId][
              data.preferences.valueMap.productMap[goalId].length - 1
            ]
          );

          userState.closeModal("goalSettingsModal");
          userState.showSuccess("Goal Settings updated!");
        });
      })
      .catch(console.log);
  };

  const saveProgress = (updatedProgressValues, successCallback) => {
    if (areAllProgressDatesUnic(updatedProgressValues)) {
      if (!updatedProgressValues.length) {
        userState.showWarning("You need to fill data for first Progress Point");
        return;
      }

      userState.closeModal("updateProgressModal");
      userState.showLoader();

      updateGoal2progress(goalId, updatedProgressValues)
        .then(() => {
          userState.setUserData().then(userData =>
            getLiveAssessment({
              objective: goalData,
              organization: "goals",
              productId: goalData.productId,
              saveId: goalId,
              activeGoal: goalId,
            })
              .then(data => {
                userState.showSuccess("Progress History updated!");
                setCalculationsData(data);
                setProgressValues(
                  getSavedProgressValuesByGoalId(
                    goalId,
                    userData.productScenariosMap
                  )
                );
                successCallback && successCallback();
              })
              .catch(error => userState.showError(error.response?.data))
              .finally(() => userState.hideLoader())
          );
        })
        .catch(error => {
          userState.showError(error.response?.data);
          userState.hideLoader();
        });
    } else {
      userState.showWarning(
        "You can't set two progress values on the same date!"
      );
    }
  };

  const updateGoalProduct = productId => {
    setCompareMode(false);

    setUserState(oldState => ({
      ...oldState,
      compareProducts: [productId],
      selectedProduct: productId,
    }));
    saveGoalSettings({
      ...goalData,
      productId,
    });
  };

  const updateTrajectoryData = () => {
    setLoading(true);

    getLiveAssessment({
      activeGoal: goalId,
      objective: goalData,
      organization: "goals",
      productId: goalData?.productId,
      saveId: goalId,
    })
      .then(data => {
        setCalculationsData(data);
        getLiveSimulation({
          historicalAnalysis: true,
          montecarlo: true,
          objective: goalData,
          organization: "goals",
          productId: goalData?.productId,
        })
          .then(liveSimulations => {
            userState.setUserData().then(() => {
              setSimulationsData(liveSimulations);
              setLoading(false);
              userState.hideLoader();
            });
          })
          .catch(e => {
            console.log(e);
            setLoading(false);
          });
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const compareListCardAction = [
    {
      buttonActiveCopy: "Selected to Compare",
      buttonCopy: "Add to Compare",
      key: "addToCompare",
      onSelect: product => {
        setUserState(lastState => ({
          ...lastState,
          compareProducts: lastState.compareProducts.includes(product._id)
            ? lastState.compareProducts.filter(id => id !== product._id)
            : [...lastState.compareProducts, product._id],
        }));

        setIsCompareChartDirty(true);
        userState.closeModal("otherGrowthTypesModal");
      },
    },
  ];

  const defaultListCardAction = [
    {
      buttonActiveCopy: "Applied to goal",
      buttonCopy: "Apply to goal",
      key: "addToGoal",
      onSelect: product => {
        userState.closeModal("otherGrowthTypesModal");
        const newProductData = userState.productsList.find(
          it => it._id === product._id
        );

        if (newProductData) {
          setGoalData(lastState => ({
            ...lastState,
            riskTolerance: newProductData.riskTolerance,
          }));
        }

        updateGoalProduct(product._id);
      },
    },
  ];

  const handleMonthlyExpensesModalSave = values => {
    const totalValues = Object.values(values)?.reduce((acc, it) => acc + it, 0);

    saveGoalSettings({
      ...goalData,
      monthlyExpenses: totalValues,
      monthlyExpensesItems: values,
    });
    userState.closeModal("monthlyExpensesModal");
  };

  return (
    <>
      <SkeletonGoal loading={userState.loading}>
        <Header style={{ background: "#ffffff" }}>
          <b style={{ fontSize: 20, color: "#1B5568" }}>Goal</b>
        </Header>
        <Layout.Content style={{ background: "#ffffff" }}>
          {goalData ? (
            compareMode ? (
              <WorkspaceGrowthTypeCompare
                goalData={goalData}
                exitCompareMode={() => setCompareMode(false)}
                handleCardClick={handleCardClick}
                isCompareChartDirty={isCompareChartDirty}
                setIsCompareChartDirty={setIsCompareChartDirty}
                updateGoalProduct={updateGoalProduct}
              />
            ) : id === "Emergency_Savings" ? (
              <WorkspaceEmergencySavings
                goalData={goalData}
                handleCardClick={handleCardClick}
                saveGoalSettings={saveGoalSettings}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                state={userState}
              />
            ) : (
              <WorkspaceGoal
                calculationsData={calculationsData}
                goalData={goalData}
                handleCardClick={handleCardClick}
                loading={loading}
                saveGoalSettings={saveGoalSettings}
                setCompareMode={setCompareMode}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                simulationsData={simulationsData}
                state={userState}
              />
            )
          ) : (
            <Empty />
          )}
        </Layout.Content>
      </SkeletonGoal>

      <ModalDeleteGoal
        handleOk={handleDeleteGoal}
        handleCancel={() => setIsDeleteModalOpen(false)}
        open={isDeleteModalOpen}
      />
      <ModalGoalSettings
        handleClose={() => userState.closeModal("goalSettingsModal")}
        handleUpdateGoal={saveGoalSettings}
        loading={userState.loading}
        open={userState.goalSettingsModal}
        organization={userState.organization?.name}
        proposalData={goalData}
      />
      <ModalMonthlyExpenses
        onCancel={() => userState.closeModal("monthlyExpensesModal")}
        onSave={handleMonthlyExpensesModalSave}
        open={userState.monthlyExpensesModal}
        values={goalData?.monthlyExpensesItems}
      />
      <ModalOtherGrowthTypes
        cardActions={
          compareMode ? compareListCardAction : defaultListCardAction
        }
        compareProducts={userState.compareProducts}
        handleCardClick={handleCardClick}
        handleClose={() => userState.closeModal("otherGrowthTypesModal")}
        open={userState.otherGrowthTypesModal}
        productsList={userState.productsList}
        selectedProduct={userState.selectedProduct}
      />
      <ModalProductDetails
        handleClose={() => userState.closeModal("productDetailsModal")}
        hideMcSettingsToggler={true}
        investmentAssumptions={{}}
        open={userState.productDetailsModal}
        organization={"goals"}
        product={{
          ...userState.productsList.find(
            it => it._id === userState.productDetailsId
          ),
          ...goalData,
          advisorFee: getPercentValue(
            goalData?.advisorFee ?? DEFAULT_ADVISOR_FEE
          ),
          withdrawalLevel: getPercentValue(goalData?.withdrawalLevel ?? 0),
        }}
        productsList={userState.productsList}
      />
      <ModalUpdateProgress
        goalName={goalName}
        progressValues={progressValues}
        saveProgress={saveProgress}
        setProgressValues={setProgressValues}
      />
    </>
  );
};

export default PageGoal;
