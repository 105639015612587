import { useContext, useEffect, useState } from "react";
import State from "../../../../context/manager/State";
import styled from "styled-components";
import dayjs from "dayjs";
import { Button, Flex, Input, Select, Table, Tag, theme } from "antd";

import { getAllConversations } from "../../../../utils/requests/penny";

import { SearchOutlined } from "@ant-design/icons";

const { Column } = Table;

const StyledTable = styled(Table)`
  font-family: Inter, sans-serif;
  padding: 0;
  background: transparent;

  table {
    border-spacing: 0 8px;

    .ant-table-row {
      background: #f0f4f7;
      cursor: pointer;

      .ant-table-cell {
        font-family: Inter, sans-serif;
        color: #354a5f;
      }
    }
  }

  &.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 12px 8px;
  }

  &.ant-table-wrapper .ant-table-thead > tr > th {
    font-weight: 500;
    background: transparent;
    color: #516474;
    border-bottom-color: transparent;

    &:before {
      display: none;
    }
  }
`;

const StyledSelect = styled(Select)`
  width: 228px;

  .ant-select-selector {
    border-radius: 23px;
  }
`;

const PageConversations = () => {
  const { token } = theme.useToken();
  const [adminState] = useContext(State);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [searchedTags, setSearchedTags] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(
      adminState.conversations?.map((it, index) => ({
        ...it,
        key: index,
      }))
    );
  }, [adminState.conversations]);

  useEffect(() => {
    setLoading(true);

    getAllConversations()
      .then(data => {
        adminState.setKeyValue("conversations", data);
        setTableData(
          data?.map((it, index) => ({
            ...it,
            key: index,
          }))
        );
      })
      .catch(error => adminState.showError(error.response?.data?.message))
      .finally(() => setLoading(false));
  }, []);

  const getOptions = () =>
    Array.from(
      new Set(adminState.conversations?.map(it => it.tags)?.flat())
    )?.map(it => ({
      label: it,
      value: it,
    }));

  return (
    <>
      <div
        style={{
          fontSize: 20,
          fontWeight: 800,
          color: token.colorPrimary,
          padding: "31px 46px",
          borderBottom: "1px solid #C9C8C8",
        }}
      >
        All Conversations
      </div>
      <div style={{ padding: "30px 109px 30px 46px" }}>
        <Flex justify={"space-between"} style={{ marginBottom: 30 }}>
          <Flex gap={27}>
            <Input
              className={"pointer"}
              onChange={event => setSearchedText(event.target.value)}
              placeholder="Search..."
              size="large"
              style={{ width: 228, borderRadius: 23 }}
              suffix={
                <SearchOutlined style={{ fontSize: 20, color: "#B1B1B1" }} />
              }
            />
            <StyledSelect
              defaultValue={searchedTags}
              mode="multiple"
              placeholder="Filter by tag"
              onChange={setSearchedTags}
              options={getOptions()}
              maxTagCount={"responsive"}
            />
          </Flex>
          <Button
            onClick={() => adminState.openModal("openModalAskAdvice")}
            shape={"round"}
            style={{ background: "#25556F", color: "#FFFFFF" }}
          >
            Ask<b>Penny</b>
          </Button>
        </Flex>
        <StyledTable
          dataSource={tableData}
          loading={loading}
          onRow={conversationData => {
            return {
              onClick: () => {
                adminState.openModal("conversationModal");
                adminState.setKeyValue(
                  "activeConversationId",
                  conversationData._id
                );
              },
            };
          }}
          pagination={{
            position: ["bottomCenter"],
          }}
          size={"small"}
        >
          <Column
            dataIndex={"userQuery"}
            filteredValue={[searchedText]}
            key={"userQuery"}
            onFilter={(value, record) =>
              String(record.userQuery)
                .toLowerCase()
                .includes(value.toLowerCase())
            }
            render={value => (
              <div
                style={{ fontWeight: 600, maxWidth: 500 }}
                dangerouslySetInnerHTML={{ __html: value }}
              />
            )}
            sorter={{
              compare: (a, b) => a.userQuery?.localeCompare(b.userQuery),
            }}
            title={"Question / Conversation"}
            width={300}
          />
          <Column
            dataIndex={"tags"}
            filteredValue={searchedTags}
            key={"tags"}
            onFilter={(value, record) => record.tags?.includes(value)}
            render={tags => (
              <Flex>
                {tags?.map((tag, index) => (
                  <Tag
                    key={index}
                    style={{
                      fontSize: 14,
                      background: "#FFF",
                      color: "#1E1919",
                      borderColor: "#585858",
                    }}
                  >
                    {tag}
                  </Tag>
                ))}
              </Flex>
            )}
            title={"Tags"}
          />
          <Column
            dataIndex={"userEmail"}
            key={"userEmail"}
            sorter={{
              compare: (a, b) => a.userEmail?.localeCompare(b.userEmail),
            }}
            title={"User"}
          />
          <Column
            dataIndex={"environment"}
            key={"environment"}
            sorter={{
              compare: (a, b) => a.environment?.localeCompare(b.environment),
            }}
            title={"Environment"}
          />
          <Column
            dataIndex={"source"}
            key={"source"}
            render={value => (
              <span style={{ textTransform: "capitalize" }}>
                {value === "db" ? "LLM" : value}
              </span>
            )}
            sorter={{
              compare: (a, b) => a.source?.localeCompare(b.source),
            }}
            title={"Answer"}
          />
          <Column
            align={"center"}
            dataIndex={"counterReplies"}
            key={"counterReplies"}
            sorter={{
              compare: (a, b) => a.counterReplies - b.counterReplies,
            }}
            title={"Replies"}
          />
          <Column
            align={"right"}
            dataIndex={"createdAt"}
            key={"createdAt"}
            render={value => dayjs(value).format("MM/DD/YYYY h:mma")}
            sorter={{
              compare: (a, b) => dayjs(a.createdAt) - dayjs(b.createdAt),
            }}
            title={"Initiated"}
            width={150}
          />
        </StyledTable>
      </div>
    </>
  );
};

export default PageConversations;
