import styled from "styled-components";
import { Button, Flex } from "antd";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";

const Container = styled(Flex)`
  background: #001141;
  border-radius: 12px;
  padding: 14px 16px;

  .carousel {
    width: calc(100% + 16px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .carousel::-webkit-scrollbar {
    display: none;
  }
`;

const CarouselSponsors = ({ handleClick, openModal, sponsors }) => (
  <Container gap={30} vertical>
    <Flex align={"center"} justify={"space-between"}>
      <span style={{ fontSize: 20, fontWeight: 500, color: "#FFFFFF" }}>
        SPONSORS
      </span>
      <Button
        onClick={() => openModal("sponsorsListModal")}
        size={"small"}
        style={{ color: "#4EABE9" }}
        type={"text"}
      >
        VIEW ALL <BlueArrow style={{ rotate: "270deg", marginLeft: 6 }} />
      </Button>
    </Flex>
    <Flex className={"carousel"} gap={32}>
      {sponsors?.map(
        (it, index) =>
          it.logo?.url && (
            <img
              alt={it.label}
              className={"pointer"}
              key={index}
              onClick={() => handleClick(it)}
              src={it.logo.url}
              style={{ height: 60 }}
            />
          )
      )}
    </Flex>
  </Container>
);

export default CarouselSponsors;
