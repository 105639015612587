import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Col, Row, theme, Typography } from "antd";
import CompareCardPlaceholder from "./components/CompareCardPlaceholder";
import CardProduct from "../../molecules/CardProduct";
import ChartComparisonGrowth from "../../molecules/ChartComparisonGrowth";
import ChartIncomeWithTypes from "../../molecules/ChartIncomeWithTypes";

import { MAX_COMPARE_PRODUCTS } from "../../utils/constants";
import { getLiveAssessment } from "../../utils/requests/regularApp";

const DashboardCompare = ({
  closeCompareMode,
  handleCardClick,
  isCompareChartDirty,
  openProductsListModal,
  productsList,
  proposalData,
  setIsCompareChartDirty,
  state,
  setState,
  showError,
  showLegend,
  showSecondaryButton,
}) => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [productsLiveAssessment, setProductsLiveAssessment] = useState([]);

  useEffect(() => {
    if (state.compareProducts?.length) {
      if (isCompareChartDirty || !productsLiveAssessment.length) {
        setLoading(true);
        debouncedLiveAssessmentFetch();
      }
    }
  }, [isCompareChartDirty]);

  const debouncedLiveAssessmentFetch = useDebouncedCallback(async () => {
    const responses = [];

    const requestPromises = state.compareProducts.map((productId, index) => {
      const requestBody = {
        organization:
          (state.organization?.name?.toLowerCase() ??
            state.orgName?.toLowerCase()) === "level"
            ? "level2"
            : state.organization?.name ?? state.orgName,
        productId: productId,
        objective: {
          ...proposalData,
          investmentDuration:
            proposalData.investmentDuration ?? proposalData.lengthOfInvestment,
        },
      };

      return getLiveAssessment(requestBody)
        .then(data => {
          responses.push({
            productId,
            ...data,
            color:
              state.compareProducts?.length > 1 &&
              token.comparisonProductsColors[index],
          });
        })
        .catch(({ response }) => {
          showError(response?.data?.error ?? response?.data?.message);
        });
    });

    await Promise.all(requestPromises);

    setProductsLiveAssessment(responses);
    setLoading(false);
    setIsCompareChartDirty(false);
  }, 2000);

  const getCompareCardsPlaceholders = () => {
    const freeSlotsAmount =
      MAX_COMPARE_PRODUCTS - state.compareProducts?.length;

    return Array.apply(null, Array(freeSlotsAmount)).map((ignore, index) => (
      <CompareCardPlaceholder key={index} handleClick={openProductsListModal} />
    ));
  };

  const handleCardCloseButton = productId => {
    setState(lastState => ({
      ...lastState,
      compareProducts: lastState.compareProducts.filter(it => it !== productId),
    }));
    setIsCompareChartDirty(true);
  };

  return (
    <>
      <Row gutter={[22, 22]} style={{ width: "100%" }}>
        <Col span={24}>
          <ChartComparisonGrowth
            height={233}
            loading={loading}
            productsList={productsList}
            productsLiveAssessment={productsLiveAssessment}
            proposalData={proposalData}
          />
        </Col>
        <Col span={24}>
          <ChartIncomeWithTypes
            loading={loading}
            productsList={productsList}
            productsLiveAssessment={productsLiveAssessment}
            productData={proposalData}
            showLegend={showLegend}
          />
        </Col>
      </Row>
      <Typography.Paragraph
        style={{ fontSize: 18, color: "#405377", margin: 0 }}
      >
        Product Selections
      </Typography.Paragraph>
      <Row style={{ width: "100%", overflowX: "auto", gap: 20 }} wrap={false}>
        {state.compareProducts
          ?.map(id => productsList?.find(it => it._id === id))
          ?.map((product, index) => (
            <Col key={product._id}>
              <div
                style={{
                  background: token.comparisonProductsColors[index],
                  borderRadius: 12,
                  height: 5,
                  marginBottom: 8,
                  width: 50,
                }}
              />
              <CardProduct
                actions={[
                  {
                    buttonCopy: "Details",
                    key: "details",
                    onSelect: product => handleCardClick(product._id),
                  },
                ]}
                product={product}
                handleCardClick={handleCardClick}
                labels={[product?.riskTolerance, product?.allocationType]}
                showCloseButton={index !== 0}
                handleCloseButtonClick={handleCardCloseButton}
                showSecondaryButton={showSecondaryButton}
                isSecondaryButtonActive={state.selectedProduct === product._id}
                handleSecondaryButtonActivate={productId => {
                  setState(oldState => ({
                    ...oldState,
                    compareProducts: [productId],
                    selectedProduct: productId,
                  }));
                  closeCompareMode();
                }}
                width={"306px"}
              />
            </Col>
          ))}
        {!state.loading && getCompareCardsPlaceholders()}
      </Row>
    </>
  );
};

export default DashboardCompare;
