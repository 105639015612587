import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";
import { Button, Flex, Row, theme } from "antd";
import ChartSkeleton from "../../atoms/ChartSkeleton";

import { getIncomeChartConfig, getIncomeChartSeries } from "../../utils/charts";
import { getCurrencyValue } from "../../utils/helpers/general";
import { isGrowthTypeRiskTolerance } from "../../utils/helpers/specialized";
import { DEFAULT_BIRTH_YEAR } from "../../utils/constants";

exporting(Highcharts);

const ChartTypeButtonsContainer = styled(Row)`
  position: absolute;
  background: ${props => props.background};
  top: 9px;
  left: 9px;
  padding: 4px;
  border-radius: 25px;
  z-index: 1;

  @media screen and (max-width: 550px) {
    position: initial;
    padding: 6px;
    margin-bottom: -24px;
  }
`;

const StyledButton = styled(Button)`
  min-width: 143px;
  height: 24px;
  font-weight: 500;
  background-color: transparent;
  color: #8396a6;
  padding: 0 12px;
  border-radius: 25px;
  border-color: transparent;

  &.active {
    background-color: ${props => props.activebackground};
    color: ${props => props.activecolor};
    pointer-events: none;
  }

  &.ant-btn:disabled {
    background: transparent;
  }

  @media screen and (max-width: 550px) {
    height: 36px;
  }
`;

const Marker = styled.div`
  height: 14px;
  width: 14px;
  min-width: 14px;
  border-radius: 2px;
`;

const NoContributionsContainer = styled(Flex)`
  font-size: 14px;
  background: #e9f3f8;
  color: #1c5568;
  padding: 26px;
  border-radius: 12px;
  margin: 12px 0;
`;

const ChartIncomeWithTypes = ({
  loading,
  productsLiveAssessment,
  productsList,
  productData,
  showLegend = true,
  showLegendAmounts,
  viewMode,
}) => {
  const { token } = theme.useToken();
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });
  const [chartSeries, setChartSeries] = useState([]);
  const [incomeChartType, setIncomeChartType] = useState("");
  const [rerenderChart, setRerenderChart] = useState(false);
  const chartRef = useRef(null);

  useEffect(() => {
    if (viewMode === "pdf") {
      const storedData = localStorage.getItem("barsChartSvg");

      const interval = setInterval(() => {
        if (!storedData) {
          const barsChartSvg = chartRef?.current?.chart?.getSVG();

          barsChartSvg && localStorage.setItem("barsChartSvg", barsChartSvg);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [viewMode]);

  useEffect(() => {
    const investmentObjectiveChartType = isGrowthTypeRiskTolerance(
      productData?.investmentObjective
    )
      ? "contributions"
      : "income";

    if (incomeChartType !== investmentObjectiveChartType)
      setIncomeChartType(investmentObjectiveChartType);
  }, [productData]);

  useEffect(() => {
    if (productsLiveAssessment?.length) {
      setRerenderChart(true);
      updateSeries();
    }
  }, [productsLiveAssessment, incomeChartType]);

  const updateSeries = () => {
    if (productsLiveAssessment?.length) {
      if (incomeChartType === "contributions") {
        setChartSeries([
          {
            name: "Contributions",
            color: token.incomeChartWithTypesContributionsColor,
            data: productsLiveAssessment[0].incomeBarchart.totalContributions,
            visible: productData?.contributions !== 0,
            borderRadius: "6px",
            groupPadding: 0.05,
            pointPadding: 0.05,
          },
        ]);
      } else {
        setChartSeries(
          productsLiveAssessment
            .map(it =>
              getIncomeChartSeries({
                annuities_isOn: productData?.annuities_isOn,
                hideDetailedNames: productsLiveAssessment.length === 1,
                incomeBarchart: it.incomeBarchart,
                name: productsList.find(
                  product => product._id === (it.productId ?? it._id)
                )?.name,
                otherIncome_isOn: productData?.otherIncome_isOn,
                socialSecurity_isOn: productData?.socialSecurity_isOn,
                totalWithdrawalsColor: it.color,
              })
            )
            .flat()
        );
      }
      setTimeout(() => setRerenderChart(false), 1000);
    }
  };

  const getLegend = () =>
    [
      {
        color: token.incomeChartWithTypesContributionsColor,
        dataName: "withdrawals",
        label: "Withdrawals",
      },
      {
        color: "#3C4C99",
        dataName: "socialSecurity",
        label: "Social Security",
        togglerName: "socialSecurity_isOn",
      },
      {
        color: "#5F82F8",
        dataName: "annuities",
        label: "Annuities",
        togglerName: "annuities_isOn",
      },
      {
        color: "#9FBEFF",
        dataName: "otherIncome",
        label: "Other Income",
        togglerName: "otherIncome_isOn",
      },
    ].map(
      (config, index) =>
        productData && (
          <Flex
            align={"start"}
            gap={12}
            justify={"space-between"}
            key={index}
            style={{
              display:
                config.togglerName && !productData[config.togglerName]
                  ? "none"
                  : "flex",
              width: isMobile && "100%",
            }}
            vertical={!isMobile}
          >
            <Flex gap={12}>
              <Marker style={{ background: config.color }} />
              <span>{config.label}</span>
            </Flex>
            <Flex justify={"end"} style={{ width: "100%" }}>
              {showLegendAmounts && (
                <b>
                  {getCurrencyValue(
                    config.dataName === "withdrawals"
                      ? getWithdrawalsLegendLabel()
                      : productData[config.dataName] ?? 0
                  )}{" "}
                  / year
                </b>
              )}
            </Flex>
          </Flex>
        )
    );

  const getWithdrawalsLegendLabel = () =>
    productsLiveAssessment?.length &&
    productsLiveAssessment[0].incomeBarchart.totalWithdrawals.find(
      ([, value]) => value > 0
    )
      ? productsLiveAssessment[0].incomeBarchart.totalWithdrawals.find(
          ([, value]) => value > 0
        )[1]
      : 0;

  return (
    <ChartSkeleton loading={rerenderChart || loading} height={200}>
      <div style={{ position: "relative" }}>
        <ChartTypeButtonsContainer
          align="center"
          background={token.incomeChartWithTypesNavbarContainerBackground}
        >
          <StyledButton
            activebackground={token.incomeChartWithTypesNavbarButtonBackground}
            activecolor={token.incomeChartWithTypesNavbarButtonColor}
            className={incomeChartType === "contributions" && "active"}
            onClick={() => setIncomeChartType("contributions")}
            type="text"
          >
            Annual Contributions
          </StyledButton>
          <StyledButton
            activebackground={token.incomeChartWithTypesNavbarButtonBackground}
            activecolor={token.incomeChartWithTypesNavbarButtonColor}
            className={incomeChartType === "income" && "active"}
            disabled={isGrowthTypeRiskTolerance(
              productData?.investmentObjective
            )}
            onClick={() => setIncomeChartType("income")}
            type="text"
          >
            Annual Income
          </StyledButton>
        </ChartTypeButtonsContainer>
        {incomeChartType === "contributions" &&
        productData?.contributions === 0 ? (
          <div style={{ paddingTop: 40 }}>
            <NoContributionsContainer align={"center"} justify={"center"}>
              No contributions selected
            </NoContributionsContainer>
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              ...getIncomeChartConfig({
                birthYear:
                  productData?.birthYear ??
                  (productData?.currentAge
                    ? dayjs().format("YYYY") - productData.currentAge
                    : DEFAULT_BIRTH_YEAR),
                height:
                  incomeChartType === "contributions" &&
                  productData?.contributions === 0
                    ? 50
                    : 200,
                spacingTop: 55,
                background: "transparent",
                borderColor: "transparent",
              }),
              series: chartSeries,
            }}
            ref={chartRef}
          />
        )}
        {showLegend && incomeChartType === "income" && (
          <Flex align={"top"} gap={10} justify={"end"} wrap={"wrap"}>
            {getLegend()}
          </Flex>
        )}
      </div>
    </ChartSkeleton>
  );
};

export default ChartIncomeWithTypes;
