import { useContext } from "react";
import styled from "styled-components";
import State from "../../../../../../context/user/State";
import { Button, Flex } from "antd";

import EmergencySavingsIcon from "../../../../../../icons/goal_type/EmergencySavingsIcon";
import GrowRetirementSavingsIcon from "../../../../../../icons/goal_type/GrowRetirementSavingsIcon";
import OtherInvestmentIcon from "../../../../../../icons/goal_type/OtherInvestmentIcon";
import RetirementIncomeIcon from "../../../../../../icons/goal_type/RetirementIncomeIcon";

const StyledButton = styled(Button)`
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  background: #e1f1f4;
  border-color: transparent;
  color: #1b5568;

  & .ant-btn-icon {
    position: absolute;
    top: 9px;
    left: 10px;
  }
`;

const CreateGoalStepTypes = ({ newGoalData, handleGoalTypeSelect }) => {
  const [userState] = useContext(State);

  const doesGoalTypeAlreadyExist = goalType => {
    const productMap = userState.getPreferenceValue("productMap");

    if (!productMap) {
      return false;
    }

    const goalsNames = Object.keys(productMap).map(
      goalId => productMap[goalId][0].goalName
    );

    if (
      goalType === "savings" &&
      goalsNames.includes("Grow Retirement Savings")
    ) {
      return true;
    } else if (
      goalType === "income" &&
      goalsNames.includes("Retirement income")
    ) {
      return true;
    } else if (
      goalType === "emergencySavings" &&
      goalsNames.includes("Emergency Savings")
    ) {
      return true;
    } else if (
      goalType === "other" &&
      goalsNames
        .filter(it => it !== "Grow Retirement Savings")
        .filter(it => it !== "Emergency Savings")
        .filter(it => it !== "Retirement income").length
    ) {
      return true;
    }
  };

  return (
    <Flex gap={26} vertical>
      <StyledButton
        block
        className={newGoalData.goalType === "savings" && "active"}
        disabled={doesGoalTypeAlreadyExist("savings")}
        icon={<GrowRetirementSavingsIcon />}
        onClick={() => handleGoalTypeSelect("savings")}
        shape={"round"}
      >
        Grow Retirement Savings
      </StyledButton>
      <StyledButton
        block
        className={newGoalData.goalType === "income" && "active"}
        disabled={doesGoalTypeAlreadyExist("income")}
        icon={<RetirementIncomeIcon />}
        onClick={() => handleGoalTypeSelect("income")}
        shape={"round"}
      >
        Retirement income
      </StyledButton>
      <StyledButton
        block
        className={newGoalData.goalType === "emergencySavings" && "active"}
        disabled={doesGoalTypeAlreadyExist("emergencySavings")}
        icon={<EmergencySavingsIcon />}
        onClick={() => handleGoalTypeSelect("emergencySavings")}
        shape={"round"}
      >
        Emergency Savings
      </StyledButton>
      <StyledButton
        block
        className={newGoalData.goalType === "other" && "active"}
        disabled={doesGoalTypeAlreadyExist("other")}
        icon={<OtherInvestmentIcon />}
        onClick={() => handleGoalTypeSelect("other")}
        shape={"round"}
        style={{ marginBottom: 100 }}
      >
        Other investment goal
      </StyledButton>
    </Flex>
  );
};

export default CreateGoalStepTypes;
