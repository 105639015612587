import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import State from "./State";
import { notification } from "antd";
import Overlay from "../../../../atoms/Overlay";

import * as globalRequests from "../../requests/global";
import { loginFts } from "../../requests/fts";
import defaultState from "./defaultState.json";
import { MAX_FEATURED_EVENTS_AMOUNT } from "../../constants";
import { setTenantSpecificHeadAttributes } from "../../../../utils/helpers/specialized";

const StateProvider = ({ children }) => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [userState, setUserState] = useState(defaultState);

  useEffect(() => {
    setTenantSpecificHeadAttributes("mmi");
    setKeyValue("showOverlay", true);

    globalRequests
      .getUserEmailByJwt()
      .then(email => {
        setKeyValue("email", email);

        globalRequests
          .getUserDataByEmail(email)
          .then(userData => {
            setKeyValue("user", userData);

            loginFts().then(({ token, userId }) => {
              setKeyValue("token", token);
              setKeyValue("userId", userId);
            });

            globalRequests.getCompanies().then(companiesList => {
              globalRequests.getLeagues().then(leaguesList => {
                const companiesNames = companiesList.map(it => it.name);

                const updatedByAdminLeagues = leaguesList.map(it =>
                  companiesNames.includes(it.company)
                    ? {
                        ...it,
                        ...companiesList.find(
                          company => company.name === it.company
                        ),
                      }
                    : it
                );

                const isUserCompanyPartOfLeaguesList =
                  updatedByAdminLeagues.find(
                    it => it.company === userData.company
                  );

                if (isUserCompanyPartOfLeaguesList) {
                  // update user company data in the leagues list by the data, that admin provided
                  updatedByAdminLeagues.forEach(it => {
                    if (it.company === userData.company) {
                      return {
                        ...it,
                        aumAnnually: userData.aumAnnually,
                        aumQuarterly: userData.aumQuarterly,
                        isMmiMember: !!userData.aumQuarterly,
                      };
                    } else {
                      return it;
                    }
                  });
                } else {
                  const customUserCompanyData = companiesList?.find(
                    it => it.name === userData.company
                  );

                  updatedByAdminLeagues.push({
                    _id: userData.company,
                    aumAnnually: customUserCompanyData?.aumAnnually,
                    aumQuarterly: customUserCompanyData?.aumQuarterly,
                    company: userData.company,
                    isMmiMember: !!customUserCompanyData?.aumQuarterly,
                    url: customUserCompanyData?.url,
                  });
                }

                const sortedByAumAnnuallyLeagues = updatedByAdminLeagues.sort(
                  (a, b) => b.aumAnnually - a.aumAnnually
                );

                const companyIndex = sortedByAumAnnuallyLeagues
                  .map(it => it.company)
                  .indexOf(userData.company);

                setKeyValue(
                  "leagues",
                  sortedByAumAnnuallyLeagues.slice(
                    companyIndex - 4,
                    companyIndex + 6
                  )
                );

                setKeyValue("loadingLeagues", false);
              });
            });
          })
          .catch(({ message }) => showWarning(message));
        globalRequests.getEvents().then(data =>
          setKeyValue(
            "events",
            data
              .filter(it => it.featured)
              .sort(
                (a, b) =>
                  (a.order ?? MAX_FEATURED_EVENTS_AMOUNT + 1) -
                  (b.order ?? MAX_FEATURED_EVENTS_AMOUNT + 1)
              )
          )
        );
        globalRequests.getSponsors().then(data =>
          setKeyValue(
            "sponsors",
            data.filter(it => it.featured)
          )
        );
        setKeyValue("showOverlay", false);
      })
      .catch(() => {
        showError("Please log in.");
        setTimeout(() => navigate("/login-mmi"), 2000);
      });
  }, []);

  const openModal = modalKey => {
    setUserState(state => ({
      ...state,
      [modalKey]: true,
    }));
  };

  const closeModal = modalKey => {
    setUserState(state => ({
      ...state,
      [modalKey]: false,
    }));
  };

  const showWarning = errorMessage => {
    api.warning({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showSuccess = successMessage => {
    api.success({
      message: successMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showError = errorMessage => {
    api.error({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showLoader = () => {
    setUserState(state => ({
      ...state,
      loading: true,
    }));
  };

  const hideLoader = () => {
    setUserState(state => ({
      ...state,
      loading: false,
    }));
  };

  const setKeyValue = (key, value) =>
    setUserState(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const mergedAdminState = {
    ...userState,
    closeModal,
    hideLoader,
    openModal,
    showWarning,
    showError,
    showLoader,
    showSuccess,
    setKeyValue,
  };

  return (
    <State.Provider value={[mergedAdminState, setUserState]}>
      <Overlay loading={userState.showOverlay}>{children}</Overlay>
      {contextHolder}
    </State.Provider>
  );
};

export default StateProvider;
