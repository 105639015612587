import { useState } from "react";
import { Button, Flex } from "antd";

import {
  addConversationDislike,
  addConversationLike,
  getConversationById,
  updateConversation,
} from "../../../../utils/requests/penny";

import ThumbUp from "../../../../ecosystems/super_admin/images/ThumbUp";
import ThumbDown from "../../../../ecosystems/super_admin/images/ThumbDown";

const RateResponseSection = ({ email, responseData, showSuccess }) => {
  const [rateType, setRateType] = useState("");
  const [hideDislikeSection, setHideDislikeSection] = useState(false);

  const handleThumbDown = () => {
    if (rateType === "useless") {
      return;
    }

    addConversationDislike({ email, conversationId: responseData.mongo_id })
      .then(() => {
        setRateType("useless");
        showSuccess("Dislike added");
      })
      .catch(console.log);
  };

  const handleThumbUp = () => {
    if (rateType === "useful") {
      return;
    }

    addConversationLike({ email, conversationId: responseData.mongo_id })
      .then(() => {
        setRateType("useful");
        showSuccess("Like added");
      })
      .catch(console.log);
  };

  const handleDislikeReason = dislikeReason => {
    getConversationById(responseData.mongo_id).then(data => {
      updateConversation({
        id: responseData.mongo_id,
        payload: {
          ...data,
          dislikeReason,
        },
      }).then(() => {
        showSuccess("Dislike reason added");
        setHideDislikeSection(true);
      });
    });
  };

  return (
    <Flex style={{ marginTop: 8 }} vertical>
      <Flex gap={13}>
        <div onClick={handleThumbUp}>
          <ThumbUp active={rateType === "useful"} />
        </div>
        <div onClick={handleThumbDown}>
          <ThumbDown active={rateType === "useless"} />
        </div>
      </Flex>
      {rateType === "useless" && !hideDislikeSection && (
        <>
          <p>
            Thanks for your feedback. Would you mind telling us why that was not
            a good answer?
          </p>
          <Flex gap={8} align={"end"} vertical>
            <Button
              onClick={() => handleDislikeReason("Not relevant to my question")}
              shape={"round"}
              type={"primary"}
            >
              Not relevant to my question
            </Button>
            <Button
              onClick={() => handleDislikeReason("Answer is too complex")}
              shape={"round"}
              type={"primary"}
            >
              Answer is too complex
            </Button>
            <Button
              onClick={() => handleDislikeReason("Too simple")}
              shape={"round"}
              type={"primary"}
            >
              Too simple
            </Button>
            <Button
              onClick={() => setHideDislikeSection(true)}
              shape={"round"}
              type={"primary"}
            >
              Skip
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default RateResponseSection;
