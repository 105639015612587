import { Button, Image, Layout, Row, theme } from "antd";
import PennyChatToggler from "../../../organisms/SectionAskAdvice/components/PennyChatToggler";

import {
  isOrganizationLevel,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../utils/helpers/specialized";

import GetAdvice from "../../images/GetAdvice";

import { ReactComponent as LevelLogo } from "../../../ecosystems/level/images/logo.svg";
import { ReactComponent as OneAscentSlogan } from "../../../ecosystems/oneascent/images/slogan.svg";

const Header = ({ organization, openModal, userManagerData }) => {
  const { token } = theme.useToken();

  return (
    <Layout.Header>
      {isOrganizationOneascent(organization) && (
        <Row justify="space-between">
          <Image
            id={"headerLogo"}
            preview={false}
            src={token.logoSrc}
            width={160}
          />
          <OneAscentSlogan id={"oneascentSlogan"} style={{ width: 160 }} />
        </Row>
      )}
      {isOrganizationRivershares(organization) && (
        <Row>
          <Image
            id={"headerLogo"}
            preview={false}
            src={token.logoSrc}
            width={160}
          />
        </Row>
      )}
      {isOrganizationLevel(organization) &&
        userManagerData?.access?.penny(
          <Row justify="space-between">
            <LevelLogo id={"headerLogo"} style={{ width: 160 }} />
            <PennyChatToggler
              handleClick={() => openModal("openModalAskAdvice")}
            />
          </Row>
        )}
      {!organization && (
        <Row justify="space-between">
          <Image
            id={"headerLogo"}
            preview={false}
            src={token.logoSrc}
            width={160}
          />
          <Button
            type="secondary"
            shape="round"
            style={{
              width: 40,
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => openModal("openModalAskAdvice")}
          >
            <GetAdvice />
          </Button>
        </Row>
      )}
    </Layout.Header>
  );
};

export default Header;
