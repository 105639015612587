import dayjs from "dayjs";

export const DEFAULT_BIRTH_YEAR = 1987;

export const DEFAULT_PRODUCT_ACCENT_COLOR = "#93191F";

export const DEFAULT_ADVISOR_FEE = 1;

export const DEFAULT_RETIREMENT_AGE = 67;

export const DEFAULT_START_WITHDRAWAL_YEAR = 1;

export const DEFAULT_WITHDRAWAL_LEVEL = "4%";

export const GOALS_RISK_TYPES = [
  { value: "aggressive", label: "Aggressive" },
  { value: "moderate", label: "Moderate" },
  { value: "conservative", label: "Conservative" },
];

export const LEVEL_SAMPLE_PROPOSAL_URL =
  "https://ontrajectory.s3.amazonaws.com/level/sample-proposals/level_proposal.pdf";

export const MAX_COMPARE_PRODUCTS = 3;

export const MC_DEFAULT_SETTINGS = {
  mcExpectedReturn: 8.88,
  mcVolatilityReturn: 18.54,
  mcExpectedInflation: 0,
  mcVolatilityInflation: 0,
};

export const MIN_BIRTH_YEAR = 1920;

export const NEW_CLIENT_DEFAULT_DATA = {
  firstName: "",
  lastName: "",
  email: "",
  doNotNotifyManagedUser: true,
  enableOnboardingQuestions: true,
  maritalStatus: "single",
  planningType: "Cashflow",
  planType: "basic",
  retirementAge: DEFAULT_RETIREMENT_AGE,
  scenarioName: "Scenario 1",
  startDate: dayjs(),
  yearBorn: 1980,
  totalSavings: 0,
  annualIncome: 0,
  savingsPerMonth: 0,
  accounts: [
    {
      key: 0,
      accountType: "529 Plan",
      startingBalance: 0,
      startAge: 43,
      endAge: 90,
      growth: 5,
    },
    {
      key: 1,
      accountType: "Other Account",
      startingBalance: 0,
      startAge: 43,
      endAge: 90,
      growth: 4.5,
    },
  ],
  incomes: [
    {
      key: 0,
      incomeType: "Regular Income",
      annualAmount: 0,
      startAge: 43,
      endAge: 90,
    },
    {
      key: 1,
      incomeType: "Social Security",
      annualAmount: 21500,
      startAge: 43,
      endAge: 90,
    },
  ],
  expenses: [
    {
      key: 0,
      expenseType: "Vehicle Payment",
      amount: 2500,
      frequency: "Monthly",
      startAge: 43,
      endAge: 90,
    },
    {
      key: 1,
      expenseType: "Property Taxes",
      amount: 2500,
      frequency: "Monthly",
      startAge: 43,
      endAge: 90,
    },
  ],
};

export const ONEASCENT_GOAL_TYPES = [
  {
    value: "growth",
    label: "Growth",
  },
  {
    value: "income",
    label: "Income",
  },
];

export const ONEASCENT_RISK_TYPES = [
  { value: "equity", label: "Equity" },
  { value: "growth", label: "Growth" },
  { value: "moderate", label: "Moderate" },
  { value: "conservative", label: "Conservative" },
  { value: "preservation", label: "Preservation" },
];

export const ONEASCENT_ELEVATE_STORIES_LINK =
  "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent_Investments_Align_Elevate_Stories_Q3_2023.pdf";

export const ONEASCENT_PORTFOLIO_X_RAY_LIST = {
  equity: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    tableData: [
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "40%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "30%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "30%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "9.6%",
        company: "",
        issue: "",
      },
    ],
    typicalPortfolioNotAlignedValue: 9.6,
    pieChartData: [
      {
        name: "ACWX",
        y: 40,
      },
      {
        name: "SMMD",
        y: 30,
      },
      {
        name: "SPY",
        y: 30,
      },
    ],
  },
  growth: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    tableData: [
      {
        indexName: "Bloomberg US Agg Bond TR USD",
        etf: "AGG",
        weight: "20%",
        weighted: "1.8%",
        company: "The Boeing Co",
        issue: "Product Safety & Quality",
      },
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "32%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "24%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "24%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "8%",
        company: "",
        issue: "",
      },
    ],
    typicalPortfolioNotAlignedValue: 8.0,
    pieChartData: [
      {
        name: "AGG",
        y: 20,
      },
      {
        name: "ACWX",
        y: 32,
      },
      {
        name: "SMMD",
        y: 24,
      },
      {
        name: "SPY",
        y: 24,
      },
    ],
  },
  moderate: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    typicalPortfolioNotAlignedValue: 6.5,
    tableData: [
      {
        indexName: "Bloomberg US Agg Bond TR USD",
        etf: "AGG",
        weight: "40%",
        weighted: "1.8%",
        company: "The Boeing Co",
        issue: "Product Safety & Quality",
      },
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "24%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "18%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "18%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "6.5%",
        company: "",
        issue: "",
      },
    ],
    pieChartData: [
      {
        name: "AGG",
        y: 40,
      },
      {
        name: "ACWX",
        y: 24,
      },
      {
        name: "SMMD",
        y: 18,
      },
      {
        name: "SPY",
        y: 18,
      },
    ],
  },
  conservative: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    tableData: [
      {
        indexName: "Bloomberg US Agg Bond TR USD",
        etf: "AGG",
        weight: "60%",
        weighted: "1.8%",
        company: "The Boeing Co",
        issue: "Product Safety & Quality",
      },
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "16%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "12%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "12%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "4.9%",
        company: "",
        issue: "",
      },
    ],
    typicalPortfolioNotAlignedValue: 4.9,
    pieChartData: [
      {
        name: "AGG",
        y: 60,
      },
      {
        name: "ACWX",
        y: 16,
      },
      {
        name: "SMMD",
        y: 12,
      },
      {
        name: "SPY",
        y: 12,
      },
    ],
  },
  preservation: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    tableData: [
      {
        indexName: "Bloomberg US Agg Bond TR USD",
        etf: "AGG",
        weight: "80%",
        weighted: "1.8%",
        company: "The Boeing Co",
        issue: "Product Safety & Quality",
      },
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "8%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "6%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "6%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "3.4%",
        company: "",
        issue: "",
      },
    ],
    typicalPortfolioNotAlignedValue: 3.4,
    pieChartData: [
      {
        name: "AGG",
        y: 80,
      },
      {
        name: "ACWX",
        y: 8,
      },
      {
        name: "SMMD",
        y: 6,
      },
      {
        name: "SPY",
        y: 6,
      },
    ],
  },
};

export const PDF_CONVERTER_OPTIONS = {
  margin: 0,
  filename: "demo.pdf",
  image: { type: "jpeg", quality: 1 },
  enableLinks: false,
  html2canvas: {
    onclone: element => {
      const svgElements = Array.from(element.querySelectorAll("svg"));
      svgElements.forEach(s => {
        const bBox = s.getBBox();
        s.setAttribute("x", bBox.x);
        s.setAttribute("y", bBox.y);
        s.setAttribute("width", bBox.width);
        s.setAttribute("height", bBox.height);
      });
    },
    scale: 2,
    width: 816,
  },
  jsPDF: {
    unit: "in",
    format: "letter",
    orientation: "portrait",
    width: 228,
    windowWidth: 816,
  },
};

export const PENDO_HOSTNAMES_LIST = [
  "align.oneascent.com",
  "go.ontrajectory.com",
  "www.goals.ontrajectory.com",
  "leveldemo.clevercx.com",
];

export const PENNY_FEEDBACK_FORM_URL =
  "https://share.hsforms.com/1TfzAHOV8SzSlz6j_6u1-4gdch11";

export const PENNY_TIPS_LIST = [
  {
    title: "How should I plan for retirement?",
    description: "Understand the basics of retirement planning.",
  },
  {
    title: "How much should I have in my emergency savings?",
    description: "Penny can help you figure out the right amount to save.",
  },
  {
    title: "What are the best questions to ask a financial advisor?",
    description: "Let Penny help you prepare for a conversation.",
  },
];

export const PRODUCTS_FACT_SHEETS_LIST = {
  "OneAscent Peak Equity Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Equity-Core-Fact-Sheet.pdf",
  "OneAscent Peak Growth Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Growth-Core-Fact-Sheet.pdf",
  "OneAscent Peak Moderate Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Moderate-Core-Fact-Sheet.pdf",
  "OneAscent Peak Conservative Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Conservative-Core-Fact-Sheet.pdf",
  "OneAscent Peak Preservation Core":
    "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent-Peak-Preservation-Core-Fact-Sheet.pdf",
  "Aggressive Growth Strategy": "",
  "Moderate Growth Strategy": "",
  "Conservative Growth Strategy": "",
};

export const RIVERSHARES_CAPITAL_MARKET_ASSUMPTIONS_LINK =
  "https://ontrajectory.s3.amazonaws.com/rivershares/Capital_Market_Assumptions.pdf";

export const RIVERSHARES_GOAL_TYPES = [
  {
    value: "Accumulate",
    label: "Accumulate",
  },
  {
    value: "Distribute",
    label: "Distribute",
  },
];

export const RIVERSHARES_RISK_TYPES = [
  { value: "Income", label: "Income" },
  { value: "Income/Growth", label: "Income and Growth" },
  { value: "Growth", label: "Growth" },
];

export const TERMS_LINK = "https://qa.ontrajectory.com/terms-of-service";
