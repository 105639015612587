import styled from "styled-components";
import {
  Button,
  Form,
  InputNumber,
  Radio,
  Space,
  theme,
  Typography,
} from "antd";

import {
  cleanCurrencyValue,
  cleanPercentValue,
  getCurrencyValue,
  getPercentValue,
  selectValue,
} from "../../../../../../utils/helpers/general";
import {
  isGrowthTypeRiskTolerance,
  isLockedByAdminView,
  isOrganizationRivershares,
} from "../../../../../../utils/helpers/specialized";
import {
  DEFAULT_ADVISOR_FEE,
  ONEASCENT_GOAL_TYPES,
  RIVERSHARES_GOAL_TYPES,
} from "../../../../../../utils/constants";

import { ReactComponent as TargetAmount } from "../../../../../../icons/target_marker.svg";

const { Text } = Typography;

const StyledForm = styled(Form)`
  & .ant-form-item {
    margin: 0;
  }

  & .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #009a00;
    background-color: #009a00;
  }

  & .ant-input-number {
    background: #ebf1f3;
    border-color: transparent;
  }
`;

const InvestmentAssumptions = ({
  handleProposalDataChange,
  managerAccess,
  openGoalSettingsModal,
  organization,
  proposalData,
}) => {
  const { token } = theme.useToken();

  const getRadioOptions = () => {
    if (isOrganizationRivershares(organization)) {
      return RIVERSHARES_GOAL_TYPES.map(({ value, label }) => (
        <Radio key={value} value={value}>
          {label}
        </Radio>
      ));
    } else {
      return ONEASCENT_GOAL_TYPES.map(({ value, label }) => (
        <Radio key={value} value={value}>
          {label}
        </Radio>
      ));
    }
  };

  const handleInitialInvestmentChange = e => {
    const cleanValue = cleanCurrencyValue(e.target?.value);
    const validatedValue =
      cleanValue > 10000000 ? 10000000 : cleanValue < 0 ? 0 : cleanValue;

    if (!isNaN(validatedValue)) {
      handleProposalDataChange("investmentAmount", +validatedValue);
      e.target.value = getCurrencyValue(validatedValue);
    }
  };

  return (
    <StyledForm layout="vertical">
      <Space direction={"vertical"} size={16}>
        {organization !== "goals" && (
          <Form.Item>
            <Radio.Group
              value={proposalData?.investmentObjective}
              onChange={e =>
                handleProposalDataChange("investmentObjective", e.target.value)
              }
            >
              <Space direction={"vertical"}>{getRadioOptions()}</Space>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item label="Initial Investment">
          <InputNumber
            step={1000}
            min={0}
            max={10000000}
            value={proposalData?.investmentAmount}
            style={{ width: "100%" }}
            formatter={getCurrencyValue}
            parser={cleanCurrencyValue}
            onClick={selectValue}
            onKeyUp={handleInitialInvestmentChange}
          />
        </Form.Item>
        <Form.Item label="Length of Investment">
          <InputNumber
            value={proposalData?.investmentDuration}
            min={0}
            max={80}
            style={{ width: "100%" }}
            formatter={value => `${value} years`}
            parser={value => value?.replace(" years", "")}
            onChange={value =>
              handleProposalDataChange("investmentDuration", value)
            }
            onClick={selectValue}
          />
        </Form.Item>
        {!isGrowthTypeRiskTolerance(proposalData?.investmentObjective) && (
          <>
            <Form.Item label="Investment Withdrawal in Retirement">
              <InputNumber
                min={0}
                max={100}
                value={cleanPercentValue(proposalData?.withdrawalLevel)}
                formatter={value => (value ? `${value}%` : "")}
                parser={value => value.replace("%", "")}
                style={{ width: "100%" }}
                onChange={value =>
                  handleProposalDataChange("withdrawalLevel", value)
                }
                onClick={selectValue}
              />
            </Form.Item>
            <Form.Item label="Begin Withdrawal after">
              <InputNumber
                min={1}
                max={80}
                value={proposalData?.yearToStartWithdrawals}
                formatter={value => (value ? `${value} years` : "")}
                parser={value => value?.replace(" years", "")}
                style={{ width: "100%" }}
                onChange={value =>
                  handleProposalDataChange("yearToStartWithdrawals", value)
                }
                onClick={selectValue}
              />
            </Form.Item>
          </>
        )}
        <Space align={"start"}>
          <Space direction={"vertical"} size={0}>
            <Text style={{ color: token.colorUiText }}>
              {isGrowthTypeRiskTolerance(proposalData?.investmentObjective)
                ? "Target Amount"
                : "End Target Amount"}
            </Text>
            <Text style={{ color: token.colorBlack }} strong>
              {getCurrencyValue(proposalData?.targetAmount)}
            </Text>
          </Space>
          <TargetAmount style={{ marginTop: 4 }} />
        </Space>
        {isGrowthTypeRiskTolerance(proposalData?.investmentObjective) && (
          <Space direction={"vertical"} size={0}>
            <Text style={{ color: token.colorUiText }}>
              Monthly Contributions
            </Text>
            <Text style={{ color: token.colorBlack }} strong>
              {getCurrencyValue(Math.round(proposalData?.contributions / 12))}
            </Text>
          </Space>
        )}
        <Space direction={"vertical"} size={0}>
          <Text style={{ color: token.colorUiText }}>Risk Tolerance</Text>
          <Text
            style={{ color: token.colorBlack, textTransform: "capitalize" }}
            strong
          >
            {proposalData?.riskTolerance}
          </Text>
        </Space>
        <Space direction={"vertical"} size={0}>
          <Text style={{ color: token.colorUiText }}>Advisor Fee</Text>
          <Text style={{ color: token.colorBlack }} strong>
            {getPercentValue(proposalData?.advisorFee ?? DEFAULT_ADVISOR_FEE)}
          </Text>
        </Space>
        <Button
          block
          disabled={isLockedByAdminView({ managerAccess })}
          onClick={openGoalSettingsModal}
          shape={"round"}
          size={"small"}
          style={{ background: "#354A5F", color: "#FFFFFF" }}
          type={"text"}
        >
          Edit
        </Button>
      </Space>
    </StyledForm>
  );
};

export default InvestmentAssumptions;
