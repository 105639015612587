import styled from "styled-components";
import PageDashboard from "../pages/PageDashboard";
import PageLeads from "../pages/PageLeads";
import PageLevelClients from "../pages/PageLevelClients";
import PageMarketing from "../../../pages/PageMarketing";
import PageNextClients from "../pages/PageNextClients";
import PageProducts from "../../../pages/PageProducts";
import PageProspects from "../../../pages/PageProspects";

const Container = styled.div`
  padding: 42px 78px 27px 35px;
`;

export default [
  {
    path: "/next-advisor-portal/no-access",
    Component: () => {},
  },
  {
    path: "/next-advisor-portal/dashboard",
    Component: PageDashboard,
  },
  {
    path: "/next-advisor-portal/leads",
    Component: PageLeads,
  },
  {
    path: "/next-advisor-portal/prospects",
    Component: () => (
      <Container>
        <PageProspects />
      </Container>
    ),
  },
  {
    path: "/next-advisor-portal/clients",
    Component: PageNextClients,
  },
  {
    path: "/next-advisor-portal/proposals",
    Component: () => (
      <Container>
        <PageLevelClients />
      </Container>
    ),
  },
  {
    path: "/next-advisor-portal/products",
    Component: () => (
      <Container>
        <PageProducts />
      </Container>
    ),
  },
  {
    path: "/next-advisor-portal/marketing",
    Component: () => (
      <Container>
        <PageMarketing />
      </Container>
    ),
  },
];
