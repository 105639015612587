import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, ConfigProvider, Flex, Row, theme } from "antd";
import SectionBitAboutYou from "./components/BitAboutYou";
import SectionSeekToElevate from "../SectionSeekToElevate";
import SectionSeekToEliminate from "../SectionSeekToEliminate";
import InvestmentObjectiveSection from "./components/InvestmentObjectiveSection";
import RiskToleranceSection from "./components/RiskToleranceSection";

import { defaultProposalData } from "./constants";

import { ReactComponent as OneAscentSlogan } from "../../../../ecosystems/oneascent/images/slogan.svg";
import { ReactComponent as OneAscentLogo } from "../../../../ecosystems/oneascent/images/logo.svg";

const Container = styled.div`
  height: 100%;
  padding: 80px 40px;
  border-bottom: unset;
  background: transparent linear-gradient(360deg, #4897d100 0%, #4897d110 100%);
  background-size: contain;
  box-sizing: border-box;
  overflow: auto;

  .content-body {
    max-width: 1100px;
    margin: 0 auto;
  }

  .title {
    line-height: 40px;
    font-size: 32px;
    font-weight: bold;
    color: #15284b;
  }

  .description {
    line-height: 28px;
    font-size: 22px;
    font-weight: 300;
    color: #15284b;
  }

  & * {
    font-family: ${props => props.fontFamily};
  }

  @media screen and (max-width: 720px) {
    padding: 40px;
  }
`;

const ContentWealt = ({
  open,
  handleClose,
  handleCreateProposal,
  setShowDisclosuresModal,
}) => {
  const { token } = theme.useToken();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [proposalData, setProposalData] = useState(defaultProposalData);

  useEffect(() => {
    if (!open) {
      setCurrentStepIndex(0);
      setProposalData(defaultProposalData);
    }
  }, [open]);

  const updateProposalData = (key, value) =>
    setProposalData(state => ({
      ...state,
      [key]: value,
    }));

  const handleGuideStepIndexChange = newStepIndex => {
    if (newStepIndex === -1) {
      handleClose();
    } else if (newStepIndex === 5) {
      handleCreateProposal({
        ...proposalData,
        contributions: proposalData.contributions * 12,
        targetAmount: 0,
        advisorFee: "1%",
        withdrawalLevel: "4%",
        yearToStartWithdrawals: 1,
        investmentsToAvoid: JSON.stringify(proposalData.investmentsToAvoid),
        investmentsToAlignWith: JSON.stringify(
          proposalData.investmentsToAlignWith
        ),
      });
    } else {
      setCurrentStepIndex(newStepIndex);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: token.proposalGuideRadio,
          Checkbox: token.proposalGuideCheckbox,
        },
      }}
    >
      <Container fontFamily={token.proposalGuideFontPrimary}>
        <div className={"content-body"}>
          <Row
            justify="space-between"
            style={{
              borderBottom: "1px solid #6DC1C7",
              marginBottom: 25,
              paddingBottom: 10,
            }}
          >
            <OneAscentLogo style={{ width: 160 }} />
            <OneAscentSlogan style={{ width: 160 }} />
          </Row>
          {currentStepIndex === 0 && (
            <SectionSeekToEliminate
              proposalData={proposalData}
              updateProposalData={updateProposalData}
              openDisclosuresModal={() => setShowDisclosuresModal(true)}
            />
          )}
          {currentStepIndex === 1 && (
            <SectionSeekToElevate
              proposalData={proposalData}
              updateProposalData={updateProposalData}
              openDisclosuresModal={() => setShowDisclosuresModal(true)}
            />
          )}
          {currentStepIndex === 2 && (
            <InvestmentObjectiveSection
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 3 && (
            <RiskToleranceSection updateProposalData={updateProposalData} />
          )}
          {currentStepIndex === 4 && (
            <SectionBitAboutYou
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          <Flex justify={"space-evenly"} style={{ marginTop: 25 }}>
            <Button
              shape="round"
              style={{
                width: 146,
                fontSize: 18,
                backgroundColor: "#BEC8CA",
                color: "#192849",
              }}
              onClick={() => handleGuideStepIndexChange(currentStepIndex - 1)}
            >
              {currentStepIndex === 0 ? "Close" : "Back"}
            </Button>
            <Button
              shape="round"
              style={{
                width: 146,
                fontSize: 18,
                backgroundColor: "#192849",
                color: "#FFFFFF",
              }}
              onClick={() => handleGuideStepIndexChange(currentStepIndex + 1)}
            >
              {currentStepIndex === 4 ? "Create" : "Continue"}
            </Button>
          </Flex>
        </div>
      </Container>
    </ConfigProvider>
  );
};

export default ContentWealt;
