import styled from "styled-components";
import { Flex } from "antd";

const ItemContainer = styled.div`
  width: ${props => props.width};
  background: #ffffff;
  padding: 22px;
  border-radius: 12px;
  box-shadow: 0 0 16px #0000000d;
`;

const LeadsAnalyticsSection = ({ leadsList }) => {
  const getLeadsConverted = () =>
    leadsList?.reduce(
      (acc, it) => acc + it.valueMap?.leadInitial?.isConverted || 0,
      0
    );

  const getLeadsRating = () => {
    const totalLeadsRate = leadsList?.reduce(
      (acc, it) => acc + it.valueMap?.leadInitial?.rate || 0,
      0
    );

    return totalLeadsRate
      ? ((leadsList?.length / totalLeadsRate) * 10)?.toFixed(2)
      : 0;
  };

  return (
    <Flex gap={24}>
      <ItemContainer width={"223px"}>
        <b style={{ fontSize: 32, color: "#296469" }}>
          {leadsList?.length ?? 0}
        </b>
        <div style={{ fontSize: 15, color: "#296469" }}>Total Active Leads</div>
      </ItemContainer>
      <ItemContainer width={"223px"}>
        <b style={{ fontSize: 32, color: "#296469" }}>{getLeadsConverted()}</b>
        <div style={{ fontSize: 15, color: "#296469" }}>Leads Converted</div>
      </ItemContainer>
      <ItemContainer width={"223px"}>
        <b style={{ fontSize: 32, color: "#296469" }}>{getLeadsRating()}</b>
        <div style={{ fontSize: 15, color: "#296469" }}>
          Average Lead Rating
        </div>
      </ItemContainer>
    </Flex>
  );
};

export default LeadsAnalyticsSection;
