import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import State from "../../context/manager/State";
import { Button, ConfigProvider, Flex, Form, Modal, theme } from "antd";
import GoalsUserTab from "./components/GoalsUserTab";
import TabUser from "../../ecosystems/next/components/ModalNextManagedUserData/components/TabUser";

import {
  addManagedUser,
  addManagedUserExpanded,
  openGoalsManagedUser,
  openManagedUser,
  setLastAdvisorLogin,
  updateManagedUser,
} from "../../utils/requests/manager";
import {
  isAdminView,
  isOrganizationOneascent,
} from "../../utils/helpers/specialized";
import { isValidEmail } from "../../utils/helpers/general";
import { tableItemTypes } from "./constants";
import {
  DEFAULT_RETIREMENT_AGE,
  NEW_CLIENT_DEFAULT_DATA,
} from "../../utils/constants";

import Close from "../../icons/Close";
import { ReactComponent as OpenClientViewIcon } from "./images/open_client_view.svg";

const StyledModal = styled(Modal)`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  &.ant-modal {
    .ant-modal-body {
      min-height: 352px;
      padding: 2px;
      box-sizing: border-box;
      overflow: auto;
    }

    .ant-modal-content {
      padding: 23px 32px 40px;
    }

    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 28px;
      }

      .ant-tabs-tab-btn {
        min-width: 64px;
        text-align: center;
      }

      .ant-tabs-ink-bar {
        height: 8px;
        background: #03494e;
        border-radius: 11px;
      }
    }
  }

  .ant-input,
  .ant-picker,
  .ant-input-number {
    background: #ebf1f3;
    border-color: transparent;
  }
`;

const ModalManagedUserData = ({ handleClose, isLevelView, open }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [managerState] = useContext(State);
  const [editView, setEditView] = useState(false);

  const isGoalsPlanningType =
    (managerState.access?.affiliate &&
      !managerState.access?.level &&
      !managerState.access?.next &&
      !managerState.access?.proportal) ||
    (managerState.activeEmail &&
      managerState.managedUsers.find(
        it => it.email === managerState.activeEmail
      )?.valueMap?.leadInitial?.planningType === "goals");

  const isOneascent = isOrganizationOneascent(
    managerState.orgName ?? managerState.organization?.name
  );

  useEffect(() => {
    if (managerState.activeEmail) {
      const existedUserData = managerState.managedUsers.find(
        it => it.email === managerState.activeEmail
      );

      if (existedUserData) {
        setEditView(true);

        form.setFieldsValue({
          ...existedUserData,
          oldEmail: existedUserData.email,
          enableOnboardingQuestions:
            existedUserData.enableOnboardingQuestions ??
            existedUserData.valueMap?.guideInitialData
              ?.enableOnboardingQuestions ??
            true,
          retirementAge:
            existedUserData.retirementAge ??
            existedUserData.valueMap?.guideInitialData?.retirementAge ??
            DEFAULT_RETIREMENT_AGE,
          yearBorn:
            existedUserData.birthYear ??
            existedUserData.yearBorn ??
            existedUserData.valueMap?.guideInitialData?.yearBorn,
        });
      }
    } else {
      setEditView(false);
      form.resetFields();
      form.setFieldsValue(NEW_CLIENT_DEFAULT_DATA);
    }
  }, [open]);

  const onFinish = () => {
    const formValues = form.getFieldsValue();

    if (!isGoalsPlanningType) {
      if (!isValidEmail(formValues.email)) {
        managerState.showWarning("Invalid email address, please re-enter.");
        return;
      } else if (!formValues.firstName) {
        managerState.showWarning("Enter new user First name, please.");
        return;
      } else if (!formValues.lastName) {
        managerState.showWarning("Enter new user Last name, please.");
        return;
      } else if (
        formValues.yearBorn &&
        formValues.startDate &&
        formValues.yearBorn > dayjs(formValues.startDate.$d).format("YYYY")
      ) {
        managerState.showWarning(
          "Birth Year could not be bigger, than Start Date."
        );
        return;
      }
    }

    managerState.showLoader();

    if (formValues.oldEmail) {
      updateManagedUser(formValues)
        .then(() => {
          managerState.setAdminData().then(() => {
            handleClose();
            managerState.showSuccess("Client data updated");
          });
        })
        .catch(console.log);
    } else {
      if (!formValues.planType || formValues.planType === "basic") {
        const requestBody = {
          doNotNotifyManagedUser: true,
          maritalStatus: "single",
          scenarioName: "Scenario 1",
          startDate: formValues.startDate?.$d ?? new Date(),
          planningType: "goals",
          ...formValues,
        };

        addManagedUser(requestBody)
          .then(response => {
            managerState.showSuccess(response.data);
            handleClose();

            managerState.setAdminData(() => {
              managerState.hideLoader();
            });
          })
          .catch(error => {
            managerState.showWarning(error.response?.data || error.message);
            managerState.hideLoader();
          });
      } else {
        const requestBody = {
          ...formValues,
          startDate: formValues.startDate?.$d,
          accounts: JSON.stringify(
            formValues.accounts.map(accountData => ({
              account_name: accountData.accountType,
              account_type: tableItemTypes.find(
                it => it.title === accountData.accountType
              ).type,
              starting_balance: accountData.startingBalance,
              growth: accountData.growth,
              start_age: accountData.startAge,
              end_age: accountData.endAge,
            }))
          ),
          incomes: JSON.stringify(
            formValues.incomes.map(incomeData => ({
              income_name: incomeData.incomeType,
              income_type: tableItemTypes.find(
                it => it.title === incomeData.incomeType
              ).type,
              annual_amount: incomeData.annualAmount,
              start_age: incomeData.startAge,
              end_age: incomeData.endAge,
            }))
          ),
          expenses: JSON.stringify(
            formValues.expenses.map(expenseData => ({
              expense_name: expenseData.expenseType,
              expense_type: tableItemTypes.find(
                it => it.title === expenseData.expenseType
              ).type,
              amount: expenseData.amount,
              frequency: expenseData.frequency,
              start_age: expenseData.startAge,
              end_age: expenseData.endAge,
            }))
          ),
        };

        addManagedUserExpanded(requestBody)
          .then(response => {
            managerState.showSuccess(response.data);
            handleClose();

            managerState.setAdminData(() => {
              managerState.hideLoader();
            });
          })
          .catch(error => {
            managerState.showWarning(error.response?.data || error.message);
            managerState.hideLoader();
          });
      }
    }
  };

  const handleOpenClientView = () => {
    if (isGoalsPlanningType) {
      openGoalsManagedUser(managerState.activeEmail);
    } else {
      openManagedUser(managerState.activeEmail);
    }

    setLastAdvisorLogin(managerState.activeEmail).then(() =>
      managerState.setAdminData()
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            colorPrimary: token.colorRadioButton,
          },
        },
      }}
    >
      <StyledModal
        className={"add-client-modal"}
        closeIcon={<Close />}
        footer={
          managerState.activeEmail &&
          (!isGoalsPlanningType || managerState.access?.affiliate) ? (
            ""
          ) : (
            <Flex gap={16}>
              <Button
                disabled={managerState.loading || isAdminView()}
                form={"clientInfoForm"}
                onClick={onFinish}
                htmlType={"button"}
                shape={"round"}
                type={"primary"}
              >
                {editView ? "Update" : "Create User"}
              </Button>
              <Button
                htmlType={"button"}
                onClick={handleClose}
                shape={"round"}
                style={{ background: "#E6F4EF", color: token.colorPrimary }}
                type={"text"}
              >
                Close
              </Button>
            </Flex>
          )
        }
        onCancel={handleClose}
        open={open}
        title={
          editView ? (
            <Flex
              align={"center"}
              justify={"space-between"}
              style={{ paddingRight: 40 }}
            >
              <b style={{ fontSize: 18, color: "#1B5568" }}>
                {form.getFieldValue("firstName")}{" "}
                {form.getFieldValue("lastName")}
              </b>
              {!isLevelView && (
                <Button
                  onClick={handleOpenClientView}
                  shape={"round"}
                  size={"small"}
                  style={{
                    width: 170,
                    background: "#E6F4EF",
                    color: "#1B5568",
                  }}
                  type={"primary"}
                >
                  <Flex align={"center"} gap={4}>
                    <span>Open Advisor View</span>
                    <OpenClientViewIcon />
                  </Flex>
                </Button>
              )}
            </Flex>
          ) : (
            "Client Details"
          )
        }
        width={568}
      >
        <Form
          form={form}
          id={"clientInfoForm"}
          layout={"vertical"}
          onFinish={onFinish}
        >
          {isGoalsPlanningType ? (
            <GoalsUserTab />
          ) : (
            <TabUser
              isGoalsPlanningType={isGoalsPlanningType}
              isOneascent={isOneascent}
              managerAccess={managerState.access}
            />
          )}
        </Form>
      </StyledModal>
    </ConfigProvider>
  );
};

export default ModalManagedUserData;
