import { useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  theme,
} from "antd";

import {
  cleanPhoneMask,
  selectValue,
  setPhoneMask,
} from "../../../../../../utils/helpers/general";

import Close from "../../../../../../icons/Close";
import dayjs from "dayjs";

const defaultFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  isNotifyAdvisor: true,
};

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-close {
      top: 28px;
      right: 43px;
    }

    .ant-modal-title {
      font-weight: 700;
    }

    .ant-modal-header {
      margin-bottom: 29px;
    }

    .ant-modal-content {
      padding: 33px 45px 27px 33px;
    }

    .ant-modal-footer {
      margin-top: 0;
    }
  }

  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number {
    background: #ebf1f3;
    border-color: transparent;
  }

  textarea.ant-input {
    padding: 20px;
  }

  .ant-switch.ant-switch-checked,
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #6073e2;
  }

  .ant-form-item .ant-form-item-label {
    padding: 0;

    > label {
      color: ${({ token }) => token.colorGrey1};
    }
  }
`;

const ModalAssignLead = ({
  activeLeadData,
  advisorsList,
  handleAssignLead,
  handleUpdateLead,
  loading,
  onCancel,
  open,
}) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  useEffect(() => {
    if (open) {
      if (activeLeadData) {
        form.setFieldsValue({
          ...activeLeadData,
          ...activeLeadData.valueMap?.leadInitial,
        });
      } else {
        form.setFieldsValue(defaultFormValues);
      }
    } else {
      form.resetFields();
    }
  }, [open]);

  const onFinish = () => {
    if (activeLeadData) {
      handleUpdateLead(form.getFieldsValue());
    } else {
      handleAssignLead(form.getFieldsValue());
    }
  };

  const getOptions = () =>
    advisorsList
      ?.filter(it => it.userManager?.access?.next)
      ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
      ?.map(it => ({
        value: it.email,
        label: `${it.firstName ?? ""} ${it.lastName ?? ""}`,
      })) ?? [];

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            form={"assignLeadForm"}
            htmlType={"submit"}
            shape={"round"}
            style={{ width: 75 }}
            type={"primary"}
          >
            Save
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={activeLeadData ? "Edit lead" : "Assign a lead…"}
      token={token}
      width={902}
    >
      <Form
        layout={"vertical"}
        id={"assignLeadForm"}
        form={form}
        onFinish={onFinish}
      >
        <Flex gap={56}>
          <Flex vertical>
            <Form.Item
              label={"First Name"}
              name={"firstName"}
              rules={[
                {
                  required: true,
                },
              ]}
              style={{ width: 206 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Last Name"}
              name={"lastName"}
              rules={[
                {
                  required: true,
                },
              ]}
              style={{ width: 206 }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={"Email"}
              name={"email"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input disabled={activeLeadData} />
            </Form.Item>
            <Form.Item
              label={"Phone"}
              name={"phoneNumber"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                formatter={setPhoneMask}
                onClick={selectValue}
                parser={cleanPhoneMask}
                style={{ width: 206 }}
              />
            </Form.Item>
            <Form.Item
              label={"Assign question to"}
              name={"assignQuestionTo"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={getOptions()} />
            </Form.Item>
            <Flex gap={15}>
              <Form.Item
                id={"switchHideLeadContact"}
                name={"hideLeadContact"}
                valuePropName={"checked"}
                labelAlign={"left"}
              >
                <Switch />
              </Form.Item>
              <label
                htmlFor={"switchHideLeadContact"}
                style={{
                  fontWeight: 500,
                  color: token.colorBlack,
                  marginTop: 6,
                }}
              >
                Hide lead contact info
              </label>
            </Flex>
            <Flex gap={15}>
              <Form.Item
                id={"switchIsNotifyAdvisor"}
                name={"isNotifyAdvisor"}
                valuePropName={"checked"}
                labelAlign={"left"}
              >
                <Switch />
              </Form.Item>
              <label
                htmlFor={"switchIsNotifyAdvisor"}
                style={{
                  fontWeight: 500,
                  color: token.colorBlack,
                  marginTop: 6,
                }}
              >
                Notify advisor
              </label>
            </Flex>
          </Flex>
          <Flex style={{ width: "100%" }} vertical>
            <Flex justify={"space-between"} style={{ width: "100%" }}>
              <label
                htmlFor={"questionTextarea"}
                style={{ color: token.colorGrey1 }}
              >
                Question from lead
              </label>
              <span>Sent {dayjs().format("MM/DD/YY, h:mma")}</span>
            </Flex>
            <Form.Item name={"question"}>
              <Input.TextArea
                autoSize={{ minRows: 8, maxRows: 8 }}
                id={"questionTextarea"}
                style={{ background: "#fff", border: "1px solid #C9C8C8" }}
              />
            </Form.Item>
            <Form.Item label={"Your reply..."} name={"answer"}>
              <Input.TextArea
                autoSize={{ minRows: 10, maxRows: 10 }}
                disabled
              />
            </Form.Item>
          </Flex>
        </Flex>
      </Form>
    </StyledModal>
  );
};

export default ModalAssignLead;
