import { useContext, useEffect, useState } from "react";
import State from "../../../../context/admin/State";
import styled from "styled-components";
import { Button, Flex, Form, Input, InputNumber, Select } from "antd";
import ModalPrimary from "../ModalPrimary";

import {
  addUser,
  updateUser,
  updateUserEmail,
} from "../../../../requests/global";
import { DEFAULT_PASSWORD } from "../../../../constants";
import {
  cleanPhoneMask,
  selectValue,
  setPhoneMask,
} from "../../../../../../utils/helpers/general";
import IdentityManager from "../../../../requests/aws/IdentityManager";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";

const StyledSelect = styled(Select)`
  width: 100%;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: transparent;
    background: #ebf1f3;
  }
`;

const ModalUser = ({ open }) => {
  const [form] = Form.useForm();
  const [adminState] = useContext(State);
  const [loading, setLoading] = useState(false);
  const activeUserData = adminState.user?.joined?.find(
    it => it._id === adminState.activeUserId
  );

  useEffect(() => {
    if (open && activeUserData) {
      form.setFieldsValue({
        ...activeUserData,
        initialEmail: activeUserData?.email,
      });
    } else {
      form.resetFields();
    }
  }, [open]);

  const onFinish = values => {
    setLoading(true);

    if (activeUserData) {
      updateUser({
        userId: adminState.activeUserId,
        payloadData: {
          ...values,
          phoneNumber: values.phoneNumber + "",
          email: undefined,
        },
      })
        .then(() => {
          if (activeUserData.email !== values.email) {
            updateUserEmail({
              userId: activeUserData._id,
              newEmail: values.email,
            })
              .then(() => {
                // Confirm new account password
                IdentityManager.completeNewPasswordChallenge(
                  values.email,
                  activeUserData.password,
                  activeUserData.password
                )
                  .then(() => {
                    adminState.reFetch.users().then(() => {
                      adminState.showSuccess("User data updated.");
                      handleClose();
                    });
                    setLoading(false);
                  })
                  .catch(e => {
                    console.log(e);
                    setLoading(false);
                  });
              })
              .catch(e => {
                console.log(e);
                setLoading(false);
              });
          } else {
            adminState.reFetch.users().then(() => {
              adminState.showSuccess("User data updated.");
              handleClose();
            });
            setLoading(false);
          }
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    } else {
      addUser({
        ...values,
        phoneNumber: values.phoneNumber + "",
        password: DEFAULT_PASSWORD,
        type: "user",
        managedBy: adminState.user?.email,
        role: ["user"],
      })
        .then(() => {
          adminState.showSuccess("New user invited.");
          handleClose();
          adminState.reFetch.users();
        })
        .catch(error => {
          adminState.showWarning(
            error?.response?.data?.message ||
              (error?.response?.data?.error ?? [])[0].message
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleClose = () => {
    adminState.setKeyValue("activeUserId", null);
    adminState.closeModal("userModal");
  };

  const getOptions = () =>
    adminState.companies
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map(it => ({
        value: it.name,
        label: it.name,
      }));

  return (
    <ModalPrimary
      footer={
        <Flex justify={"space-between"}>
          <Button
            onClick={handleClose}
            shape={"round"}
            size={"large"}
            type={"text"}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            shape={"round"}
            size={"large"}
            style={{ width: 206, background: "#001141", color: "#FFFFFF" }}
            htmlType={"submit"}
            form={"userInfoForm"}
          >
            {activeUserData ? (
              "Save Updates"
            ) : (
              <Flex align={"center"} gap={8} justify={"center"}>
                <span>Send Invitation</span>
                <BlueArrow style={{ rotate: "270deg" }} />
              </Flex>
            )}
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={activeUserData ? "Edit User" : "Invite User"}
      width={492}
    >
      <Form
        form={form}
        id={"userInfoForm"}
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Flex gap={16} vertical>
          <Flex gap={16}>
            <Form.Item
              label={"First Name"}
              name={"firstName"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input onClick={selectValue} />
            </Form.Item>
            <Form.Item
              label={"Last Name"}
              name={"lastName"}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input onClick={selectValue} />
            </Form.Item>
          </Flex>
          <Form.Item
            label={"Email"}
            name={"email"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input onClick={selectValue} />
          </Form.Item>
          <Form.Item
            label={"Phone Number"}
            name={"phoneNumber"}
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value?.toString().length >= 10
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Required format (111) 111-1111")
                      ),
              },
            ]}
          >
            <InputNumber
              controls={false}
              formatter={setPhoneMask}
              onClick={selectValue}
              parser={cleanPhoneMask}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label={"Company"}
            name={"company"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyledSelect options={getOptions()} />
          </Form.Item>
          <Form.Item name={"aumAnnually"} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={"aumQuarterly"} hidden>
            <Input />
          </Form.Item>
        </Flex>
      </Form>
    </ModalPrimary>
  );
};

export default ModalUser;
