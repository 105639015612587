import { useContext, useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import State from "../../../../context/manager/State";
import { Button, Flex, Image, InputNumber } from "antd";
import Bullet from "../../../../atoms/Bullet";
import GuideDisclaimer from "../../../../atoms/GuideDisclaimer";
import InputContainer from "../../../../atoms/InputContainer";

import { defaultProposalData, stepsCopy } from "./constants";
import {
  cleanCurrencyValue,
  getCurrencyValue,
  selectValue,
} from "../../../../utils/helpers/general";
import { isGrowthTypeRiskTolerance } from "../../../../utils/helpers/specialized";

import logo from "../../../../ecosystems/astor/images/logo_main.png";
import right_side_background from "./images/right_side_background.png";

const Container = styled(Flex)`
  background: linear-gradient(125deg, #ffffff 0%, #edfcff 100%);
  height: 100%;

  .left-side {
    width: 100%;
    max-width: 637px;
    padding-top: 105px;

    .body {
      width: 306px;
      max-height: 720px;

      .title {
        line-height: 28px;
        font-family: Montserrat, sans-serif;
        font-size: 27px;
        font-weight: 300;
        color: #04223a;
        margin: 50px 0 10px;
      }

      .description {
        line-height: 22px;
        font-family:
          Open Sans,
          sans-serif;
        font-size: 16px;
        font-weight: 300;
        color: #262626;
        margin: 0;
      }
    }
  }

  .right-side {
    width: 684px;
    padding: 200px 0 40px 40px;
    background: url(${right_side_background}) no-repeat;

    section {
      max-width: 604px;
      background: #ffffff;
      border-radius: 15px;
      opacity: 0.83;
      padding: 42px 67px 50px 49px;
      box-sizing: border-box;

      .title {
        line-height: 40px;
        font-family: Montserrat, sans-serif;
        font-size: 36px;
        margin: 0 0 16px;
        color: #2c5466;
      }

      .description {
        font-family:
          Open Sans,
          sans-serif;
        font-size: 18px;
        font-weight: 300;
        margin: 0;
        color: #2c5466;
      }
    }
  }

  .ant-btn-custom {
    background: #04223a;
    color: #fff;

    &.active,
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: #bfc8ce;
    }
  }
`;

const ContentAstor = ({ open, handleClose, handleCreateProposal }) => {
  const [adminState] = useContext(State);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [proposalData, setProposalData] = useState(defaultProposalData);

  useEffect(() => {
    if (!open) {
      setCurrentStepIndex(0);
      setProposalData(defaultProposalData);
    }
  }, [open]);

  const { title, description, rightSideImage, subTitle, subDescription } =
    stepsCopy[currentStepIndex];

  const updateProposalData = (key, value) =>
    setProposalData(state => ({
      ...state,
      [key]: value,
    }));

  const isContinueButtonDisabled = () =>
    (currentStepIndex === 0 && !proposalData.investmentObjective) ||
    (currentStepIndex === 1 && !proposalData.riskTolerance);

  return (
    <Container>
      <Flex className={"left-side"} justify={"center"}>
        <Flex className={"body"} justify={"space-between"} vertical>
          <Flex vertical>
            <Image preview={false} src={logo} width={256} />
            <h3 className={"title"}>{title}</h3>
            <p className={"description"}>{description}</p>
            <Flex gap={24} vertical style={{ marginTop: 40 }}>
              {currentStepIndex === 0 && (
                <>
                  <Button
                    block
                    type={"custom"}
                    shape={"round"}
                    size={"large"}
                    className={
                      isGrowthTypeRiskTolerance(
                        proposalData.investmentObjective
                      ) && "active"
                    }
                    onClick={() =>
                      updateProposalData("investmentObjective", "growth")
                    }
                  >
                    Drive Growth
                  </Button>
                  <Button
                    block
                    type={"custom"}
                    shape={"round"}
                    size={"large"}
                    className={
                      proposalData.investmentObjective === "income" && "active"
                    }
                    onClick={() =>
                      updateProposalData("investmentObjective", "income")
                    }
                  >
                    Drive Income
                  </Button>
                </>
              )}
              {currentStepIndex === 1 && (
                <>
                  <Button
                    block
                    type={"primary"}
                    shape={"round"}
                    size={"large"}
                    className={
                      proposalData.riskTolerance === "Aggressive" && "active"
                    }
                    onClick={() =>
                      updateProposalData("riskTolerance", "Aggressive")
                    }
                  >
                    Aggressive
                  </Button>
                  <Button
                    block
                    type={"primary"}
                    shape={"round"}
                    size={"large"}
                    className={
                      proposalData.riskTolerance === "Moderate" && "active"
                    }
                    onClick={() =>
                      updateProposalData("riskTolerance", "Moderate")
                    }
                  >
                    Moderate
                  </Button>
                  <Button
                    block
                    type={"primary"}
                    shape={"round"}
                    size={"large"}
                    className={
                      proposalData.riskTolerance === "Conservative" && "active"
                    }
                    onClick={() =>
                      updateProposalData("riskTolerance", "Conservative")
                    }
                  >
                    Conservative
                  </Button>
                  <Button
                    block
                    className={
                      proposalData.riskTolerance === "unknown" && "active"
                    }
                    shape={"round"}
                    size={"large"}
                    onClick={() =>
                      updateProposalData("riskTolerance", "unknown")
                    }
                  >
                    I don&apos;t know
                  </Button>
                </>
              )}
              {currentStepIndex === 2 && (
                <>
                  <InputContainer labelText={"Investment Amount"}>
                    <InputNumber
                      className={"input-custom"}
                      min={0}
                      max={100000000}
                      step={1000}
                      value={proposalData.investmentAmount}
                      formatter={getCurrencyValue}
                      parser={cleanCurrencyValue}
                      onChange={value =>
                        updateProposalData("investmentAmount", value)
                      }
                      onClick={selectValue}
                    />
                  </InputContainer>
                  <InputContainer labelText={"Length of Investment"}>
                    <InputNumber
                      className={"input-custom"}
                      value={proposalData.investmentDuration}
                      min={1}
                      max={80}
                      onChange={value =>
                        updateProposalData("investmentDuration", value)
                      }
                      onClick={selectValue}
                    />
                  </InputContainer>
                  {proposalData.investmentObjective === "income" && (
                    <InputContainer labelText={"Year to Start Withdrawals"}>
                      <InputNumber
                        className={"input-custom"}
                        value={proposalData.yearToStartWithdrawals}
                        min={1}
                        max={80}
                        onChange={value =>
                          updateProposalData("yearToStartWithdrawals", value)
                        }
                        onClick={selectValue}
                      />
                    </InputContainer>
                  )}
                </>
              )}
            </Flex>
          </Flex>
          <Flex align={"center"} gap={4} vertical>
            <Flex
              align={"center"}
              gap={8}
              justify={"center"}
              style={{ marginBottom: 20 }}
            >
              {stepsCopy?.map((elem, index) => (
                <Bullet
                  key={index}
                  size={11}
                  color={currentStepIndex === index ? "#F79105" : "#D1D1D1"}
                />
              ))}
            </Flex>
            {currentStepIndex === 2 ? (
              <Button
                block
                disabled={adminState.loading}
                onClick={() => handleCreateProposal(proposalData)}
                size={"large"}
                type={"custom"}
              >
                Let’s Go
              </Button>
            ) : (
              <Button
                block
                disabled={isContinueButtonDisabled()}
                onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                size={"large"}
                type={"custom"}
              >
                Continue
              </Button>
            )}
            {currentStepIndex === 0 ? (
              <Button block onClick={handleClose} size={"large"} type={"text"}>
                Cancel
              </Button>
            ) : (
              <Button
                block
                onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                size={"large"}
                type={"text"}
              >
                Back
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <MediaQuery minWidth={1200}>
        <Flex align={"center"} className={"right-side"} vertical>
          {rightSideImage}
          <section>
            <h3 className={"title"}>{subTitle}</h3>
            <p className={"description"}>{subDescription}</p>
          </section>
        </Flex>
        {currentStepIndex === 3 && <GuideDisclaimer />}
      </MediaQuery>
    </Container>
  );
};

export default ContentAstor;
