import styled from "styled-components";
import { Row } from "antd";

import { ReactComponent as AddIcon } from "../../images/plus_blue.svg";

const StyledPlaceholder = styled(Row)`
  width: 306px;
  min-width: 306px;
  background: #f5f9fc;
  border: 1px solid #bfc8ce;
  border-radius: 12px;
  cursor: pointer;
  margin: 13px 0 8px;
`;

const CompareCardPlaceholder = ({ handleClick }) => (
  <StyledPlaceholder align={"middle"} justify={"center"} onClick={handleClick}>
    <AddIcon />
  </StyledPlaceholder>
);

export default CompareCardPlaceholder;
