import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../context/manager/State";
import { Flex, Image, Layout, theme } from "antd";
import MenuManagerProfile from "../../molecules/MenuManagerProfile";
import MenuSidebar from "../../molecules/MenuSidebar";
import ModalAddProspect from "../../organisms/ModalAddProspect";
import ModalAdminSettings from "../../organisms/ModalAdminSettings";
import SectionAskAdvice from "../../organisms/SectionAskAdvice";
import ModalProposalGuide from "../../organisms/ModalProposalGuide";

import { MENU_ITEMS } from "./constants";
import {
  isOrganizationAstor,
  isOrganizationBluepoint,
  isOrganizationOneascent,
  isOrganizationRiggs,
  isOrganizationRivershares,
  isPortalAdmin,
} from "../../utils/helpers/specialized";

import LevelLogo from "../../ecosystems/next/images/LevelLogo";
import { ReactComponent as OneascentLogo } from "../../ecosystems/oneascent/images/logo.svg";
import { ReactComponent as RiversharesLogo } from "../../ecosystems/rivershares/images/rivershares_logo_light.svg";
import { ReactComponent as OntrajectoryLogo } from "../../images/ot_logo_light.svg";
import astor_logo from "../../ecosystems/astor/images/logo_circle.svg";
import bluepoint_logo from "../../ecosystems/bluepoint/images/logo.png";
import riggs_logo from "../../ecosystems/riggs/images/logo.png";

const { Content, Sider } = Layout;

const LayoutPrimary = ({ children }) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [managerState, setManagerState] = useContext(State);
  const organization = managerState.orgName ?? managerState.organization?.name;

  const getLogo = () => {
    if (isOrganizationOneascent(organization)) {
      return <OneascentLogo style={{ width: "100%" }} />;
    } else if (isOrganizationRivershares(organization)) {
      return <RiversharesLogo style={{ width: "100%" }} />;
    } else if (isOrganizationRiggs(organization)) {
      return <Image preview={false} src={riggs_logo} />;
    } else if (isOrganizationBluepoint(organization)) {
      return <Image preview={false} src={bluepoint_logo} />;
    } else if (isOrganizationAstor(organization)) {
      return <Image preview={false} src={astor_logo} />;
    } else if (managerState.access?.cashflow) {
      return <OntrajectoryLogo style={{ width: "100%" }} />;
    } else {
      return <LevelLogo />;
    }
  };

  const getMenuItems = () => {
    //routing based on the organization name
    if (isOrganizationAstor(organization)) {
      if (isPortalAdmin()) {
        return MENU_ITEMS.astor.admin;
      } else {
        return MENU_ITEMS.astor.advisor;
      }
    }

    //routing based on the accesses, provided by SuperAdmin
    if (managerState.access) {
      const { next, level, affiliate, proportal } = managerState.access;

      if (!affiliate && !level && next && !proportal) {
        // next only
        return MENU_ITEMS.access.next;
      } else if (!affiliate && level && !next && !proportal) {
        // level only
        return MENU_ITEMS.access.level;
      } else if (!affiliate && !level && !next && proportal) {
        // proportal only
        return MENU_ITEMS.access.proportal;
      } else if (affiliate && !level && !next && !proportal) {
        // affiliate only
        return MENU_ITEMS.access.affiliate;
      } else if (affiliate && next && proportal) {
        return MENU_ITEMS.access.affiliateNextProportal;
      } else if (level && next) {
        return MENU_ITEMS.access.levelNext;
      } else if (affiliate && next) {
        return MENU_ITEMS.access.affiliateNext;
      } else if (affiliate && proportal) {
        return MENU_ITEMS.access.affiliateProportal;
      } else if (next && proportal) {
        return MENU_ITEMS.access.affiliateProportal;
      } else {
        return MENU_ITEMS.disabled;
      }
    }
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        style={{ background: token.sidebarBackground }}
        width={184}
      >
        <Flex
          justify={"space-between"}
          style={{
            width: "100%",
            height: "100%",
            padding: "31px 0 114px 28px",
          }}
          vertical
        >
          <Flex gap={30} vertical>
            <Flex
              className={"pointer"}
              id={"headerLogo"}
              justify={"center"}
              onClick={() => navigate(getMenuItems()[0].path)}
              style={{ marginRight: 24 }}
            >
              {getLogo()}
            </Flex>
            <MenuSidebar routes={getMenuItems()} />
          </Flex>
          <MenuManagerProfile logoutLink={"/login"} />
        </Flex>
      </Sider>
      <Layout>
        <Content
          style={{
            background: "#ffffff",
            overflow: "auto",
          }}
        >
          {children}
        </Content>
      </Layout>

      <ModalAddProspect
        handleClose={() => managerState.closeModal("addProspectModal")}
        open={managerState.addProspectModal}
      />
      <ModalAdminSettings />
      <SectionAskAdvice state={managerState} setState={setManagerState} />
      <ModalProposalGuide
        email={managerState.activeEmail}
        handleClose={() => managerState.closeModal("proposalGuideModal")}
        open={managerState.proposalGuideModal}
      />
    </Layout>
  );
};

export default LayoutPrimary;
