import { useContext, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import State from "../../../../../../context/manager/State";
import { Space, theme, Typography } from "antd";

import {
  getAllManagedUsersOfAdvisors,
  getLatestSavedProposalProps,
} from "../../../../../../utils/helpers/specialized";

const Top3ProductsPieChart = () => {
  const { token } = theme.useToken();
  const [adminState] = useContext(State);

  const [topStrategiesData, setTopStrategiesData] = useState([]);

  useEffect(() => {
    setTopStrategiesData(getTopStrategiesData());
  }, [adminState]);

  const getTopStrategiesData = () => {
    if (!adminState.investmentStrategies) {
      return [];
    }

    const selectedStrategies = adminState.investmentStrategies
      .map(({ name }) => {
        return {
          name,
          y: getAllManagedUsersOfAdvisors(
            adminState.managedUserManagers
          ).reduce(
            (acc, it) =>
              (getLatestSavedProposalProps(it?.valueMap?.productMap)
                ?.productName ?? it.selectedProduct) === name
                ? acc + 1
                : acc,
            0
          ),
        };
      })
      .sort((a, b) => b.y - a.y);

    const restStrategiesTimesSelected = selectedStrategies
      .slice(3)
      .reduce((acc, it) => acc + it.y, 0);

    return [
      ...selectedStrategies.slice(0, 3).sort((a, b) => b.y - a.y),
      { name: "Rest", y: restStrategiesTimesSelected },
    ];
  };

  const chartConfig = {
    chart: {
      type: "pie",
      height: 80,
      width: 100,
    },
    colors: [
      token.adminPieChartColorPrimary,
      token.adminPieChartColorSecondary,
      token.adminPieChartColorTertiary,
      token.adminPieChartColorRest,
    ],
    tooltip: {
      outside: true,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: 66,
        innerSize: "60%",
        dataLabels: {
          enabled: false,
        },
        center: ["50%", "50%"],
        borderWidth: 0,
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Selected",
        colorByPoint: true,
        data: topStrategiesData,
      },
    ],
  };

  return (
    <Space size={0} align="start">
      <Typography.Text style={{ color: token.colorLabel, lineHeight: "19px" }}>
        Top 3 products
        <br />
        Vs. the rest
      </Typography.Text>
      <HighchartsReact highcharts={Highcharts} options={chartConfig} />
    </Space>
  );
};

export default Top3ProductsPieChart;
