import styled from "styled-components";
import dayjs from "dayjs";
import { Flex, Rate } from "antd";

const Card = styled(Flex)`
  background: #ffffff;
  color: #03494e;
  padding: 26px 23px;
  border-radius: 12px;
  box-shadow: 0 0 16px #0000000d;

  .name {
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }

  .phone {
    min-width: 100px;
  }

  .email {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
`;

const LeadCard = ({ leadData }) => (
  <Card justify={"space-between"}>
    <Flex gap={3} vertical>
      <div className={"name"}>
        {leadData?.firstName} {leadData?.lastName}
      </div>
      <div>
        Received:{" "}
        {dayjs(leadData?.valueMap?.leadInitial?.createdAt).format("MM/DD/YYYY")}
      </div>
    </Flex>
    <Flex align={"end"} className={"phone"}>
      {leadData?.phoneNumber || " "}
    </Flex>
    <Flex align={"end"}>
      <u className={"email"}>{leadData?.email}</u>
    </Flex>
    <Rate disabled value={leadData?.valueMap?.leadInitial?.rate} />
  </Card>
);

export default LeadCard;
