import { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import styled from "styled-components";
import State from "../../../../context/user/State";
import { Button, Col, Flex, Image, Layout, Row, theme } from "antd";
import CardGoalPreview from "../../components/CardGoalPreview";
import Header from "../../components/Header";
import ModalCreateGoal from "../../components/ModalCreateGoal";
import ModalProductDetails from "../../../../organisms/ModalProductDetails";
import SectionAskAdvice from "../../../../organisms/SectionAskAdvice";
import SectionIntroducingAdvice from "./components/SectionIntroducingAdvice";
import SkeletonDashboard from "../../../../molecules/SkeletonDashboard";

import { isLockedByAdminView } from "../../../../utils/helpers/specialized";

const SectionAskAdvise = styled.div`
  background: #ffffff;
  border-radius: 12px;
  height: 779px;
  padding: 24px;
`;

const VideoContainer = styled.div`
  background: transparent linear-gradient(179deg, #ffffff 0%, #e7f7fd 100%);
  border-radius: 12px;
  padding: 24px;

  .title {
    line-height: 28px;
    font-size: 22px;
    color: #1b5568;
    margin: 0 0 8px;
  }

  .description {
    line-height: 22px;
    font-size: 16px;
    color: #1c5568;
    margin: 0 0 13px;
  }

  iframe {
    border-radius: 12px;
  }
`;

const PageDash = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [userState, setUserState] = useContext(State);
  const isLargeScreen = useMediaQuery({ maxWidth: 1180 });
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });

  useEffect(() => {
    if (!userState.getPreferenceValue("initialRiskTolerance")) {
      userState.getPreferenceValue("productMap") &&
        userState.setPreferenceValue(
          "initialRiskTolerance",
          userState.getPreferenceValue("productMap")[
            Object.keys(userState.getPreferenceValue("productMap"))[0]
          ][0].riskTolerance
        );
    }
  }, [userState]);

  const getLatestGoalPreview = () => {
    const productMap = userState.getPreferenceValue("productMap");

    if (!productMap) {
      return false;
    }

    const lastGoalData =
      productMap[Object.keys(productMap)[Object.keys(productMap).length - 1]];
    const lastGoalLatestData = lastGoalData[lastGoalData.length - 1];

    return (
      <CardGoalPreview
        goalData={{
          ...lastGoalLatestData,
          objective: {
            ...lastGoalLatestData,
            organization: "goals",
          },
          organization: "goals",
          productId: lastGoalLatestData?.productId,
        }}
        config={{
          isMobile: isMobile,
          hideChart: isMobile,
        }}
        productsList={userState.productsList}
      />
    );
  };

  const handleGetAdviceButtonClick = () => {
    userState.setKeyValue("openModalAskAdvice", true);
    userState.setKeyValue("sectionAskAdviceActiveTab", "advisor");
  };

  return (
    <SkeletonDashboard loading={userState.loading}>
      <Header hideNavbar={!isMobile}>
        <Image preview={false} src={token.logoSrc} width={216} />
      </Header>
      <Layout.Content>
        <Row gutter={[30, 30]} justify={"center"} wrap={isLargeScreen}>
          <Col flex={"1 1 378px"}>
            <Flex vertical gap={20}>
              {getLatestGoalPreview()}
              <Flex gap={15} justify={"space-between"}>
                <Button
                  onClick={() => navigate("/goals")}
                  shape={"round"}
                  size={isMobile && "large"}
                  style={{
                    width: 180,
                    fontSize: isMobile && 14,
                    background: "#FFFFFF",
                    color: "#24556F",
                  }}
                  type={"text"}
                >
                  View All Goals
                </Button>
                <Button
                  disabled={isLockedByAdminView({
                    managerAccess: userState.managerAccess,
                  })}
                  onClick={() => userState.openModal("createGoalModal")}
                  shape={"round"}
                  size={isMobile && "large"}
                  style={{
                    width: 180,
                    fontSize: isMobile && 14,
                    background: token.pageDashCreateNewGoalsButtonBackground,
                    color: token.pageDashCreateNewGoalsButtonColor,
                  }}
                >
                  + Create New Goal
                </Button>
              </Flex>
            </Flex>
          </Col>
          {!isMobile && (
            <Col flex={"1 1 380px"}>
              <SectionAskAdvise>
                <SectionAskAdvice
                  modalView={false}
                  setState={setUserState}
                  state={userState}
                />
              </SectionAskAdvise>
            </Col>
          )}
          <Col flex={"1 1 313px"}>
            <Flex gap={32} vertical>
              <SectionIntroducingAdvice
                handleGetAdviceButtonClick={handleGetAdviceButtonClick}
                isMobile={isMobile}
                managerAccess={userState.managerAccess}
              />
              <VideoContainer>
                <h4 className={"title"}>
                  How do you think about your finances?
                </h4>
                <p className={"description"}>
                  Connect with our community to learn more and figure out your
                  next best step.
                </p>
                <ReactPlayer
                  controls={true}
                  height={186}
                  id={"promoVideoContainer"}
                  style={{ marginBottom: 19 }}
                  url={token.advisorDashboardPageVideoLink}
                  width={"100%"}
                />
                <Button
                  block
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC_5ubjAqG9S_TZOM55uJRhQ",
                      "_blank"
                    )
                  }
                  shape={"round"}
                  size={"large"}
                  style={{
                    fontSize: 16,
                    background: token.pageDashAllEpisodesButtonBackground,
                    color: "#FFFFFF",
                  }}
                >
                  All Episodes
                </Button>
              </VideoContainer>
            </Flex>
          </Col>
        </Row>
      </Layout.Content>

      <ModalCreateGoal />
      <ModalProductDetails
        handleClose={() => userState.closeModal("productDetailsModal")}
        hideMcSettingsToggler={true}
        investmentAssumptions={userState.investmentAssumptions}
        open={userState.productDetailsModal}
        organization={"goals"}
        product={{
          ...userState.productsList.find(
            it => it._id === userState.productDetailsId
          ),
        }}
        productsList={userState.productsList}
      />
    </SkeletonDashboard>
  );
};

export default PageDash;
