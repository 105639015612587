import { useContext } from "react";
import State from "../../../../context/manager/State";
import { Row } from "antd";
import FileCard from "./components/FileCard";
import FolderCard from "./components/FolderCard";

const MaterialsTilesView = ({ searchText }) => {
  const [managerState] = useContext(State);

  return (
    <Row style={{ padding: 20, gap: "20px 70px" }} wrap>
      {searchText
        ? managerState.marketingMaterialFolders
            ?.map(folderData => folderData.marketingMaterials)
            ?.flat()
            ?.filter(fileData =>
              fileData?.name?.toLowerCase()?.includes(searchText.toLowerCase())
            )
            ?.map((fileData, index) => (
              <FileCard key={index} fileData={fileData} />
            ))
        : managerState.activeFolderData?.name
          ? managerState.marketingMaterialFolders
              ?.find(it => it.name === managerState.activeFolderData?.name)
              ?.marketingMaterials?.map((fileData, index) => (
                <FileCard key={index} fileData={fileData} />
              ))
          : managerState.marketingMaterialFolders?.map((folder, index) => (
              <FolderCard
                date={folder.createDateTime.$date}
                key={index}
                name={folder.name}
                onSelect={() =>
                  managerState.setKeyValue("activeFolderData", folder)
                }
              />
            ))}
    </Row>
  );
};

export default MaterialsTilesView;
