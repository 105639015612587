import { useContext, useEffect, useState } from "react";
import State from "../../context/manager/State";
import styled from "styled-components";
import { Button, Collapse, Flex, Row, Skeleton, theme, Typography } from "antd";
import DashboardCompare from "../../organisms/DashboardCompare";
import DefaultProductAssumptionsModal from "./components/DefaultProductAssumptionsModal";
import LevelExplore from "./components/LevelExplore";
import ModalProductDetails from "../../organisms/ModalProductDetails";
import ModalRelatedProducts from "../../organisms/ModalRelatedProducts";
import OneAscentExplore from "../../ecosystems/oneascent/components/OneAscentExplore";
import RiversharesExplore from "../../ecosystems/rivershares/components/RiversharesExplore";
import Top3ProductsBars from "./components/Top3ProductsBars";

import {
  addToFavoriteFinancialProducts,
  removeFromFavoriteFinancialProducts,
} from "../../utils/requests/manager";
import { getFinancialProducts } from "../../utils/requests/regularApp";
import {
  MAX_COMPARE_PRODUCTS,
  ONEASCENT_PORTFOLIO_X_RAY_LIST,
} from "../../utils/constants";
import {
  isOrganizationAstor,
  isOrganizationOneascent,
  isOrganizationRivershares,
  isPortalAdmin,
} from "../../utils/helpers/specialized";

import { DownOutlined, FilePdfOutlined, UpOutlined } from "@ant-design/icons";
import AstorExplore from "../../ecosystems/astor/components/AstorExplore";

const ActionTitle = styled(Typography.Text)`
  font-weight: 500;
  font-size: 18px;
  display: block;
  line-height: 1;
  color: #354a5f;
`;

const ActionSubtitle = styled(Typography.Text)`
  font-size: 14px;
  color: #8396a6;
  line-height: 1;
`;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 24px;

  &.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 24px 26px 20px;
  }

  &.ant-collapse-borderless
    > .ant-collapse-item:last-child
    .ant-collapse-header {
    background: #4897d126;
    padding: 16px 26px 8px;
    border-radius: 12px;
  }

  &.ant-collapse-borderless
    > .ant-collapse-item.ant-collapse-item-active:last-child
    .ant-collapse-header {
    border-radius: 12px 12px 0 0;
  }

  &.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-header-text {
    font-size: 18px;
    font-weight: 500;
    color: #1c4e7a;
  }

  &.ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
    margin-top: 2px;
    background: #4897d126;
    border-radius: 0 0 12px 12px;
  }

  & .ant-collapse-expand-icon {
    position: absolute;
    left: 150px;
  }
`;

const PageProducts = () => {
  const { token } = theme.useToken();
  const [adminState, setAdminState] = useContext(State);
  const [compareMode, setCompareMode] = useState(false);
  const [isCompareChartDirty, setIsCompareChartDirty] = useState(false);
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    if (adminState._id && !productsList.length && !adminState.loading) {
      adminState.showLoader();
      setFavoriteProducts(adminState.favoriteFinancialProducts);

      getFinancialProducts(
        adminState.organization?.name ?? adminState.orgName
      ).then(data => {
        setProductsList(data);
        adminState.hideLoader();
      });
    }
  }, [adminState]);

  const handleViewDetails = product => {
    setAdminState(lastState => ({
      ...lastState,
      productDetailsId: product._id,
    }));
    adminState.openModal("productDetailsModal");
  };

  const handleAddToFavorite = productId => {
    adminState.showLoader();

    addToFavoriteFinancialProducts(productId)
      .then(() =>
        adminState.setAdminData().then(data => {
          setFavoriteProducts(data.favoriteFinancialProducts);
          adminState.showSuccess("Product marked as Favorite");
        })
      )
      .catch(console.log);
  };

  const handleRemoveFromFavorite = productId => {
    adminState.showLoader();

    removeFromFavoriteFinancialProducts(productId)
      .then(() =>
        adminState.setAdminData().then(data => {
          setFavoriteProducts(data.favoriteFinancialProducts);
          adminState.showSuccess("Product removed from Favorite");
        })
      )
      .catch(console.log);
  };

  const productActions = product =>
    [
      {
        label: (
          <>
            <ActionTitle role="action-item">
              {favoriteProducts?.find(it => it === product._id) ? (
                <span style={{ color: "red" }}>Remove from Favorite</span>
              ) : (
                "Mark as Favorite"
              )}
            </ActionTitle>
            <ActionSubtitle role="action-item">
              Favorites are shown to clients
            </ActionSubtitle>
          </>
        ),
        key: "action-item-0",
        onSelect: product => {
          favoriteProducts?.find(it => it === product._id)
            ? handleRemoveFromFavorite(product._id)
            : handleAddToFavorite(product._id);
        },
        status:
          isOrganizationOneascent(adminState.organization?.name) &&
          adminState.subscriptionType === "ManagedUserManager"
            ? "visible"
            : "hidden",
      },
      {
        label: (
          <>
            <ActionTitle role="action-item">View Details</ActionTitle>
            <ActionSubtitle role="action-item">
              Read more about this product
            </ActionSubtitle>
          </>
        ),
        key: "action-item-1",
        onSelect: product => {
          handleViewDetails(product);
        },
        status: "visible",
      },
      {
        label: (
          <>
            <ActionTitle role="action-item">
              {adminState?.compareProducts?.includes(product?._id) ? (
                <span style={{ color: "red" }}>Remove from Compare</span>
              ) : (
                "Compare Strategies"
              )}
            </ActionTitle>
            <ActionSubtitle role="action-item">
              Choose up to 3 products
            </ActionSubtitle>
          </>
        ),
        key: "action-item-2",
        onSelect: product => {
          if (
            adminState.compareProducts.length >= MAX_COMPARE_PRODUCTS &&
            !adminState.compareProducts.includes(product._id)
          ) {
            adminState.showWarning(
              `No more than ${MAX_COMPARE_PRODUCTS} products can be compared to at the same time`
            );

            return;
          }

          const productIncluded = adminState.compareProducts?.find(
            productId => productId === product._id
          );

          if (!productIncluded) {
            setAdminState(lastState => ({
              ...lastState,
              compareProducts: [...lastState.compareProducts, product._id],
            }));
          } else {
            setAdminState(lastState => ({
              ...lastState,
              compareProducts: lastState.compareProducts.filter(
                productId => productId !== product._id
              ),
            }));
          }
          adminState.closeModal("productDetailsModal");
        },
        status: "visible",
      },
      {
        label: (
          <Flex gap={12}>
            <Flex vertical>
              <ActionTitle role="action-item">Proposal Template</ActionTitle>
              <ActionSubtitle role="action-item">
                What the client sees
              </ActionSubtitle>
            </Flex>
            <FilePdfOutlined style={{ fontSize: 24 }} />
          </Flex>
        ),
        key: "action-item-3",
        onSelect: product => window.open(product.proposalPdfSample, "_blank"),
        status: isPortalAdmin() && "visible",
      },
    ].filter(it => it.status === "visible");

  const handleCardClick = cardId => {
    setAdminState(lastState => ({
      ...lastState,
      productDetailsId: cardId,
    }));
    adminState.openModal("productDetailsModal");
  };

  const compareListCardAction = [
    {
      key: "addToCompare",
      buttonCopy: "Add to Compare",
      buttonActiveCopy: "Selected to Compare",
      onSelect: product => {
        setAdminState(lastState => ({
          ...lastState,
          compareProducts: lastState.compareProducts.includes(product._id)
            ? lastState.compareProducts.filter(id => id !== product._id)
            : [...lastState.compareProducts, product._id],
        }));

        setIsCompareChartDirty(true);
        adminState.closeModal("relatedProductsModal");
      },
    },
  ];

  return (
    <Skeleton active loading={adminState.loading}>
      {compareMode ? (
        <>
          <Row justify={"end"}>
            <Button
              shape={"round"}
              style={{
                background: token.tenantColorPrimary,
                borderColor: "transparent",
                color: "#FFFFFF",
                marginBottom: 16,
                width: 178,
              }}
              onClick={() => setCompareMode(false)}
            >
              Exit Compare Mode
            </Button>
          </Row>
          <DashboardCompare
            closeCompareMode={() => setCompareMode(false)}
            handleCardClick={handleCardClick}
            isCompareChartDirty={isCompareChartDirty}
            openProductsListModal={() =>
              adminState.openModal("relatedProductsModal")
            }
            productsList={productsList}
            proposalData={adminState.investmentAssumptions}
            setIsCompareChartDirty={setIsCompareChartDirty}
            setState={setAdminState}
            showError={adminState.showError}
            showLegend={
              !isOrganizationRivershares(adminState.organization?.name)
            }
            showSecondaryButton={false}
            state={adminState}
          />
        </>
      ) : (
        <>
          {!isOrganizationAstor(adminState.organization?.name) && (
            <StyledCollapse
              bordered={false}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <UpOutlined style={{ color: token.colorNavy }} />
                ) : (
                  <DownOutlined style={{ color: token.colorNavy }} />
                )
              }
              style={{ padding: 0, background: "none" }}
              items={[
                {
                  key: "productStats",
                  label: "Product Stats",
                  children: <Top3ProductsBars productsList={productsList} />,
                },
              ]}
            />
          )}
          {isOrganizationOneascent(adminState.organization?.name) ? (
            <OneAscentExplore
              comparedProducts={adminState.compareProducts}
              handleCardClick={handleCardClick}
              openModal={adminState.openModal}
              productActions={productActions}
              productsList={productsList}
              setCompareMode={setCompareMode}
            />
          ) : isOrganizationRivershares(adminState.organization?.name) ? (
            <RiversharesExplore
              comparedProducts={adminState.compareProducts}
              handleCardClick={handleCardClick}
              openModal={adminState.openModal}
              productActions={productActions}
              productsList={productsList}
              setCompareMode={setCompareMode}
            />
          ) : isOrganizationAstor(adminState.organization?.name) ? (
            <AstorExplore
              comparedProducts={adminState.compareProducts}
              handleCardClick={handleCardClick}
              productActions={productActions}
              productsList={productsList}
              setCompareMode={setCompareMode}
            />
          ) : (
            <LevelExplore
              comparedProducts={adminState.compareProducts}
              handleCardClick={handleCardClick}
              openModal={adminState.openModal}
              productActions={productActions}
              productsList={productsList}
              setCompareMode={setCompareMode}
            />
          )}
        </>
      )}

      <ModalProductDetails
        open={adminState.productDetailsModal}
        handleClose={() => adminState.closeModal("productDetailsModal")}
        product={productsList.find(
          it => it._id === adminState.productDetailsId
        )}
        productsList={productsList}
        investmentAssumptions={adminState.investmentAssumptions}
        organization={
          adminState.organization?.name?.toLowerCase() === "level"
            ? "level2"
            : adminState.organization?.name?.toLowerCase()
        }
        hideMcSettingsToggler={isOrganizationOneascent(
          adminState.organization?.name
        )}
        xRayList={
          isOrganizationOneascent(adminState.organization?.name) &&
          ONEASCENT_PORTFOLIO_X_RAY_LIST
        }
      />
      <ModalRelatedProducts
        cardActions={compareListCardAction}
        compareProducts={adminState.compareProducts}
        favoriteFinancialProducts={adminState.favoriteFinancialProducts}
        handleCardClick={handleCardClick}
        handleClose={() => adminState.closeModal("relatedProductsModal")}
        hideFavoriteProducts={isOrganizationOneascent(
          adminState.organizations?.name
        )}
        open={adminState.relatedProductsModal}
        productsList={productsList}
        selectedProduct={adminState.selectedProduct}
      />
      <DefaultProductAssumptionsModal
        handleClose={() =>
          adminState.closeModal("defaultProductAssumptionsModal")
        }
        investmentAssumptions={adminState.investmentAssumptions}
        open={adminState.defaultProductAssumptionsModal}
      />
    </Skeleton>
  );
};

export default PageProducts;
