import { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import State from "../../context/user/State";
import { Flex, Layout, Row, theme } from "antd";
import SectionAskAdvice from "../../organisms/SectionAskAdvice";
import MenuGoalsUser from "../../ecosystems/goals/components/MenuGoalsUser";
import MenuSidebar from "../../molecules/MenuSidebar";
import ModalContactUs from "../../organisms/ModalContactUs";
import ModalGoalsUserSettings from "../../organisms/ModalGoalsUserSettings";
import Header from "./components/Header";

import {
  getOrganizationManagerData,
  getUserManagerData,
} from "../../utils/requests/manager";

import "./index.css";

import mobileOverlay from "../images/dashboard_bg_mobile.svg";

const { Content, Sider, Footer } = Layout;

const StyledLayout = styled(Layout)`
  flex-direction: column;
  height: 100vh;

  & .ant-layout-header {
    height: fit-content;
    background: #ffffff;
    padding: 33px 46px 32px 40px;
  }

  & .ant-layout-content {
    padding: 0 85px 40px 40px;
  }

  &.ant-layout .ant-layout-sider {
    background: ${props => props.sidebarbackground};
    background-position: left;
    padding: 56px 0;
  }

  &.ant-layout.ant-layout-has-sider > .ant-layout,
  &.ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: auto;
    background-color: #fff;
    overflow-x: auto;
  }

  @media screen and (min-width: 993px) {
    & .sidebar-button.active::after {
      content: "";
      position: absolute;
      right: -23px;
      width: 6px;
      height: 100%;
      border-radius: 3px 0 0 3px;
      background: #ffffff;
    }
  }

  @media screen and (max-width: 992px) {
    &.ant-layout.ant-layout-has-sider {
      flex-direction: column;
      height: 100vh;
      background-image: url(${mobileOverlay});
    }

    &.ant-layout.ant-layout-has-sider > .ant-layout {
      width: auto;
      background: transparent;
      overflow: auto;
    }

    & .ant-layout-footer {
      background: transparent linear-gradient(121deg, #15284b 0%, #2a98d5 100%);
    }
  }
`;

const LayoutProposal = ({ routes, children }) => {
  const { token } = theme.useToken();
  const [userState, setUserState] = useContext(State);
  const isTablet = useMediaQuery({ maxWidth: token.screenLGMin });

  useEffect(() => {
    getUserManagerData()
      .then(data => {
        userState.setKeyValue("userManagerData", data);
      })
      .catch(() => {
        getOrganizationManagerData()
          .then(data => {
            userState.setKeyValue("userManagerData", data);
          })
          .catch(console.log);
      });
  }, []);

  return (
    <StyledLayout hasSider sidebarbackground={token.sidebarBackground}>
      {!isTablet && (
        <Sider width={80}>
          <MenuSidebar itemsLayoutVertical routes={routes} />
          <MenuGoalsUser />
        </Sider>
      )}
      <Layout>
        <Header
          openModal={userState.openModal}
          organization={userState.organization?.name ?? userState.orgName}
        />
        <Row
          justify={"center"}
          style={{
            height: "100%",
          }}
        >
          <Content
            className="tab-background"
            style={{
              maxWidth: 1550,
              overflow: "auto",
            }}
          >
            {children}
          </Content>
        </Row>
      </Layout>
      {isTablet && (
        <Footer>
          <Flex justify={"space-between"}>
            <MenuSidebar itemsLayoutVertical routes={routes} />
            <MenuGoalsUser />
          </Flex>
        </Footer>
      )}

      <SectionAskAdvice setState={setUserState} state={userState} />
      <ModalContactUs />
      <ModalGoalsUserSettings />
    </StyledLayout>
  );
};

export default LayoutProposal;
