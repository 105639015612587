import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../../context/user/State";
import dayjs from "dayjs";
import styled from "styled-components";
import { Button, Flex } from "antd";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";

const NewsCard = styled(Flex)`
  color: #17191c;
  border-bottom: 1px solid #e1ecf1;
  padding-bottom: 11px;

  .title {
    line-height: 28px;
    font-size: 22px;
    margin: 0 0 6px;
  }

  .date {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 2px;
  }

  .description {
    line-height: 18px;
    font-size: 14px;
    margin: 0 0 6px;
  }

  .source {
    font-size: 11px;
    color: #546170;
    margin: 0;
  }

  .button {
    width: fit-content;
    font-size: 16px;
    color: #21409a;
    padding: unset;
  }
`;

const PageNewsList = () => {
  const navigate = useNavigate();
  const [userState] = useContext(State);

  const handleClose = () => navigate("/mmi/dashboard");

  return (
    <Flex gap={16} vertical>
      <Button
        block
        onClick={handleClose}
        shape={"round"}
        style={{
          fontSize: 16,
          background: "#001141",
          color: "#4EABE9",
        }}
      >
        <Flex align={"center"} gap={6} justify={"center"}>
          <BlueArrow style={{ rotate: "90deg" }} />
          Back to Dashboard
        </Flex>
      </Button>
      <Flex gap={20} vertical>
        {userState.newsList?.map(it => (
          <NewsCard key={it.id} vertical>
            <h5 className={"title"}>{it.title}</h5>
            <p className={"date"}>
              {dayjs(it.published).format("MMM DD, YYYY h:mma")}
            </p>
            <p className={"description"}>{it.summary}</p>
            <p className={"source"}>Source: {it.sourceName}</p>
            <Button
              className={"button"}
              onClick={() => window.open(it.url, "_target")}
              type={"text"}
            >
              Read More
            </Button>
          </NewsCard>
        ))}
      </Flex>
    </Flex>
  );
};

export default PageNewsList;
