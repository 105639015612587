import { ReactComponent as Step1Image } from "../images/right_side_step_1.svg";
import { ReactComponent as Step2Image } from "../images/right_side_step_2.svg";
import { ReactComponent as Step3Image } from "../images/right_side_step_3.svg";

export const defaultProposalData = {
  birthYear: 1972,
  contributions: 12000,
  investmentAmount: 100000,
  investmentDuration: 20,
  investmentObjective: "",
  organization: "level2",
  riskTolerance: "",
  yearToStartWithdrawals: 1,
};

export const stepsCopy = [
  {
    title: "Investment Objective",
    description: `Do you want to maximize the growth of your savings or create an income stream that will allow you to
      retire early.`,
    rightSideImage: <Step1Image />,
    subTitle: "Having a goal helps keep your money on track.",
    subDescription: `Whether you are accumulating a nest egg leading up to retirement, making sure your money can
      last through retirement or some other investment goal, we have the right tools to help you succeed.`,
  },
  {
    title: "What is your risk tolerance?",
    description:
      "Every investment involves some risk. We can look at portfolios that matches your level of risk.",
    rightSideImage: <Step2Image />,
    subTitle: "Every goal is powered by actual investments.",
    subDescription: `Each investment carries different risks and rewards. If you don’t have much experience yet, we
      have lots of ways to help you learn more. Don’t worry, you can always explore and adjust this later.`,
  },
  {
    title: "Amount & Time",
    description: `Let’s set some basic parameters for this journey. Please select amount you want to invest, and for
      how long.`,
    rightSideImage: <Step3Image />,
    subTitle: "Wherever you’re starting from, we can help.",
    subDescription: `With just a few key pieces of information, we can start visualizing your trajectory. Seeing where
      your money is taking you helps you make informed decisions and stay on course to reach your goals.`,
  },
];
