import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import State from "./State";
import UI from "../../providers/UI";
import { notification } from "antd";
import Overlay from "../../atoms/Overlay";

import defaultAdminStateData from "./defaultState.json";
import {
  editLead,
  getOrganizationManagerData,
  getUserManagerData,
} from "../../utils/requests/manager";
import {
  getManagerPendoAccountId,
  getManagerPendoVisitorMetadata,
  initializePendo,
  isPortalAdmin,
  setTenantSpecificHeadAttributes,
} from "../../utils/helpers/specialized";
import {
  getFinancialProducts,
  getSubscriptionType,
} from "../../utils/requests/regularApp";

const StateProvider = ({ children, organization }) => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [adminState, setAdminState] = useState(defaultAdminStateData);

  useEffect(() => {
    setTenantSpecificHeadAttributes(organization);
    setKeyValue("session", uuidv4());
  }, []);

  useEffect(() => {
    setKeyValue("showOverlay", true);

    setAdminData().then(data => {
      if (!data?._id) {
        navigate("/login");
      } else {
        initializePendo(getManagerPendoVisitorMetadata(data), {
          id: getManagerPendoAccountId(data),
        });

        getSubscriptionType(data._id).then(subscriptionTypeResponse => {
          setAdminState(lastState => ({
            ...lastState,
            ...subscriptionTypeResponse,
          }));
          getFinancialProducts(
            adminState.organization?.name ?? adminState.orgName
          ).then(data => {
            setKeyValue("productsList", data);
            setKeyValue("showOverlay", false);
          });
        });

        data.managedUsers?.map(it => {
          if (
            it?.valueMap?.leadInitial?.isNewLead &&
            it?.valueMap?.leadInitial?.isNotifyAdvisor
          ) {
            api.info({
              message: "A new lead has been added to your Leads page",
              placement: "topRight",
              description: `Lead email: ${it.email}`,
            });
            editLead({
              ...it,
              leadInitial: {
                ...it.valueMap.leadInitial,
                isNewLead: false,
              },
            });
          }
        });
      }
    });
  }, []);

  const openModal = modalKey => {
    setAdminState(state => ({
      ...state,
      [modalKey]: true,
    }));
  };

  const closeModal = modalKey => {
    setAdminState(state => ({
      ...state,
      [modalKey]: false,
    }));
  };

  const setAdminData = () => {
    showLoader();

    return (isPortalAdmin() ? getOrganizationManagerData : getUserManagerData)()
      .then(data => {
        setAdminState(oldState => ({
          ...oldState,
          ...data,
          managedUserManagers: data.managedUserManagers ?? [],
          loading: false,
        }));

        return data;
      })
      .catch(({ message }) => {
        showWarning(message);
        setTimeout(() => navigate("/login"), 3000);
      });
  };

  const setKeyValue = (key, value) =>
    setAdminState(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const showWarning = errorMessage => {
    api.warning({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showSuccess = successMessage => {
    api.success({
      message: successMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showError = errorMessage => {
    api.error({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });
  };

  const showLoader = () => {
    setAdminState(state => ({
      ...state,
      loading: true,
    }));
  };

  const hideLoader = () => {
    setAdminState(state => ({
      ...state,
      loading: false,
    }));
  };

  const getManagedUsers = () =>
    adminState.managedUsers
      ? adminState.managedUsers
          .filter(it => !it.isDeleted)
          .filter(
            elem =>
              elem.userType === "Registered" ||
              elem.userType === "ConvertedProspect"
          )
      : [];

  const getProspectUsers = () =>
    adminState.managedUsers
      ? adminState.managedUsers
          .filter(it => !it.isDeleted)
          .filter(
            elem =>
              elem.userType === "Prospect" ||
              (elem.entryPoint === "addedByUserManager" &&
                elem.userType === "Assigned")
          )
      : [];

  const mergedAdminState = {
    ...adminState,
    closeModal,
    getManagedUsers,
    getProspectUsers,
    hideLoader,
    openModal,
    setAdminData,
    setKeyValue,
    showWarning,
    showError,
    showLoader,
    showSuccess,
  };

  return (
    <State.Provider value={[mergedAdminState, setAdminState]}>
      <UI state={adminState}>
        <Overlay loading={adminState.showOverlay}>{children}</Overlay>
        {contextHolder}
      </UI>
    </State.Provider>
  );
};

export default StateProvider;
