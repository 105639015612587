import { useContext, useState } from "react";
import styled from "styled-components";
import State from "../../context/manager/State";
import { Modal } from "antd";
import ContentAstor from "./components/ContentAstor";
import ContentDefault from "./components/ContentDefault";
import ContentIPQ from "./components/ContentIPQ";
import ContentRivershares from "./components/ContentRivershares";
import ContentWealt from "./components/ContentWealt";
import ModalDisclosures from "./components/ModalDisclosures";

import { DEFAULT_BIRTH_YEAR } from "../../utils/constants";

import { openProposal } from "../../utils/requests/manager";
import { updateProductObjective } from "../../utils/requests/regularApp";
import {
  isOrganizationAstor,
  isOrganizationRivershares,
} from "../../utils/helpers/specialized";

const StyledModal = styled(Modal)`
  max-width: 100vw;
  top: 0;
  padding: 0;

  & .ant-modal-content {
    height: 100vh;
    padding: 0;
  }

  & .ant-modal-body {
    height: 100%;
  }
`;

const ModalProposalGuide = ({ email, open, handleClose }) => {
  const [managerState] = useContext(State);
  const [showDisclosuresModal, setShowDisclosuresModal] = useState(false);
  const organization = managerState.organization?.name ?? managerState.orgName;

  const handleCreateProposal = proposalData => {
    managerState.showLoader();
    handleClose();

    updateProductObjective({
      email,
      ...proposalData,
      birthYear:
        managerState.managedUsers?.find(it => it.email === email)?.birthYear ||
        proposalData.birthYear ||
        DEFAULT_BIRTH_YEAR,
    })
      .then(() => {
        managerState.setAdminData().then(() => {
          managerState.showSuccess("New proposal created!");
          managerState.hideLoader();
          openProposal({
            userEmail: email,
            organization: proposalData.organization,
          });
        });
      })
      .catch(error => {
        managerState.showError(error.response?.data);
        managerState.hideLoader();
      });
  };

  const GuideBody = () => {
    if (managerState.onboardingQuestionnaireType === "wealthManagement") {
      return (
        <ContentWealt
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
          setShowDisclosuresModal={setShowDisclosuresModal}
        />
      );
    } else if (managerState.onboardingQuestionnaireType === "IPQ") {
      return (
        <ContentIPQ
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
          setShowDisclosuresModal={setShowDisclosuresModal}
        />
      );
    } else if (isOrganizationRivershares(organization)) {
      return (
        <ContentRivershares
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      );
    } else if (isOrganizationAstor(organization)) {
      return (
        <ContentAstor
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      );
    } else {
      return (
        <ContentDefault
          handleClose={handleClose}
          handleCreateProposal={handleCreateProposal}
          open={open}
        />
      );
    }
  };

  return (
    <StyledModal open={open} width="100vw" footer={false} closable={false}>
      <GuideBody />
      <ModalDisclosures
        handleClose={() => setShowDisclosuresModal(false)}
        open={showDisclosuresModal}
      />
    </StyledModal>
  );
};

export default ModalProposalGuide;
