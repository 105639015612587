import ot_logo_dark from "../images/ot_logo_dark.svg";
import ot_logo_light from "../images/ot_logo_light.svg";

export default {
  advisorDashboardPageActiveTabColor: "transparent",
  advisorDashboardPageVideoLink: "https://vimeo.com/947148074?share=copy",
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#FB703A", "#296469"],
  colorBlack: "#2A2828",
  colorError: "#EF0000",
  colorGlacier: "#6DC1C7",
  colorGreen: "#47951d",
  colorGrey1: "#8396A6",
  colorGrey2: "#BEC8CE",
  colorGrey3: "#E7EBEE",
  colorLabel: "#5A607F",
  colorNavy: "#354A5F",
  colorPrimary: "#1B5568",
  colorPrimaryText: "#354A5F",
  colorRadioButton: "#296469",
  colorSuccess: "#47951D",
  colorTextBase: "#354A5F",
  colorText: "#516474",
  colorTextHeading: "#354A5F",
  colorUiText: "#516474",
  comparisonProductsColors: ["#4997D1", "#004E88", "#D8D800"],
  goalsOnboardingBackground: "#e3ebec",
  goalsOnboardingButtonIconBackground: "#FFFFFF",
  goalsOnboardingButtonIconColor: "#1b5568",
  goalsOnboardingStepDividerLineColor: "#dbe248",
  goalsOnboardingButtonContinueBackground: "#03494E",
  goalsOnboardingButtonContinueColor: "#DBE247",
  goalsOnboardingButtonContinueBorderRadius: 8,
  goalsOnboardingSectionBasicInfoListMarkerBackground: "#DBE247",
  goalsOnboardingSectionRiskToleranceButtonIconColor: "#FFFFFF",
  goalsOnboardingSectionRiskToleranceButtonIconBackground: "#D6CEF4",
  goalsOnboardingSectionRiskToleranceButtonColor: "#FFFFFF",
  goalsOnboardingSectionRiskToleranceButtonConservativeBackground: "#749B9E",
  goalsOnboardingSectionRiskToleranceButtonModerateBackground: "#4F8083",
  goalsOnboardingSectionRiskToleranceButtonAggressiveBackground: "#296469",
  fontFamily: "Inter, sans-serif",
  fontFamilySecondary: "Filson-pro, sans-serif",
  incomeChartWithTypesContributionsColor: "#1B5568",
  incomeChartWithTypesNavbarButtonBackground: "#2b41a9",
  incomeChartWithTypesNavbarButtonColor: "#FFFFFF",
  incomeChartWithTypesNavbarContainerBackground: "#efefef",
  layoutBackground: "#E3EBEC",
  logoSrc: ot_logo_dark,
  modalFinancialProductDetailsHeaderBackground: "#E6F4EF",
  pageLoginLogo: ot_logo_light,
  pennySendMessageButtonBackground: "#296469",
  productCardBackground: "#e6f4ef",
  quarterlyGoalAumColor: "#6DC1C7",
  quarterlyGoalClientColor: "#66AEDC",
  quarterlyGoalProposalColor: "#354A5F",
  tenantColorPrimary: "#428A16",
  topProductsColors: ["#296469", "#6DC1C7", "#354A5F"],
  topProductsPieChartPallet: ["#6CB7E0", "#BEC8CE", "#364A5F", "#72C0C7"],
  sidebarAvatarBackground: "#6DC1C7",
  sidebarAvatarColor: "#FFFFFF",
  sidebarAvatarLabelColor: "#FFFFFF70",
  sidebarIconColor: "#BEC8CE",
  sidebarIconColorActive: "#296469",
  sidebarMenuItemHoverBackground: "#052D2C",
};
