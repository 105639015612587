import logo from "../images/logo.png";
import want_to_talk from "../images/want_to_talk.png";

export default {
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimaryButtonText: "#FFFFFF",
  comparisonProductsColors: ["#7908A3", "#0076FF", "#001A45"],
  goalsOnboardingBackground: "#EBEBEB",
  goalsOnboardingButtonIconBackground: "#0E51A7",
  goalsOnboardingButtonIconColor: "#FFFFFF",
  goalsOnboardingStepDividerLineColor: "#0E51A7",
  goalsOnboardingButtonContinueBackground: "#0551A7",
  goalsOnboardingButtonContinueColor: "#ffffff",
  goalsOnboardingButtonContinueBorderRadius: 30,
  goalsOnboardingSectionBasicInfoListMarkerBackground: "#516474",
  goalsOnboardingSectionRiskToleranceButtonIconColor: "#FFFFFF",
  goalsOnboardingSectionRiskToleranceButtonIconBackground: "#437CBD",
  goalsOnboardingSectionRiskToleranceButtonColor: "#FFFFFF",
  goalsOnboardingSectionRiskToleranceButtonConservativeBackground: "#0551A7",
  goalsOnboardingSectionRiskToleranceButtonModerateBackground: "#0551A7",
  goalsOnboardingSectionRiskToleranceButtonAggressiveBackground: "#0551A7",
  goalsOnboardingFormRegistrationButtonSubmitBackground: "#0551A7",
  goalsOnboardingFormRegistrationButtonSubmitBorderRadius: 30,
  incomeChartWithTypesContributionsColor: "#4997D1",
  incomeChartWithTypesNavbarButtonBackground: "#FFFFFF",
  incomeChartWithTypesNavbarButtonColor: "#2A2828",
  incomeChartWithTypesNavbarContainerBackground: "#D9F3F7",
  tenantColorPrimary: "#0551A7",
  headerNavbarButtonBackgroundColor: "#0E51A7",
  logoSrc: logo,
  pageDashAllEpisodesButtonBackground: "#296469",
  pageDashCreateNewGoalsButtonBackground: "#0551A7",
  pageDashCreateNewGoalsButtonColor: "#FFFFFF",
  pageDashGetAdviceButtonBackground: "#0551A7",
  pageDashGetAdviceButtonColor: "#FFFFFF",
  pageDashWantToTalkImageLink: want_to_talk,
  pageDashWantToTalkLimitedContent: "",
  pageDashWantToTalkTitleContent: "Want to talk to a<br/>Bluepoint Advisor?",
  pageGoalUpdateProgressButtonBackground: "#0551A7",
  pageGoalUpdateProgressButtonColor: "#FFFFFF",
  pageLoginButtonSubmitColor: "#0551A7",
  pageLoginLogo: logo,
  pennySendMessageButtonBackground: "#0551A7",
  sidebarAvatarBackground: "#FFFFFF",
  sidebarAvatarColor: "#1056AA",
  sidebarBackground: "#0D1238",
  sidebarIconColor: "#9EA0AF",
  sidebarIconColorActive: "#FFFFFF",
};
