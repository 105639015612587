import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import State from "../../../../context/user/State";
import Overlay from "../../../../atoms/Overlay";
import PageProposalGoals from "../../pages/PageProposalGoals";
import PageProposalGoal from "../../pages/PageProposalGoal";

const Proposal = () => {
  const location = useLocation();
  const [dashboardState, setDashboardState] = useContext(State);
  const [viewMode, setViewMode] = useState("goals");

  useEffect(() => {
    setViewMode("goals");
  }, [location]);

  const setActiveGoal = activeGoal =>
    setDashboardState(lastState => ({
      ...lastState,
      activeGoal,
    }));

  const handleAddGoal = () => {
    setActiveGoal(undefined);
    setViewMode("goal");
  };

  const handleCloseGoal = () => {
    setActiveGoal(undefined);
    setViewMode("goals");
  };

  const handleCopyGoal = activeGoal => {
    setActiveGoal(activeGoal);
    setViewMode("goal");
    setTimeout(() => setActiveGoal(undefined), 1000);
  };

  const handleViewGoal = goalId => {
    setActiveGoal(goalId);
    setViewMode("goal");
  };

  return (
    <Overlay loading={dashboardState.loading || viewMode === "pdf"}>
      {dashboardState.getPreferenceValue("productMap") &&
      viewMode === "goals" ? (
        <PageProposalGoals
          handleAddGoal={handleAddGoal}
          handleCopyGoal={handleCopyGoal}
          handleViewGoal={handleViewGoal}
          productsList={dashboardState.productsList}
          setViewMode={setViewMode}
        />
      ) : (
        <PageProposalGoal
          activeGoal={dashboardState.activeGoal}
          handleCloseGoal={handleCloseGoal}
          productsList={dashboardState.productsList}
          setActiveGoal={setActiveGoal}
          setViewMode={setViewMode}
          viewMode={viewMode}
        />
      )}
    </Overlay>
  );
};

export default Proposal;
