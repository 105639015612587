import { useContext, useState } from "react";
import State from "../../context/user/State";
import { Button, Input, Modal, Typography } from "antd";

import { sendQuestion } from "../../utils/requests/regularApp";

import Close from "../../icons/Close";

const ModalContactUs = () => {
  const [dashboardState] = useContext(State);
  const [question, setQuestion] = useState("");

  const handleSend = () => {
    sendQuestion(question)
      .then(() => {
        setQuestion("");
        dashboardState.showSuccess(
          "Your information has been sent. Thank you!"
        );
        dashboardState.closeModal("contactUsModal");
      })
      .catch(console.log);
  };

  return (
    <Modal
      title="24-hour Product Support"
      open={dashboardState.contactUsModal}
      onCancel={() => dashboardState.closeModal("contactUsModal")}
      closeIcon={<Close />}
      footer={
        <Button
          type="primary"
          shape="round"
          onClick={handleSend}
          disabled={!question}
        >
          Send
        </Button>
      }
      forceRender
      centered
    >
      <div style={{ margin: "40px 0" }}>
        <Input.TextArea
          autoSize={{
            minRows: 10,
            maxRows: 10,
          }}
          placeholder="Type your question here..."
          onChange={e => setQuestion(e.target.value)}
        />
        <Typography.Paragraph>
          Ask questions, report issues - 24 hour response time
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default ModalContactUs;
