import { useContext } from "react";
import State from "../../../../context/admin/State";
import { Button, Flex } from "antd";
import ModalPrimary from "../ModalPrimary";

import { deleteCompany } from "../../../../requests/global";

const ModalDeleteCompany = ({ open }) => {
  const [adminState] = useContext(State);

  const handleClose = () => {
    adminState.closeModal("deleteCompanyModal");
  };

  const handleDeleteUser = () => {
    adminState.activeCompanyId &&
      deleteCompany(adminState.activeCompanyId).then(() =>
        adminState.reFetch.companies().then(() => {
          handleClose();
          adminState.showSuccess("Company deleted");
          adminState.setKeyValue("activeCompanyId", null);
        })
      );
  };

  return (
    <ModalPrimary
      footer={
        <Flex justify={"space-between"}>
          <Button
            onClick={handleClose}
            shape={"round"}
            size={"large"}
            type={"text"}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            shape={"round"}
            size={"large"}
            style={{ width: 206, background: "#001141", color: "#FFFFFF" }}
          >
            Delete
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={"Delete Company"}
      width={492}
    >
      <span>Are you sure you want to delete this company?</span>
    </ModalPrimary>
  );
};

export default ModalDeleteCompany;
