import styled from "styled-components";
import dayjs from "dayjs";
import { Button, Space, theme, Typography } from "antd";

import { openProposal } from "../../../../../../utils/requests/manager";
import {
  getLastSavedProposalProps,
  isGrowthTypeRiskTolerance,
} from "../../../../../../utils/helpers/specialized";
import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helpers/general";

const { Text } = Typography;

const Container = styled(Space)`
  gap: 36px;
  width: 100%;
  max-width: 716px;
  background: #ffffff;
  padding: 23px 45px 15px 29px;
  border-radius: 8px;
  box-shadow: 0 0 16px #0000000d;
`;

const StyledButton = styled(Button)`
  color: #4e96d1;
  border-color: #4e96d1;
  background: #ffffff;
`;

const ProposalCard = ({ organization, userData }) => {
  const { token } = theme.useToken();
  const latestGoalData = getLastSavedProposalProps(
    userData?.valueMap?.productMap
  );

  const getDetails = () => {
    if (isGrowthTypeRiskTolerance(latestGoalData.investmentObjective)) {
      return `${getCurrencyValueWithRoundSign(
        latestGoalData.calculated?.endingInvestmentBalance
      )} over ${
        latestGoalData.lengthOfInvestment ?? latestGoalData.investmentDuration
      } yrs`;
    } else {
      return getCurrencyValueWithRoundSign(
        latestGoalData.calculated?.withdrawalAmount
      );
    }
  };

  const handleViewProposal = () => {
    openProposal({
      userEmail: userData?.email,
      organization,
    });
  };

  return (
    <Container justify="space-between" size={26} wrap align={"start"}>
      <Space direction="vertical" style={{ width: 176 }}>
        <Text
          style={{
            fontSize: 18,
            fontWeight: 500,
            color: token.colorLabel,
          }}
        >
          {userData?.firstName} {userData?.lastName}
        </Text>
        {latestGoalData?.timestamp && (
          <Text>
            Created: {dayjs(latestGoalData.timestamp).format("MMM D, YYYY")}
          </Text>
        )}
      </Space>
      <Space
        direction="vertical"
        style={{
          width: 115,
        }}
      >
        <Text
          style={{
            color: token.colorLabel,
          }}
          strong
        >
          Details
        </Text>
        <Text>{getDetails()}</Text>
      </Space>
      <Space direction="vertical" style={{ width: 155 }}>
        <Text
          style={{
            color: token.colorLabel,
          }}
          strong
        >
          Product
        </Text>
        <Text>{latestGoalData?.productName ?? "Nothing selected"}</Text>
      </Space>
      <StyledButton shape="round" size="small" onClick={handleViewProposal}>
        View Proposal
      </StyledButton>
    </Container>
  );
};

export default ProposalCard;
