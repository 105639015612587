import styled from "styled-components";
import { Button, Flex } from "antd";

import { ReactComponent as IconAskAdvisor } from "../../images/icon_ask_advisor.svg";
import { ReactComponent as IconAskAi } from "../../images/icon_ask_ai.svg";

const Container = styled(Flex)`
  background: #e6f4ef;
  border-radius: 88px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2) inset;
  padding: 8px 9px;

  .ant-btn-default {
    background: transparent;
    border-color: transparent;
    color: #02494e;

    .ant-btn-icon {
      color: #03494e;
    }

    &.active,
    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: #215054;
      border-color: #215054;
      color: #fff;

      .ant-btn-icon {
        color: #d7e05f;
      }
    }
  }
`;

const ContainerHelpButtons = ({
  handleButtonClick,
  isAiButtonActive,
  isAdvisorButtonActive,
}) => {
  return (
    <Flex justify={"center"}>
      <Container align={"center"} justify={"center"}>
        <Button
          className={isAiButtonActive && "active"}
          icon={<IconAskAi />}
          onClick={() => handleButtonClick("ai")}
          shape={"round"}
          style={{ width: 145 }}
        >
          Ask our AI
        </Button>
        <Button
          className={isAdvisorButtonActive && "active"}
          icon={<IconAskAdvisor />}
          onClick={() => handleButtonClick("advisor")}
          shape={"round"}
          style={{ width: 173 }}
        >
          Ask an Advisor
        </Button>
      </Container>
    </Flex>
  );
};

export default ContainerHelpButtons;
