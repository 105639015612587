import { useState } from "react";
import styled from "styled-components";
import { Button, Flex, Modal, Upload } from "antd";

import { importCSVManagedUserTemplate } from "../../../../utils/requests/manager";

import Close from "../../../../icons/Close";
import { ReactComponent as UploadIcon } from "./images/upload.svg";
import { ReactComponent as DownloadIcon } from "./images/download.svg";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-content {
      padding: 29px 39px 36px 32px;
    }

    .ant-modal-header {
      margin-bottom: 65px;

      .ant-modal-title {
        font-weight: 800;
      }
    }

    .ant-modal-body {
      padding: 0 30px 0 50px;

      .step-marker {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 31px;
        width: 31px;
        height: 31px;
        font-size: 16px;
        font-weight: 800;
        background: #296469;
        color: #ffffff;
        border-radius: 50%;
      }

      .ant-upload-wrapper {
        width: 100%;

        .ant-upload-drag .ant-upload-btn {
          background: #e6f4ef;
          color: #0551a7;
          padding: 28px 12px;
          text-align: center;
          border-radius: 12px;

          .title {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
          }

          .description {
            font-size: 16px;
            margin: 0;
          }
        }
      }
    }

    .ant-modal-footer {
      margin-top: 48px;
    }
  }

  @media screen and (max-width: 550px) {
    &.ant-modal {
      .ant-modal-content {
        padding: 12px;
      }

      .ant-modal-body {
        padding: 0;
      }
    }
  }
`;

const ModalImportManagedUser = ({
  instanceLabel,
  loading,
  onCancel,
  onFinish,
  open,
}) => {
  const [file, setFile] = useState();

  const handleFileChange = event => {
    if (event.fileList?.length) {
      setFile(event.fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={onCancel} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            disabled={!file || loading}
            onClick={() => onFinish(file)}
            shape={"round"}
            type={"primary"}
          >
            Done
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={`Import ${instanceLabel}s`}
      width={561}
    >
      <Flex gap={37} vertical>
        <Flex align={"center"} gap={25}>
          <div className="step-marker">1</div>
          <Button
            block
            icon={<DownloadIcon />}
            onClick={() => importCSVManagedUserTemplate({ instanceLabel })}
            shape={"round"}
            size={"large"}
            style={{
              background: "#4F8083",
              color: "#FFFFFF",
              textTransform: "capitalize",
            }}
          >
            Download {instanceLabel}s Template
          </Button>
        </Flex>
        <Flex gap={25}>
          <div className="step-marker">2</div>
          <Flex vertical>
            <h5 style={{ fontSize: 16, color: "#0D1238", margin: "0 0 10px" }}>
              Populate the template with your data
            </h5>
            <p style={{ color: "#707070", margin: 0 }}>
              If you don’t have data for certain columns, just leave them blank.
              Do not delete or rename columns.
            </p>
          </Flex>
        </Flex>
        <Flex gap={25}>
          <div className="step-marker">3</div>
          <Upload.Dragger
            name={"file"}
            maxCount={1}
            beforeUpload={() => false}
            onChange={handleFileChange}
          >
            <UploadIcon />
            <h5 className={"title"}>
              Upload your {instanceLabel}s (.xls, .xlsx, .csv)
            </h5>
            <p className={"description"}>
              Drag here or <u>browse</u>
            </p>
          </Upload.Dragger>
        </Flex>
      </Flex>
    </StyledModal>
  );
};

export default ModalImportManagedUser;
