import styled from "styled-components";
import { Row } from "antd";

import { ReactComponent as PennyImage } from "../../images/penny_logo_1.svg";

const Container = styled(Row)`
  position: fixed;
  top: 0;
  right: 18px;
  height: 86px;
  width: 147px;
  background: #ffffff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 3px 12px #0000002f;
  cursor: pointer;
  z-index: 999;
`;

const PennyChatToggler = ({ handleClick }) => (
  <Container align={"middle"} justify={"center"} onClick={handleClick}>
    <PennyImage />
  </Container>
);

export default PennyChatToggler;
