import styled from "styled-components";
import { Typography } from "antd";

const StyledSeeDisclosures = styled(Typography.Text)`
  position: fixed;
  bottom: 20px;
  left: 40px;
  font-size: 16px;
  font-weight: 300;
  color: #224843;

  @media screen and (max-width: 720px) {
    position: initial;
  }
`;

const SeeDisclosures = ({ openModal }) => (
  <StyledSeeDisclosures>
    * See disclosures for{" "}
    <Typography.Link
      style={{ textDecoration: "underline" }}
      onClick={openModal}
    >
      definition
    </Typography.Link>
  </StyledSeeDisclosures>
);

export default SeeDisclosures;
