import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import dayjs from "dayjs";
import State from "../../../../context/manager/State";
import { Button, Flex, Input, Modal, Select, Tag, theme, Upload } from "antd";

import {
  CONVERSATION_COMPLEXITY_LIST,
  CONVERSATION_RELEVANCE_LIST,
  DEFAULT_TAGS_LIST,
} from "../../constants";
import {
  deleteConversation,
  getAllConversations,
  getConversationById,
  updateConversation,
} from "../../../../utils/requests/penny";

import Close from "../../../../icons/Close";
import ThumbUp from "../../images/ThumbUp";
import ThumbDown from "../../images/ThumbDown";
import UploadIcon from "../../../../icons/Upload";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 32px 31px 24px 29px;
  }

  &.ant-modal .ant-modal-header {
    margin-bottom: 19px;
  }

  .header-title {
    font-size: 22px;
    font-weight: 500;
    color: #23292c;
  }

  .header-description {
    font-weight: 400;
    color: #4b4747;
  }

  .section-prompt {
    background: #e6f4ef;
    border-radius: 12px;
    padding: 15px 24px 24px;
    margin-bottom: 31px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #1e1919;
    border-radius: 16px;
    border-color: transparent;
  }

  .ant-select .ant-select-arrow {
    color: #354a5f;
  }

  .status-marker {
    line-height: 19px;
    padding: 3px 8px;
    border: 1px solid transparent;
    border-radius: 6px;
    cursor: pointer;

    &.active {
      background: #749b9e;
      color: #ffffff;
      border-color: #03494e;
    }

    &.inactive {
      background: #bfbfbf;
      color: #ffffff;
    }
  }
`;

const StyledTag = styled(Tag.CheckableTag)`
  font-size: 14px;
  margin: 0;

  &:not(.ant-tag-checkable-checked) {
    background: #ffffff;
    color: #1e1919;
    border-color: #585858;
  }
`;

const AddNewTagInput = styled(Input)`
  height: 23px;
  width: 126px;
  border-color: #585858;
`;

const ResponseContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background: #ebf1f3;
  padding: 23px;
  border-radius: 12px;

  &.active {
    border: 1px solid #707070;
  }

  & textarea.ant-input {
    height: 195px;
    line-height: 19px;
    color: #1e1919;
    padding: 22px 22px 21px 18px;
    border: 1px solid #a5a3a3;
    border-radius: 6px;
    resize: none;
  }
`;

const getMarker = active =>
  active ? (
    <div className={"status-marker active"}>Active</div>
  ) : (
    <div className={"status-marker inactive"}>Inactive</div>
  );

const ModalConversationDetails = ({ open, onCancel }) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMD });
  const [adminState] = useContext(State);
  const [updatedConversationData, setUpdatedConversationData] = useState();
  const [newTagName, setNewTagName] = useState("");
  const [conversationDataDirty, setConversationDataDirty] = useState(false);

  useEffect(() => {
    if (open && adminState.activeConversationId) {
      getConversationById(adminState.activeConversationId).then(data => {
        setUpdatedConversationData(data);
        setConversationDataDirty(false);
      });
    }
  }, [open]);

  const updateConversationData = (key, value) => {
    setUpdatedConversationData(lastState => ({
      ...lastState,
      [key]: value,
    }));

    setConversationDataDirty(true);
  };

  const updateConversationObjectData = (object, key, value) => {
    setUpdatedConversationData(lastState => ({
      ...lastState,
      [object]: {
        ...lastState[object],
        [key]: value,
      },
    }));

    setConversationDataDirty(true);
  };

  const handleAddTag = () => {
    if (newTagName) {
      if (updatedConversationData?.tags?.includes(newTagName)) {
        adminState.showError("Tag already exist.");
      } else {
        setUpdatedConversationData(lastState => ({
          ...lastState,
          tags: Array.from(new Set([...(lastState.tags ?? []), newTagName])),
        }));
        setNewTagName("");
        setConversationDataDirty(true);
      }
    }
  };

  const handleDelete = () => {
    adminState.showLoader();

    deleteConversation(updatedConversationData._id).then(() => {
      getAllConversations()
        .then(data => {
          adminState.setKeyValue("conversations", data);
          adminState.showSuccess("Conversation deleted");
          setConversationDataDirty(false);
          onCancel();
        })
        .catch(console.log)
        .finally(() => adminState.hideLoader());
    });
  };

  const handleSave = () => {
    let payload = updatedConversationData;

    if (
      updatedConversationData?.replacedResponse?.humanResponse &&
      !updatedConversationData?.replacedResponse?.replacedByEmail
    ) {
      payload.replacedResponse.replacedByEmail = adminState._id;
      payload.replacedResponse.timestamp = +dayjs().format("X");
    }

    if (
      payload?.responseActive === "human" &&
      !updatedConversationData?.replacedResponse?.humanResponse
    ) {
      adminState.showError(
        "You could not Replace Penny’s response with an empty message"
      );
      return;
    }

    adminState.showLoader();

    updateConversation({
      id: updatedConversationData._id,
      payload,
    })
      .then(() => {
        getAllConversations()
          .then(data => {
            adminState.setKeyValue("conversations", data);
            adminState.showSuccess("Conversation data updated");
            setConversationDataDirty(false);
            onCancel();
          })
          .catch(console.log)
          .finally(() => adminState.hideLoader());
      })
      .catch(console.log);
  };

  const handleTagChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...(updatedConversationData?.tags ?? []), tag]
      : updatedConversationData?.tags.filter(it => it !== tag);
    updateConversationData("tags", nextSelectedTags);
  };

  const handleReplacedResponseChange = e => {
    updateConversationObjectData(
      "replacedResponse",
      "humanResponse",
      e.target.value
    );
  };

  const mapRates = (dataList, isLike) =>
    dataList?.map((it, index) => (
      <Flex gap={28} key={index}>
        <Flex gap={13}>
          <ThumbUp active={isLike} />
          <ThumbDown active={!isLike} />
        </Flex>
        <p style={{ margin: 0, color: token.colorCleverBlack }}>
          <i>
            Rated by {it?.email},{" "}
            {dayjs(it?.timestamp).format("MM/DD/YYYY h:mma")}
          </i>
        </p>
      </Flex>
    ));

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      destroyOnClose
      footer={
        <Flex justify={"space-between"}>
          <Flex gap={8}>
            <Button
              disabled={!conversationDataDirty}
              onClick={handleSave}
              shape={"round"}
              style={{ width: 75 }}
              type={"primary"}
            >
              Save
            </Button>
            <Button onClick={onCancel} shape={"round"} type={"text"}>
              Cancel
            </Button>
          </Flex>
          <Button onClick={handleDelete} shape={"round"} type={"text"}>
            Delete
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={
        <Flex gap={44} wrap={"wrap"}>
          <span className={"header-title"}>Conversation Details</span>
          <Flex gap={10} className={"header-description"}>
            <span>
              User:{" "}
              <span style={{ fontWeight: 500 }}>
                {updatedConversationData?.userName}
              </span>
            </span>
            <span>
              Email:{" "}
              <span style={{ fontWeight: 500 }}>
                <u>{updatedConversationData?.userEmail}</u>
              </span>
            </span>
            <span>
              Initiated:{" "}
              <span style={{ fontWeight: 500 }}>
                {dayjs(updatedConversationData?.createdAt).format(
                  "MM/DD/YYYY h:mma"
                )}
              </span>
            </span>
          </Flex>
        </Flex>
      }
      width={1167}
      zIndex={1001}
    >
      <div className={"section-prompt"}>
        <Flex vertical>
          <p
            style={{
              fontSize: 16,
              margin: "0 0 4px",
              color: token.colorUiText,
            }}
          >
            <b>Prompt</b>
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: updatedConversationData?.userQuery,
            }}
            style={{ margin: "0 0 14px", color: token.colorCleverBlack }}
          />
          <Flex gap={8} style={{ marginBottom: 24 }} wrap={"wrap"}>
            {Array.from(
              new Set([
                ...DEFAULT_TAGS_LIST,
                ...(updatedConversationData?.tags ?? []),
              ])
            ).map((tag, index) => (
              <StyledTag
                checked={updatedConversationData?.tags?.includes(tag)}
                key={index}
                onChange={checked => handleTagChange(tag, checked)}
              >
                {tag}
              </StyledTag>
            ))}
            <AddNewTagInput
              placeholder={"+ Type a new tag…"}
              onChange={e => setNewTagName(e.target.value?.trim())}
              value={newTagName}
              onBlur={handleAddTag}
            />
          </Flex>
          <Flex align={"end"} gap={11} wrap={"wrap"}>
            <Flex gap={2} vertical>
              <span>Complexity</span>
              <Select
                onChange={value => updateConversationData("complexity", value)}
                options={CONVERSATION_COMPLEXITY_LIST.map(it => ({
                  value: it,
                  label: it,
                }))}
                style={{ width: 134 }}
                value={updatedConversationData?.complexity}
              />
            </Flex>
            <Flex gap={2} vertical>
              <span>Relevance</span>
              <Select
                onChange={value => updateConversationData("relevance", value)}
                options={CONVERSATION_RELEVANCE_LIST.map(it => ({
                  value: it,
                  label: it,
                }))}
                style={{ width: 134 }}
                value={updatedConversationData?.relevance}
              />
            </Flex>
            <span style={{ color: token.colorCleverBlack, marginLeft: 10 }}>
              <i>
                Last updated by{" "}
                {updatedConversationData?.promptUpdatedBy?.email},{" "}
                {dayjs(
                  updatedConversationData?.promptUpdatedBy?.timestamp
                ).format("MM/DD/YYYY h:mma")}
              </i>
            </span>
          </Flex>
        </Flex>
      </div>
      <Flex align={"start"} gap={37} wrap={isSmallScreen ? "wrap" : "nowrap"}>
        <ResponseContainer>
          <Flex justify={"space-between"} style={{ marginBottom: 12 }}>
            <p
              style={{
                fontSize: 16,
                color: token.colorUiText,
                margin: 0,
              }}
            >
              <b>Penny’s response</b> (provided by{" "}
              <span style={{ textTransform: "capitalize" }}>
                {updatedConversationData?.source})
              </span>
            </p>
            <div
              onClick={() => {
                updateConversationData("responseActive", "kendra");
              }}
            >
              {getMarker(updatedConversationData?.responseActive === "kendra")}
            </div>
          </Flex>
          <p
            style={{
              lineHeight: "19px",
              color: token.colorCleverBlack,
              margin: "0 0 19px",
            }}
          >
            {updatedConversationData?.pennyResponse}
          </p>
          <p style={{ margin: "0 0 7px", color: token.colorCleverBlack }}>
            Rated Answer
          </p>
          <Flex gap={8} vertical>
            {mapRates(updatedConversationData?.likes, true)}
            {mapRates(updatedConversationData?.dislikes)}
          </Flex>
        </ResponseContainer>
        <ResponseContainer className={"active"}>
          <Flex justify={"space-between"} style={{ marginBottom: 14 }}>
            <p style={{ fontSize: 16, color: token.colorUiText, margin: 0 }}>
              <b>Replace Penny’s response with…</b>
            </p>
            <div
              onClick={() => {
                updateConversationData("responseActive", "human");
              }}
            >
              {getMarker(updatedConversationData?.responseActive === "human")}
            </div>
          </Flex>
          <Input.TextArea
            onChange={handleReplacedResponseChange}
            style={{ marginBottom: 18 }}
            value={updatedConversationData?.replacedResponse?.humanResponse}
          />
          <Flex align={"center"} gap={16} style={{ marginBottom: 16 }}>
            <Upload
              beforeUpload={() => false}
              maxCount={1}
              name={"file"}
              onChange={file => updateConversationData("file", file)}
              onRemove={() => updateConversationData("file", null)}
            >
              <Button
                icon={<UploadIcon />}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 30,
                }}
              >
                Upload image...
              </Button>
            </Upload>
            <span>or</span>
            <Input
              onChange={e =>
                updateConversationObjectData(
                  "replacedResponse",
                  "fileUrl",
                  e.target.value
                )
              }
              placeholder={"Image url…"}
              size={"large"}
              style={{ fontSize: 14 }}
              value={updatedConversationData?.replacedResponse?.fileUrl}
            />
          </Flex>
          {updatedConversationData?.replacedResponse && (
            <p style={{ color: token.colorCleverBlack, margin: 0 }}>
              <i>
                Updated by{" "}
                {updatedConversationData.replacedResponse?.replacedByEmail},{" "}
                {dayjs(
                  updatedConversationData.replacedResponse?.timestamp * 1000
                ).format("MM/DD/YYYY h:mma")}
              </i>
            </p>
          )}
        </ResponseContainer>
      </Flex>
    </StyledModal>
  );
};

export default ModalConversationDetails;
