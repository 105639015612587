import { useContext, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import State from "../../context/manager/State";
import { Button, Flex, Input, Row, Space, theme, Typography } from "antd";
import FeaturedVideoModalToggler from "./components/FeaturedVideoModalToggler";
import IconContainer from "../../atoms/IconContainer";
import MaterialsTableView from "./components/MaterialsTableView";
import MaterialsTilesView from "./components/MaterialsTilesView";
import ModalAddFolder from "./components/ModalAddFolder";
import ModalDeleteFile from "./components/ModalDeleteFile";
import ModalDeleteFolder from "./components/ModalDeleteFolder";
import ModalFeaturedContentOptions from "./components/ModalFeaturedContentOptions";
import ModalFeaturedVideo from "./components/ModalFeaturedVideo";
import ModalRenameFile from "./components/ModalRenameFile";
import ModalRenameFolder from "./components/ModalRenameFolder";
import ModalUploadFile from "./components/ModalUploadFile";

import {
  isOrganizationAstor,
  isPortalAdmin,
} from "../../utils/helpers/specialized";
import {
  addFolder,
  addSubFolder,
  deleteFile,
  deleteFolder,
  renameFile,
  renameFolder,
  updateFeatureContent,
  updateOrgVideoLink,
  uploadFile,
} from "../../utils/requests/manager";

import {
  FolderOpenOutlined,
  LeftOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TableView from "../../icons/TableView";
import TilesView from "../../icons/TilesView";

const Container = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #ffffff;

    &::before {
      display: none;
    }
  }
`;

const PageMarketing = () => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);
  const [viewType, setViewType] = useState("table");
  const [searchText, setSearchText] = useState("");

  const getMaterialsLastUpdateDate = () =>
    managerState.marketingMaterialFolders
      ?.map(it => [
        it.createDateTime.$date,
        it.marketingMaterials?.map(file => file.createDateTime.$date) ?? [],
      ])
      ?.flat(2)
      ?.sort((a, b) => dayjs(a) - dayjs(b))
      ?.at(-1);

  const handleAddFolder = ({ newFolderName }) => {
    managerState.showLoader();
    managerState.closeModal("openModalAddFolder");

    if (managerState.parentFolderId) {
      addSubFolder({
        parentFolderId: managerState.parentFolderId,
        folderName: newFolderName,
      })
        .then(response => {
          managerState.showSuccess(response.data);

          managerState.setAdminData().then(() => {
            managerState.setKeyValue("parentFolderId", null);
          });
        })
        .catch(error => {
          console.log(error);
          managerState.hideLoader();
        });
    } else {
      addFolder(newFolderName)
        .then(response => {
          managerState.showSuccess(response.data);
          managerState.setAdminData();
        })
        .catch(error => {
          console.log(error);
          managerState.hideLoader();
        });
    }
  };

  const handleBack = () => {
    managerState.setKeyValue("activeFolderData", null);
    setSearchText("");
  };

  const handleDeleteFile = () => {
    managerState.showLoader();
    managerState.closeModal("openModalDeleteFile");

    deleteFile({
      folderId: managerState.activeFileData.folderData?.id,
      id: managerState.activeFileData.id,
    })
      .then(response => {
        managerState.showSuccess(response.data);
        managerState.setAdminData();
      })
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleDeleteFolder = () => {
    managerState.showLoader();
    managerState.closeModal("openModalDeleteFolder");

    deleteFolder({ folderId: managerState.activeFolderData.id })
      .then(response => {
        managerState.showSuccess(response.data);
        managerState.setAdminData();
      })
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleRenameFile = ({ newFileName }) => {
    if (newFileName.includes("±")) {
      managerState.showWarning(
        "“±” signs cannot be used when saving a marketing document"
      );
      return;
    }

    managerState.showLoader();
    managerState.closeModal("openModalRenameFile");

    renameFile({
      folderId: managerState.activeFileData.folderData.id,
      id: managerState.activeFileData.id,
      newFileName,
    })
      .then(({ data }) => {
        managerState.showSuccess(data);
        managerState.setAdminData();
      })
      .catch(console.log);
  };

  const handleRenameFolder = ({ newFolderName }) => {
    managerState.showLoader();
    managerState.closeModal("openModalRenameFolder");

    renameFolder({
      newFolderName,
      oldFolderId: managerState.activeFolderData.id,
    })
      .then(({ data }) => {
        managerState.showSuccess(data);
        managerState.setAdminData();
      })
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleSearch = value => {
    setViewType("files");
    setSearchText(value);
  };

  const handleUpdateFeaturedContent = requestBody => {
    if (!requestBody.headline) {
      managerState.showWarning("Please, provide the Headline");
      return;
    } else if (!requestBody.description) {
      managerState.showWarning("Please, provide the Short Description");
      return;
    }

    managerState.showLoader();

    updateFeatureContent(requestBody)
      .then(() => {
        managerState.closeModal("openModalFeaturedContentOptions");
        managerState.showSuccess("Featured Content Options updated");
        managerState.setAdminData();
      })
      .catch(data => console.log(data));
  };

  const handleUploadFile = fileData => {
    if (
      managerState.marketingMaterialFolders
        ?.find(it => it.name === fileData.folderName)
        ?.marketingMaterials?.find(it => it.name === fileData.fileName)
    ) {
      managerState.showWarning(
        "You cannot upload a file into a folder where the file already exists."
      );
      return;
    } else if (fileData.fileName.includes("±")) {
      managerState.showWarning(
        "“±” signs cannot be used when saving a marketing document"
      );
      return;
    }

    managerState.showLoader();
    managerState.closeModal("openModalUploadFile");

    const formData = new FormData();
    formData.append("fileName", fileData.fileName);
    formData.append("folderId", fileData.folderId);
    formData.append("isVisibleToAdvisor", true);
    formData.append("file", fileData.file.fileList[0].originFileObj);

    uploadFile(formData)
      .then(({ data }) => {
        managerState.showSuccess(data);
        managerState.setAdminData();
      })
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleVideoLinkUpdate = ({ link }) => {
    if (link.includes("https://www.") || link.includes("http://www.")) {
      managerState.showLoader();

      updateOrgVideoLink(link)
        .then(() => {
          managerState.setAdminData().then(() => {
            managerState.showSuccess("Featured Video link updated.");
            managerState.closeModal("openModalFeaturedVideo");
          });
        })
        .catch(console.log);
    } else {
      managerState.showError("Please, add valid link on the video.");
    }
  };

  return (
    <Container>
      <Row align="bottom" justify="space-between" style={{ marginBottom: 40 }}>
        <Space direction="vertical">
          <Typography.Title
            style={{ fontSize: 22, color: "#131523", margin: 0 }}
          >
            Marketing Materials
          </Typography.Title>
          <Typography.Text>
            {isPortalAdmin()
              ? "Use this area to upload materials for your advisor to use with their clients."
              : `Information and Materials on ${managerState.organization?.name} Products.`}
          </Typography.Text>
        </Space>
        {isPortalAdmin() && <FeaturedVideoModalToggler />}
      </Row>
      <Flex
        justify={"space-between"}
        style={{
          paddingBottom: 8,
          borderBottom: `1px solid ${token.colorGrey3}`,
          marginBottom: 24,
        }}
      >
        <Flex gap={13}>
          <Input
            value={searchText}
            placeholder={"Search..."}
            style={{
              height: 40,
              width: 228,
              borderRadius: 25,
            }}
            allowClear
            onChange={e => handleSearch(e.target.value)}
          />
          {((managerState.activeFolderData?.name && viewType === "tiles") ||
            viewType === "files" ||
            searchText) && (
            <>
              <Button
                type="text"
                size="small"
                shape="round"
                icon={<LeftOutlined />}
                onClick={handleBack}
              >
                Back
              </Button>
              <FolderOpenOutlined style={{ fontSize: 26 }} />
            </>
          )}
        </Flex>
        <Space size={30}>
          {isPortalAdmin() && (
            <Space size={10}>
              <Button
                type="primary"
                size="small"
                shape="round"
                icon={<PlusOutlined />}
                onClick={() => managerState.openModal("openModalAddFolder")}
                disabled={managerState.loading}
                style={{
                  background: token.colorTertiaryButton,
                  color: token.colorTertiaryButtonText,
                }}
              >
                New Folder
              </Button>
              <Button
                type="secondary"
                size="small"
                shape="round"
                icon={<UploadOutlined />}
                onClick={() => managerState.openModal("openModalUploadFile")}
                disabled={managerState.loading}
                style={{
                  width: 118,
                  background: token.colorSecondaryButton,
                  color: token.colorSecondaryButtonText,
                }}
              >
                Upload
              </Button>
            </Space>
          )}
          {!isOrganizationAstor(managerState.organization?.name) && (
            <Space size={8}>
              <IconContainer
                size={32}
                onClick={() => setViewType("table")}
                pointer
              >
                <TableView
                  color={viewType === "table" ? "#354A5F" : "#8396A6"}
                />
              </IconContainer>
              <IconContainer
                size={32}
                onClick={() => setViewType("tiles")}
                pointer
              >
                <TilesView
                  color={viewType !== "table" ? "#354A5F" : "#8396A6"}
                />
              </IconContainer>
              <Typography.Text
                style={{ fontSize: 12, color: token.colorGrey1 }}
              >
                Last Updated:{" "}
                {dayjs(getMaterialsLastUpdateDate()).format("M/DD/YYYY")}
              </Typography.Text>
            </Space>
          )}
        </Space>
      </Flex>
      {viewType === "table" ? (
        <MaterialsTableView />
      ) : (
        <MaterialsTilesView searchText={searchText} />
      )}

      <ModalAddFolder
        onCancel={() => managerState.closeModal("openModalAddFolder")}
        open={managerState.openModalAddFolder}
        onSuccess={handleAddFolder}
      />
      <ModalDeleteFile
        activeFileData={managerState.activeFileData}
        onCancel={() => managerState.closeModal("openModalDeleteFile")}
        open={managerState.openModalDeleteFile}
        onSuccess={handleDeleteFile}
      />
      <ModalDeleteFolder
        activeFolderName={managerState.activeFolderData?.name}
        onSuccess={handleDeleteFolder}
        onCancel={() => managerState.closeModal("openModalDeleteFolder")}
        open={managerState.openModalDeleteFolder}
      />
      <ModalFeaturedContentOptions
        activeFileData={
          managerState.marketingMaterialFolders
            ? {
                ...managerState.activeFileData,
                ...managerState.marketingMaterialFolders
                  .map(it => it.marketingMaterials)
                  .flat()
                  .find(it => it?.id === managerState.activeFileData?.id),
              }
            : managerState.activeFileData
        }
        foldersList={managerState.marketingMaterialFolders}
        handleUpdateFeaturedContent={handleUpdateFeaturedContent}
        loading={managerState.loading}
        onClose={() =>
          managerState.closeModal("openModalFeaturedContentOptions")
        }
        open={managerState.openModalFeaturedContentOptions}
        organization={managerState.organization?.name}
      />
      <ModalFeaturedVideo
        link={managerState.video}
        loading={managerState.loading}
        onCancel={() => managerState.closeModal("openModalFeaturedVideo")}
        onSuccess={handleVideoLinkUpdate}
        open={managerState.openModalFeaturedVideo}
      />
      <ModalRenameFile
        activeFileData={managerState.activeFileData}
        onCancel={() => managerState.closeModal("openModalRenameFile")}
        onSuccess={handleRenameFile}
        open={managerState.openModalRenameFile}
      />
      <ModalRenameFolder
        activeFolderName={managerState.activeFolderData?.name}
        onCancel={() => managerState.closeModal("openModalRenameFolder")}
        onSuccess={handleRenameFolder}
        open={managerState.openModalRenameFolder}
      />
      <ModalUploadFile
        marketingMaterialFolders={managerState.marketingMaterialFolders}
        onCancel={() => managerState.closeModal("openModalUploadFile")}
        onSuccess={handleUploadFile}
        open={managerState.openModalUploadFile}
      />
    </Container>
  );
};

export default PageMarketing;
