import { useContext } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
import State from "../../../../context/manager/State";
import { Button, Flex, theme } from "antd";
import SectionAdvisorDashboardAnalytics from "../../../../organisms/SectionAdvisorDashboardAnalytics";

import { openProposalWithConfig } from "../../../../utils/helpers/specialized";

import AskPennyLogo from "../../images/AskPennyLogo";
import LevelFeatureIcon from "./images/LevelFeatureIcon";

const StyleProvider = styled.div`
  background: #f5f5f5;
  height: 100vh;
  min-width: 1140px;
  overflow: auto;
  padding: 42px 78px 27px 35px;

  .header {
    margin-bottom: 25px;
  }

  .news-updates-container {
    height: 100%;
    max-height: 839px;
    min-width: 396px;
    width: 396px;
    background: ${({ token }) => token.pageDashboardRightSideSectionBackground};
    padding: 24px;
    border-radius: 12px;

    iframe {
      border-radius: 12px;
    }

    .title {
      font-size: 21px;
      font-weight: 400;
      color: ${({ token }) => token.pageDashboardRightSideSectionTitleColor};
      margin: 0 0 14px;
    }

    .unlock-features-section {
      background: #dbe247;
      padding: 14px;
      border-radius: 12px;
      margin-top: 28px;

      .title {
        color: #1e1919;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }

      .description {
        line-height: 19px;
        color: #1e1919;
        margin: 0;
      }
    }

    .news-section {
      background: #ffffff;
      border-radius: 12px;
      overflow: auto;
      margin-top: 25px;

      .header {
        font-size: 18px;
        font-weight: 500;
        color: #a5a3a3;
        padding: 16px 23px 11px;
        border-bottom: 1px solid #e6f4ef;
        margin: 0;
      }

      .body {
        padding: 21px 26px;
        overflow: auto;
      }

      .description {
        color: #a5a3a3;
      }
    }
  }
`;

const PageDashboard = () => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);

  return (
    <StyleProvider token={token}>
      <Flex className={"header"} align={"center"} justify={"space-between"}>
        <div style={{ fontSize: 21, fontWeight: 300, color: "#5A607F" }}>
          Dashboard
        </div>
        <Flex align={"center"} gap={23}>
          {managerState.access?.level && (
            <Button
              onClick={() => openProposalWithConfig({})}
              shape={"round"}
              size={"small"}
              style={{
                width: 140,
                fontSize: 13,
                color: "#1B5568",
                borderColor: "#1B5568",
              }}
            >
              Demo Mode
            </Button>
          )}
          <Button
            disabled={!managerState.access?.penny}
            shape={"round"}
            style={{ background: "#03494E" }}
            onClick={() => managerState.openModal("openModalAskAdvice")}
          >
            <Flex align={"center"} justify={"center"}>
              <AskPennyLogo />
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <Flex
        gap={31}
        justify={"center"}
        style={{ height: "100%", minWidth: "fit-content" }}
      >
        <Flex gap={20} vertical>
          <SectionAdvisorDashboardAnalytics />
        </Flex>
        <Flex className={"news-updates-container"} vertical>
          <h3 className={"title"}>
            {token.pageDashboardRightSideSectionTitleCopy}
          </h3>
          <div>
            <ReactPlayer
              controls={true}
              height={172}
              url={token.advisorDashboardPageVideoLink}
              width={"100%"}
            />
          </div>
          <Flex className={"unlock-features-section"} gap={10}>
            <div>
              <LevelFeatureIcon />
            </div>
            <Flex gap={4} vertical>
              <h5 className={"title"}>Want to unlock more Level features?</h5>
              <p className={"description"}>
                From planning to portfolios, we can help you with the tools to
                grow your firm.{" "}
                <a href={"https://clevercx.com/"} target={"_blank"}>
                  Learn more
                </a>{" "}
                or{" "}
                <a
                  href={
                    "https://meetings.hubspot.com/andrew-weiler?uuid=ebc09a2c-9b3d-46b2-b585-a677950d22ce"
                  }
                  target={"_blank"}
                >
                  schedule time
                </a>{" "}
                with one of our team members.
              </p>
            </Flex>
          </Flex>
          <div className={"news-section"}>
            <h4 className={"header"}>INDUSTRY NEWS</h4>
            <div className={"body"}>
              <p className={"description"}>Coming soon</p>
            </div>
          </div>
        </Flex>
      </Flex>
    </StyleProvider>
  );
};

export default PageDashboard;
