import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import State from "../../../../context/manager/State";
import { Button, ConfigProvider, Flex, Row, theme } from "antd";
import SectionSeekToElevate from "../SectionSeekToElevate";
import SectionSeekToEliminate from "../SectionSeekToEliminate";
import SectionAboutMe from "./components/SectionAboutMe";
import SectionTimeHorizon from "./components/SectionTimeHorizon";
import SectionRiskSelection from "./components/SectionRiskSelection";
import ReturnsRiskVolatility from "./components/SectionReturnsRiskVolatility";
import SectionBitAboutYou from "./components/SectionBitAboutYou";

import { defaultProposalData } from "./constants";
import {
  convertCurrentAgeRange,
  convertYearToStartWithdrawalsRange,
} from "../../../../utils/helpers/specialized";

import { ReactComponent as OneAscentSlogan } from "../../../../ecosystems/oneascent/images/slogan.svg";
import { ReactComponent as OneAscentLogo } from "../../../../ecosystems/oneascent/images/logo.svg";

const Container = styled.div`
  height: 100%;
  padding: 80px 40px;
  border-bottom: unset;
  background: linear-gradient(360deg, #4897d100 0%, #4897d120 100%) 0 0;
  background-size: contain;
  box-sizing: border-box;
  overflow: auto;

  .content-body {
    max-width: 1100px;
    margin: 0 auto;
  }

  .title {
    line-height: 40px;
    font-size: 32px;
    font-weight: bold;
    color: #15284b;
  }

  .description {
    line-height: 28px;
    font-size: 22px;
    font-weight: 300;
    color: #15284b;
  }

  & * {
    font-family: ${props => props.fontFamily};
  }

  .ant-radio-wrapper .ant-radio-inner::after {
    width: 22px;
    height: 22px;
    background: #5dca83;
    border: 3px solid #fff;
    margin-top: -11px;
    margin-left: -11px;
  }

  & .ant-radio-wrapper .ant-radio-input:focus-visible + .ant-radio-inner {
    outline: none;
  }

  & .ant-radio-wrapper .ant-radio-inner {
    width: 22px;
    height: 22px;
  }

  & .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.775);
  }

  @media screen and (max-width: 720px) {
    padding: 40px;
  }
`;

const ContentIPQ = ({
  open,
  handleClose,
  handleCreateProposal,
  setShowDisclosuresModal,
}) => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [proposalData, setProposalData] = useState(defaultProposalData);

  useEffect(() => {
    if (!open) {
      setCurrentStepIndex(0);
      setProposalData(defaultProposalData);
    }
  }, [open]);

  const updateProposalData = (key, value) =>
    setProposalData(state => ({
      ...state,
      [key]: value,
    }));

  const handleBackButton = () => {
    handleGuideStepIndexChange(currentStepIndex - 1);
  };

  const handleGuideStepIndexChange = newStepIndex => {
    if (newStepIndex === -1) {
      handleClose();
    } else if (
      newStepIndex === 3 &&
      (proposalData.currentAgeRange === null ||
        proposalData.incomeExpectations === null)
    ) {
      managerState.showWarning("All fields are required to be completed.");
    } else if (
      newStepIndex === 4 &&
      (proposalData.beginWithdrawal === null ||
        proposalData.investmentKnowledge === null)
    ) {
      managerState.showWarning("All fields are required to be completed.");
    } else if (
      newStepIndex === 5 &&
      (proposalData.decisionInfluence === null ||
        proposalData.volatilityTolerance === null ||
        proposalData.decliningMarketWithholdingPeriod === null)
    ) {
      managerState.showWarning("All fields are required to be completed.");
    } else if (newStepIndex === 7) {
      const yearToStartWithdrawals = convertYearToStartWithdrawalsRange(
        proposalData.beginWithdrawal
      );

      if (proposalData.investmentDuration <= yearToStartWithdrawals) {
        managerState.showError(
          "Length of investment must be greater than the begin withdrawals year"
        );
        return;
      }

      handleCreateProposal({
        ...proposalData,
        contributions: proposalData.contributions * 12,
        currentAge: convertCurrentAgeRange(proposalData.currentAgeRange),
        investmentsToAlignWith: JSON.stringify(
          proposalData.investmentsToAlignWith
        ),
        investmentsToAvoid: JSON.stringify(proposalData.investmentsToAvoid),
        yearToStartWithdrawals,
        investmentObjective:
          proposalData.riskTolerance === "preservation" ||
          proposalData.riskTolerance === "conservative"
            ? "income"
            : "growth",
      });
    } else {
      setCurrentStepIndex(newStepIndex);
    }
  };

  const handleSaveContinueButton = () => {
    const activeUserData = managerState.managedUsers?.find(
      it => it.email === managerState.activeEmail
    );

    console.log(activeUserData);
    managerState.showWarning("TODO saving new data");
    handleGuideStepIndexChange(currentStepIndex + 1);
  };

  const handleSaveExitButton = () => {
    const activeUserData = managerState.managedUsers?.find(
      it => it.email === managerState.activeEmail
    );

    console.log(activeUserData);
    managerState.showWarning("TODO saving new data");
    handleClose();
  };

  const ButtonBack = ({ label = "Back" }) => (
    <Button
      onClick={handleBackButton}
      shape={"round"}
      size={"small"}
      style={{ fontSize: 16 }}
      type={"text"}
    >
      {label}
    </Button>
  );

  const ButtonForward = ({ label = "Continue" }) => (
    <Button
      disabled={managerState.loading}
      onClick={() => handleGuideStepIndexChange(currentStepIndex + 1)}
      shape={"round"}
      size={"small"}
      style={{
        width: 142,
        fontSize: 16,
        backgroundColor: "#192849",
        color: "#FFFFFF",
      }}
      type={"primary"}
    >
      {label}
    </Button>
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: token.proposalGuideRadio,
          Checkbox: token.proposalGuideCheckbox,
        },
      }}
    >
      <Container fontFamily={token.proposalGuideFontPrimary}>
        <div className={"content-body"}>
          <Row
            justify="space-between"
            style={{
              borderBottom: "1px solid #6DC1C7",
              marginBottom: 25,
              paddingBottom: 10,
            }}
          >
            <OneAscentLogo style={{ width: 160 }} />
            <OneAscentSlogan style={{ width: 160 }} />
          </Row>
          {currentStepIndex === 0 && (
            <SectionSeekToEliminate
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack label={"Close"} />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
              openDisclosuresModal={() => setShowDisclosuresModal(true)}
            />
          )}
          {currentStepIndex === 1 && (
            <SectionSeekToElevate
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
              openDisclosuresModal={() => setShowDisclosuresModal(true)}
            />
          )}
          {currentStepIndex === 2 && (
            <SectionAboutMe
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 3 && (
            <SectionTimeHorizon
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 4 && (
            <ReturnsRiskVolatility
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 5 && (
            <SectionRiskSelection
              ButtonsContainer={() => (
                <Flex
                  align={"center"}
                  gap={22}
                  style={{ marginTop: 100 }}
                  vertical
                >
                  <Button
                    disabled={!proposalData.acceptedRiskLevel}
                    onClick={handleSaveContinueButton}
                    shape={"round"}
                    size={"small"}
                    style={{ background: "#192849", fontSize: 16, wodth: 240 }}
                    type={"primary"}
                  >
                    Save & Continue Planning
                  </Button>
                  <Button
                    disabled={!proposalData.acceptedRiskLevel}
                    onClick={handleSaveExitButton}
                    shape={"round"}
                    size={"small"}
                    style={{
                      background: token.colorPrimary,
                      fontSize: 16,
                      width: 132,
                    }}
                    type={"primary"}
                  >
                    Save & Exit
                  </Button>
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
          {currentStepIndex === 6 && (
            <SectionBitAboutYou
              ButtonsContainer={() => (
                <Flex align={"center"} gap={12} vertical>
                  <ButtonForward label={"Create"} />
                  <ButtonBack />
                </Flex>
              )}
              proposalData={proposalData}
              updateProposalData={updateProposalData}
            />
          )}
        </div>
      </Container>
    </ConfigProvider>
  );
};

export default ContentIPQ;
