import { useEffect, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import styled from "styled-components";
import { Button, Dropdown, Flex, theme, Typography } from "antd";

import { DEFAULT_PRODUCT_ACCENT_COLOR } from "../../utils/constants";

import Close from "../../icons/Close";

const { Text } = Typography;

const Card = styled(Flex)`
  flex-grow: 1;
  flex-wrap: nowrap;
  max-width: ${props => props.max_width};
  width: ${props => props.width};
  min-width: ${props => props.min_width};
  min-height: ${props => props.min_height ?? props.height};
  background: ${({ token }) => token.productCardBackground};
  border-left: 12px solid ${props => props.accent_color};
  border-top: ${({ token }) => token.productCardBorder};
  border-right: ${({ token }) => token.productCardBorder};
  border-bottom: ${({ token }) => token.productCardBorder};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px 24px 32px 19px;
  overflow: auto;
  margin: 8px;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }

  .title {
    width: 100%;
    line-height: 26px;
    font-size: 22px;
    font-weight: 500;
    color: #2a2828;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .top-label {
    font-size: 13px;
  }

  .objective {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CloseButtonContainer = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
`;

const StyledButton = styled(Button)`
  height: 37px;
  border-color: transparent;
`;

const CardProduct = ({
  actions,
  handleCardClick,
  handleCloseButtonClick,
  handleSecondaryButtonActivate,
  height,
  inactive,
  isActionButtonActive,
  isSecondaryButtonActive,
  labels,
  minHeight,
  onMouseEnter,
  onMouseLeave,
  product,
  showCloseButton,
  showChart,
  showObjective,
  showSecondaryButton,
  width = "245px",
  maxWidth = "380px",
  minWidth = "185px",
  viewMode,
}) => {
  const { token } = theme.useToken();
  const chartRef = useRef(null);

  useEffect(() => {
    if (viewMode === "pdf") {
      const storedData = localStorage.getItem("pieChartSvg");

      const interval = setInterval(() => {
        if (!storedData) {
          const pieChartSvg = chartRef?.current?.chart?.getSVG();

          pieChartSvg && localStorage.setItem("pieChartSvg", pieChartSvg);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [viewMode]);

  const handleMenuClick = ({ item }) => item.props.onSelect(product);

  const menuProps = {
    items: actions,
    onClick: it => {
      it.domEvent.stopPropagation();
      handleMenuClick(it);
    },
  };

  const chartConfig = {
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      spacingTop: 0,
      width: 260,
      height: 250,
    },
    colors: token.chartColors,
    tooltip: {
      outside: true,
      style: {
        zIndex: 9999,
      },
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: 116,
        innerSize: "60%",
        showInLegend: true,
        center: ["50%", "50%"],
        borderWidth: 0,
        dataLabels: {
          connectorWidth: 0,
          enabled: true,
          format: "{point.percentage:.1f}%",
          distance: 2,
          position: "left",
          style: {
            fontSize: 16,
            fontWeight: 400,
            color: "#516474",
          },
        },
      },
    },
    legend: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Percent",
        colorByPoint: true,
        data: product?.assets?.map(it => ({
          name: it.assetName,
          y: it.investmentPercent,
        })),
      },
    ],
  };

  const getActions = () =>
    actions ? (
      actions.length > 1 ? (
        <Dropdown menu={menuProps} trigger={["click"]}>
          <Button
            onClick={e => e.stopPropagation()}
            size={"small"}
            shape={"round"}
            style={{ borderColor: "transparent", marginTop: 16 }}
          >
            Options
          </Button>
        </Dropdown>
      ) : isActionButtonActive ? (
        <StyledButton
          onClick={e => {
            e.stopPropagation();
            actions[0].onSelect(product);
          }}
          shape={"round"}
          style={{
            background: token.tenantColorPrimary,
            color: "#FFFFFF",
            marginTop: 16,
          }}
        >
          {actions[0].buttonActiveCopy}
        </StyledButton>
      ) : (
        <StyledButton
          shape={"round"}
          style={{
            marginTop: 16,
            width: actions[0].buttonWidth,
          }}
          onClick={e => {
            e.stopPropagation();
            actions[0].onSelect(product);
          }}
        >
          {actions[0].buttonCopy}
        </StyledButton>
      )
    ) : (
      ""
    );

  const getBottomLabels = () => (
    <div
      style={{
        color: token.colorUiText,
        marginTop: 8,
        marginBottom: showObjective && 19,
      }}
    >
      {labels
        .filter(it => !!it)
        .map((label, index) => (index === 0 ? label : ` • ${label}`))}
    </div>
  );

  const getCloseButton = () =>
    showCloseButton && (
      <CloseButtonContainer
        onClick={e => {
          e.stopPropagation();
          handleCloseButtonClick(product?._id);
        }}
      >
        <Close />
      </CloseButtonContainer>
    );

  const getSecondaryButton = () =>
    showSecondaryButton &&
    (isSecondaryButtonActive ? (
      <StyledButton
        shape={"round"}
        style={{
          background: token.tenantColorPrimary,
          color: "#FFFFFF",
        }}
      >
        Added to Proposal
      </StyledButton>
    ) : (
      <StyledButton
        shape={"round"}
        onClick={e => {
          e.stopPropagation();
          handleSecondaryButtonActivate(product?._id);
        }}
      >
        Add to Proposal
      </StyledButton>
    ));

  return (
    <Card
      accent_color={product?.color ?? DEFAULT_PRODUCT_ACCENT_COLOR}
      className={"product-card-container"}
      direction={"vertical"}
      height={height}
      onClick={() => handleCardClick && handleCardClick(product?._id)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ opacity: inactive && 0.3 }}
      min_height={minHeight}
      max_width={maxWidth}
      min_width={minWidth}
      token={token}
      width={width}
    >
      <Flex
        align={"start"}
        gap={2}
        justify={"space-between"}
        style={{ width: "100%" }}
        vertical
      >
        <div style={{ width: "100%" }}>
          <Flex
            align={"center"}
            justify={"space-between"}
            style={{ height: 19, width: "100%" }}
            wrap={"nowrap"}
          >
            <span
              className={"top-label"}
              style={{ color: token.colorUiText }}
              dangerouslySetInnerHTML={{ __html: product?.assetManager }}
            />
            {getCloseButton()}
          </Flex>
          <div className={"title"}>{product?.name}</div>
          {getBottomLabels()}
          {showObjective && (
            <Text className={"objective"}>{product?.objective}</Text>
          )}
        </div>
        <Flex align={"flex-end"} gap={21}>
          {getActions()}
          {getSecondaryButton()}
        </Flex>
      </Flex>
      {showChart && (
        <Flex>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartConfig}
            ref={chartRef}
          />
        </Flex>
      )}
    </Card>
  );
};

export default CardProduct;
