import { useContext } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Flex, Layout, Row, theme } from "antd";
import State from "../../context/manager/State";
import AdminHeader from "./components/AdminHeader";
import AdvisorHeader from "./components/AdvisorHeader";
import MenuManagerProfile from "../../molecules/MenuManagerProfile";
import MenuSidebar from "../../molecules/MenuSidebar";
import ModalAddProspect from "../../organisms/ModalAddProspect";
import ModalAdminSettings from "../../organisms/ModalAdminSettings";
import ModalAdvisorArtBoard from "../../organisms/ModalAdvisorArtBoard";
import SectionAskAdvice from "../../organisms/SectionAskAdvice";
import ModalManagedUserData from "../../organisms/ModalManagedUserData";
import ModalProposalGuide from "../../organisms/ModalProposalGuide";
import PennyChatToggler from "../../organisms/SectionAskAdvice/components/PennyChatToggler";

import {
  isOrganizationLevel,
  isOrganizationOneascent,
  isPortalAdmin,
} from "../../utils/helpers/specialized";

const { Content, Sider } = Layout;

const StyledLayout = styled(Layout)`
  flex-direction: column;
  height: 100vh;

  & .ant-layout-header {
    height: fit-content;
    background: #ffffff;
    padding: 24px 46px;
  }

  & .ant-layout-content {
    padding: 0 85px 40px 40px;
  }

  &.ant-layout .ant-layout-sider {
    background: ${props => props.sidebarbackground};
    background-position: left;
  }

  &.ant-layout.ant-layout-has-sider > .ant-layout,
  &.ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: auto;
    background-color: #fff;
    overflow-x: auto;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    font-weight: 500;
    color: #8093a3;
    border-bottom-color: #bec8ce;
    white-space: pre;
    background: #fff;

    &::before {
      display: none;
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    color: #8093a3;
    border-bottom-color: #bec8ce;
  }
`;

const LayoutManager = ({ routes, children }) => {
  const { token } = theme.useToken();
  const location = useLocation();
  const [adminState, setAdminState] = useContext(State);

  return (
    <StyledLayout hasSider sidebarbackground={token.sidebarBackground}>
      <Sider breakpoint="md" collapsedWidth="0" width={184}>
        <Flex
          justify={"space-between"}
          style={{
            width: "100%",
            height: "100%",
            padding: "56px 0 56px 28px",
          }}
          vertical
        >
          <MenuSidebar routes={routes} />
          <MenuManagerProfile />
        </Flex>
      </Sider>
      <Layout
        style={{
          backgroundColor: location.pathname.includes(
            "advisor-portal/dashboard"
          )
            ? "#fafafa"
            : "#FFFFFF",
          minWidth: 800,
          overflow: "auto",
          width: "auto",
        }}
      >
        {isPortalAdmin() ? (
          <AdminHeader
            organization={adminState.organization?.name ?? adminState.orgName}
          />
        ) : (
          <AdvisorHeader
            organization={adminState.organization?.name ?? adminState.orgName}
          />
        )}
        <Row
          justify={"center"}
          style={{
            height: "100%",
          }}
        >
          <Content
            style={{
              maxWidth: 1550,
              overflow: "auto",
              padding: "28px 100px 50px 47px",
            }}
          >
            {children}
          </Content>
        </Row>
      </Layout>
      {isOrganizationLevel(
        adminState.organization?.name ?? adminState.orgName
      ) && (
        <PennyChatToggler
          handleClick={() => adminState.openModal("openModalAskAdvice")}
        />
      )}

      <ModalAddProspect
        handleClose={() => adminState.closeModal("addProspectModal")}
        hideBirtYear={isOrganizationOneascent(adminState.organization?.name)}
        open={adminState.addProspectModal}
      />
      <ModalAdminSettings />
      <ModalAdvisorArtBoard />
      <SectionAskAdvice setState={setAdminState} state={adminState} />
      <ModalManagedUserData
        handleClose={() => adminState.closeModal("addManagedUserModal")}
        isLevelView={true}
        open={adminState.addManagedUserModal}
      />
      <ModalProposalGuide
        email={adminState.activeEmail}
        handleClose={() => adminState.closeModal("proposalGuideModal")}
        open={adminState.proposalGuideModal}
      />
    </StyledLayout>
  );
};

export default LayoutManager;
