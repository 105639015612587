const BlockedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.859"
    height="21.554"
    viewBox="0 0 18.859 21.554"
  >
    <path
      d="M16.839,9.43h-1.01V6.4a6.4,6.4,0,0,0-12.8,0V9.43H2.021A2.021,2.021,0,0,0,0,11.45v8.083a2.021,2.021,0,0,0,2.021,2.021H16.839a2.021,2.021,0,0,0,2.021-2.021V11.45A2.021,2.021,0,0,0,16.839,9.43Zm-4.378,0H6.4V6.4a3.031,3.031,0,1,1,6.062,0Z"
      fill="currentColor"
    />
  </svg>
);

export default BlockedIcon;
