import { useContext, useState } from "react";
import styled from "styled-components";
import State from "../../context/user/State";
import { Button, Checkbox, Flex, Image, Modal, Typography } from "antd";

import { TERMS_LINK } from "../../utils/constants";

import Close from "../../icons/Close";
import PennyLogo from "../SectionAskAdvice/images/penny_logo_2.svg";

const { Text, Link } = Typography;

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    margin-bottom: 45px;
  }

  &.ant-modal .ant-modal-content {
    padding: 32px 32px 46px;
  }

  &.ant-modal .ant-modal-footer {
    margin-top: 30px;
  }

  .title {
    line-height: 40px;
    font-size: 28px;
    font-weight: 700;
    color: #23292c;
    margin: 0 0 35px;
  }

  .description {
    line-height: 24px;
    font-size: 16px;
    color: #23292c;
  }
`;

const ModalPennyTerms = ({ onCancel, open }) => {
  const [userState] = useContext(State);
  const [acceptedPennyTerms, setAcceptedPennyTerms] = useState(
    !!userState.getPreferenceValue("acceptedPennyTerms")
  );

  const handleCheckboxChange = e => {
    setAcceptedPennyTerms(e.target.checked);
  };

  const handleStartConversationClick = () => {
    userState
      .setPreferenceValue("acceptedPennyTerms", acceptedPennyTerms)
      .then(() => {
        userState.setKeyValue("openModalPennyTerms", false);

        if (!userState.abortPennyTermsHandler) {
          userState.setKeyValue("openModalAskAdvice", true);
        }
      });
  };

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      footer={
        <Flex justify={"start"}>
          <Button
            type="secondary"
            shape="round"
            style={{ width: 193, fontSize: 18 }}
            disabled={!acceptedPennyTerms}
            onClick={handleStartConversationClick}
          >
            Start Conversation
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title={<Image preview={false} src={PennyLogo} />}
      width={604}
      zIndex={1001}
    >
      <h4 className={"title"}>
        Welcome to Penny, OnTrajectory’s AI coach powered by ChatGPT.
      </h4>
      <p className={"description"}>
        Before you opt in, please read and{" "}
        <Link href={TERMS_LINK} target="_blank">
          accept our terms and conditions.
        </Link>
      </p>
      <p className={"description"}>
        Please note that Penny is not an investment advisor and does not provide
        investment advice. It may produce inaccurate or incomplete responses at
        times.
      </p>
      <p className={"description"}>
        When interacting with Penny, please do not provide any personally
        identifiable information such as your Social Security number, or your
        banking or brokerage account number.
      </p>
      <p className={"description"}>
        <Checkbox checked={acceptedPennyTerms} onChange={handleCheckboxChange}>
          <Text style={{ marginLeft: 7 }}>
            I agree to the{" "}
            <Link href={TERMS_LINK} target="_blank">
              terms & conditions.
            </Link>
          </Text>
        </Checkbox>
      </p>
    </StyledModal>
  );
};

export default ModalPennyTerms;
