import styled from "styled-components";
import { Tabs } from "antd";
import TabCompanies from "./components/TabCompanies";
import TabEvents from "./components/TabEvents";
import TabSponsors from "./components/TabSponsors";
import TabUsers from "./components/TabUsers";

const StyledTabs = styled(Tabs)`
  &.ant-tabs .ant-tabs-tab-btn {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    color: #105dae;
  }

  &.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    width: 96px;
    font-weight: 700;
    border-bottom: 4px solid #00aeef;
    text-align: left;
    text-shadow: unset;
  }

  &.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
  &.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
    background: transparent;
  }

  &.ant-tabs-left > .ant-tabs-content-holder,
  &.ant-tabs-left > div > .ant-tabs-content-holder {
    min-width: max-content;
    border-left: unset;
  }

  & .ant-tabs-nav-list::before {
    content: "MANAGE";
    font-family: Poppins, sans-serif;
    font-size: 12px;
    color: #a2a2a2;
    margin-left: 24px;
    margin-bottom: 10px;
  }

  & .ant-table-wrapper .ant-table-column-title {
    font-weight: 500;
    color: #383838;
  }

  & .ant-table-wrapper .ant-table-thead > tr > th,
  & .ant-table-wrapper .ant-table-thead > tr > td {
    background: #ffffff;
  }

  &
    .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  & .ant-table-wrapper .ant-table-thead > tr > th,
  & .ant-table-wrapper .ant-table-thead > tr > td {
    border-bottom-color: #707070;
  }

  & .ant-pagination .ant-pagination-item-active {
    background: #00aeef;
    border-color: #00aeef;
  }

  & .ant-pagination .ant-pagination-item-active.ant-pagination-item a {
    font-weight: 400;
    color: #ffffff;
  }

  & .ant-pagination .ant-pagination-item a {
    color: #105dae;
  }

  &
    .ant-pagination
    .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  &
    .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    font-size: 10px;
    color: #105dae;
    margin-top: 4px;
  }

  &
    .ant-pagination.ant-pagination-mini
    .ant-pagination-prev
    .ant-pagination-item-link,
  &
    .ant-pagination.ant-pagination-mini
    .ant-pagination-next
    .ant-pagination-item-link {
    font-size: 16px;
  }
`;

const TabsAdmin = () => {
  const tabsItems = [
    {
      key: "companies",
      label: "Companies",
      children: <TabCompanies />,
    },
    {
      key: "users",
      label: "Users",
      children: <TabUsers />,
    },
    {
      key: "sponsors",
      label: "Sponsors",
      children: <TabSponsors />,
    },
    {
      key: "events",
      label: "Events",
      children: <TabEvents />,
    },
  ];

  return (
    <StyledTabs
      defaultActiveKey="companies"
      items={tabsItems}
      tabPosition={"left"}
    />
  );
};

export default TabsAdmin;
