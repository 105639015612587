import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import State from "../../../../context/user/State";
import { Button, Flex, Layout, theme } from "antd";
import CardGoalPreview from "../../components/CardGoalPreview";
import Header from "../../components/Header";
import ModalCreateGoal from "../../components/ModalCreateGoal";
import ModalProductDetails from "../../../../organisms/ModalProductDetails";
import SkeletonDashboard from "../../../../molecules/SkeletonDashboard";

import { isLockedByAdminView } from "../../../../utils/helpers/specialized";

const PageGoals = () => {
  const { token } = theme.useToken();
  const [userState] = useContext(State);
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });

  return (
    <SkeletonDashboard loading={userState.loading}>
      <Header>
        <Flex align={"center"} gap={20}>
          <b className={"title"}>Your goals</b>
          <Button
            disabled={isLockedByAdminView({
              managerAccess: userState.managerAccess,
            })}
            onClick={() => userState.openModal("createGoalModal")}
            shape={"round"}
            type={"primary"}
          >
            + New Goal
          </Button>
        </Flex>
      </Header>
      <Layout.Content>
        <Flex align={"start"} gap={32} justify={"center"} wrap={"wrap"}>
          {userState.getPreferenceValue("productMap") &&
            Object.keys(userState.getPreferenceValue("productMap")).map(
              (key, index) => {
                const productMap = userState.getPreferenceValue("productMap");
                const lastDataSnapshot =
                  productMap[key][productMap[key].length - 1];

                return (
                  <div key={index} style={{ width: isMobile ? "100%" : 289 }}>
                    <CardGoalPreview
                      config={{
                        isMobile,
                        hideChart: isMobile,
                      }}
                      goalData={{
                        ...lastDataSnapshot,
                        objective: {
                          ...lastDataSnapshot,
                          organization: "goals",
                        },
                        organization: "goals",
                        productId: lastDataSnapshot.productId,
                      }}
                      productsList={userState.productsList}
                    />
                  </div>
                );
              }
            )}
        </Flex>
      </Layout.Content>

      <ModalCreateGoal />
      <ModalProductDetails
        handleClose={() => userState.closeModal("productDetailsModal")}
        hideMcSettingsToggler={true}
        investmentAssumptions={userState.investmentAssumptions}
        open={userState.productDetailsModal}
        organization={"goals"}
        product={{
          ...userState.productsList.find(
            it => it._id === userState.productDetailsId
          ),
        }}
        productsList={userState.productsList}
      />
    </SkeletonDashboard>
  );
};

export default PageGoals;
