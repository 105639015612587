import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context/manager/State";
import { Button, ConfigProvider, Flex, Input, Table } from "antd";
import MenuActions from "../../../../../../atoms/MenuActions";
import ModalProposalGuide from "../../../../../../organisms/ModalProposalGuide";

import { ReactComponent as ImportIcon } from "../../../../images/import.svg";

import {
  editLead,
  openProposal,
  setLastAdvisorLogin,
} from "../../../../../../utils/requests/manager";
import { getCurrencyValue } from "../../../../../../utils/helpers/general";
import {
  getLastSavedProposalProps,
  isAdminView,
  isManagerAccessLevelOnly,
  openProposalWithConfig,
} from "../../../../../../utils/helpers/specialized";

import { SearchOutlined } from "@ant-design/icons";

const getColumns = ({
  getLeadType,
  handleArchiveLeadAction,
  handleCreateProposal,
  handleEditLeadAction,
  handleMoveLeadToClient,
  handleOpenMyLeadView,
  managerAccess,
  getProposalSavedDate,
  searchedText,
}) =>
  [
    {
      dataIndex: "firstName",
      filteredValue: [searchedText],
      key: "leadName",
      onFilter: (value, record) =>
        String(record.firstName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.lastName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (value, restValues) =>
        isManagerAccessLevelOnly(managerAccess) &&
        !getProposalSavedDate(restValues) ? (
          <span>
            {restValues.firstName} {restValues.lastName}
          </span>
        ) : (
          <a
            className={"pointer"}
            onClick={() =>
              handleOpenMyLeadView({
                planningType: restValues.planningType,
                userEmail: restValues.email,
              })
            }
          >
            {restValues.firstName} {restValues.lastName}
          </a>
        ),
      title: "Name",
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={"mailto:" + value}>{value}</a>,
      title: "Email",
    },
    {
      dataIndex: "leadType",
      key: "leadType",
      render: (value, restValues) => getLeadType(restValues),
      title: "Lead Type",
    },
    {
      dataIndex: "planningType",
      key: "planningType",
      render: (planningType, allValues) => (
        <span style={{ textTransform: "capitalize" }}>
          {planningType ?? allValues?.valueMap?.leadInitial?.planningType}
        </span>
      ),
      title: "Planning Type",
    },
    {
      dataIndex: "valueMap",
      key: "assets",
      render: (valueMap, restValues) =>
        getCurrencyValue(
          restValues.lastLoginTime
            ? restValues.currentAssets
            : valueMap?.leadInitial?.assets ?? 0
        ),
      title: "Assets",
    },
    {
      align: "right",
      hidden: !isManagerAccessLevelOnly(managerAccess),
      key: "proposalGenerated",
      render: (_, restValues) => {
        const proposalSavedDate = getProposalSavedDate(restValues);

        return proposalSavedDate ? (
          dayjs(proposalSavedDate).format("M/DD/YYYY")
        ) : (
          <Button
            shape="round"
            size="small"
            onClick={() => handleCreateProposal(restValues.email)}
            disabled={isAdminView()}
          >
            Create Proposal
          </Button>
        );
      },
      sorter: (a, b) => dayjs(a.proposalGenerated) - dayjs(b.proposalGenerated),
      title: "Proposal Generated",
      width: 150,
    },
    {
      align: "right",
      dataIndex: "lastAdvisorLoginTime",
      key: "lastAdvisorLoginTime",
      render: it => it?.$date && dayjs(it.$date).format("M/DD/YYYY"),
      title: "Advisor Last Login",
    },
    {
      align: "right",
      dataIndex: "lastAdvisorUpdateTime",
      key: "lastAdvisorUpdateTime",
      render: it => it?.$date && dayjs(it.$date).format("M/DD/YYYY"),
      title: "Advisor Last Update",
    },
    {
      className: "actions-menu-table-cell",
      dataIndex: "actionsMenu",
      key: "actionsMenu",
      render: (value, restValues) => (
        <MenuActions
          items={getItems({
            email: restValues.email,
            handleArchiveLeadAction,
            handleEditLeadAction,
            handleMoveLeadToClient,
          })}
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

const getItems = ({
  email,
  handleArchiveLeadAction,
  handleEditLeadAction,
  handleMoveLeadToClient,
}) => [
  {
    key: "movetoClient",
    label: (
      <div onClick={() => handleMoveLeadToClient(email)}>Move to Client</div>
    ),
  },
  {
    key: "editLead",
    label: <div onClick={() => handleEditLeadAction(email)}>Edit Lead</div>,
  },
  {
    type: "divider",
  },
  {
    key: "archiveLead",
    label: (
      <div
        onClick={() => handleArchiveLeadAction(email)}
        style={{ color: "red" }}
      >
        Archive
      </div>
    ),
  },
];

const SectionMyLeads = ({ handleMoveLeadToClient, myLeadsList }) => {
  const [managerState] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const getLeadType = leadData => {
    if (managerState.access?.level) {
      return "";
    } else if (managerState.access?.affiliate) {
      if (
        (leadData?.planningType?.toLowerCase() ??
          leadData?.valueMap?.leadInitial?.planningType?.toLowerCase()) ===
        "cashflow"
      ) {
        return "External";
      } else {
        return "Affiliate";
      }
    } else {
      return "External";
    }
  };

  const getProposalSavedDate = proposalData =>
    proposalData.proposalSavedDate?.$date ??
    (proposalData.valueMap?.productMap &&
      getLastSavedProposalProps(proposalData.valueMap.productMap)?.timestamp);

  const handleArchiveLeadAction = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("archiveLeadModal");
  };

  const handleCreateProposal = email => {
    const proposalData = managerState?.managedUsers?.find(
      it => it.email === email
    );

    if (
      !proposalData?.valueMap?.guideInitialData ||
      proposalData?.valueMap?.guideInitialData?.enableOnboardingQuestions
    ) {
      managerState.setKeyValue("activeEmail", email);
      managerState.setKeyValue("openProposalGuide", true);
    } else {
      openProposalWithConfig({
        organization: managerState?.organization?.name,
        prospectType: managerState?.onboardingQuestionnaireType,
        userEmail: email,
      });
    }
  };

  const handleEditLeadAction = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("leadDetailsModal");
  };

  const handleImportLeadsClick = () => {
    managerState.openModal("importManagedUserModal");
  };

  const handleNewLeadModalOpen = () => {
    managerState.setKeyValue("activeEmail", null);
    managerState.openModal("leadDetailsModal");
  };

  const handleOpenMyLeadView = ({ userEmail }) => {
    if (managerState.access?.level) {
      openProposal({ userEmail });
      setLastAdvisorLogin(userEmail).then(() => managerState.setAdminData());
    } else {
      handleEditLeadAction(userEmail);
    }
  };

  return (
    <>
      <Flex
        align={"center"}
        justify={"space-between"}
        style={{ marginBottom: 16 }}
      >
        <Input
          style={{
            maxWidth: 228,
            height: 40,
            borderRadius: 25,
          }}
          placeholder="Search my leads..."
          onChange={event => setSearchedText(event.target.value)}
          suffix={<SearchOutlined />}
        />
        <Flex gap={20}>
          <Button
            icon={<ImportIcon />}
            onClick={handleImportLeadsClick}
            shape={"round"}
            size={"small"}
            type={"text"}
          >
            Import Leads
          </Button>
          <Button
            onClick={handleNewLeadModalOpen}
            shape={"round"}
            size={"small"}
            style={{
              width: 125,
              background: "#428916",
              color: "#FFFFFF",
            }}
          >
            + New Lead
          </Button>
        </Flex>
      </Flex>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerColor: "#6C6C6C",
              colorText: "#516474",
              rowHoverBg: "#DCE14725",
            },
            Switch: {
              colorPrimary: "#4F8083",
              colorPrimaryHover: "#4F8083",
            },
          },
        }}
      >
        <Table
          columns={getColumns({
            getLeadType,
            getProposalSavedDate,
            handleArchiveLeadAction,
            handleCreateProposal,
            handleEditLeadAction,
            handleMoveLeadToClient,
            handleOpenMyLeadView,
            managerAccess: managerState.access,
            searchedText,
          })}
          dataSource={myLeadsList}
          loading={managerState.loading}
          pagination={false}
          scroll={{
            x: 1250,
          }}
        />
      </ConfigProvider>

      <ModalProposalGuide
        email={managerState.activeEmail}
        open={managerState.openProposalGuide}
        handleClose={() => managerState.setKeyValue("openProposalGuide", false)}
      />
    </>
  );
};

export default SectionMyLeads;
