import styled from "styled-components";
import { Flex, Image } from "antd";
import dayjs from "dayjs";
import CarouselPrimary from "../../../../../../atoms/CarouselPrimary";

const Card = styled.div`
  height: 146px;
  border-radius: 12px;
  padding: 10px 10px 27px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #00000029;
  margin: 2px 0;
`;

const CarouselEvents = ({ events }) => (
  <CarouselPrimary>
    {events?.map((it, index) => (
      <Card
        className={it.linkUrl ? "pointer" : ""}
        key={index}
        onClick={() => it.linkUrl && window.open(it.linkUrl)}
      >
        <Flex gap={10} justify={"space-between"} style={{ height: "100%" }}>
          <Image
            alt={it.graphicFile?.filename}
            height={"100%"}
            preview={false}
            src={it.graphicFile?.url}
          />
          <Flex justify={"center"} style={{ width: 211 }} vertical>
            <span
              style={{
                fontSize: 16,
                color: "#9D9D9D",
                textTransform: "uppercase",
              }}
            >
              Event
            </span>
            <b style={{ lineHeight: "20px", fontSize: 18, color: "#17191C" }}>
              {it.name}
            </b>
            <span style={{ fontSize: 13, color: "#4EABE9" }}>
              <b>{dayjs(it.date).format("MMM DD, YYYY")}</b>
              {" • "}
              {it.location}
            </span>
          </Flex>
        </Flex>
      </Card>
    ))}
  </CarouselPrimary>
);

export default CarouselEvents;
