import dayjs from "dayjs";
import styled from "styled-components";
import { Flex, Image } from "antd";

const NewsCard = styled(Flex)`
  width: 244px;
  height: 144px;
  background: #e5f1f5;
  border-radius: 12px;
  padding: 16px;

  .date {
    font-size: 10px;
    color: #546170;
    margin-top: 4px;
  }

  .image-container {
    width: 127px;
    border-radius: 12px;
    overflow: hidden;
  }

  .description-container {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 3px;
  }

  .description {
    line-height: 18px;
    color: #21409a;
    margin: 0;
    cursor: pointer;
  }

  .source {
    display: -webkit-box;
    font-size: 10px;
    color: #546170;
    margin: 0;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CarouselHorizontal = ({ news }) => (
  <Flex gap={21} style={{ overflow: "auto" }}>
    {news?.map(it => (
      <NewsCard gap={16} key={it.id}>
        {it.image_url && (
          <div className={"image-container"}>
            <Image
              height={"100%"}
              preview={false}
              src={it.image_url}
              width={"max-content"}
            />
          </div>
        )}
        <Flex style={{ width: it.title.length > 60 ? 212 : 137 }} vertical>
          <div className={"date"}>
            {dayjs(it.updated).format("MMM D, YYYY h:mma")}
          </div>
          <div className={"description-container"}>
            <p className={"description"} onClick={() => window.open(it.url)}>
              {it.title}
            </p>
          </div>
          <p className={"source"}>Source: {it.sourceName}</p>
        </Flex>
      </NewsCard>
    ))}
  </Flex>
);

export default CarouselHorizontal;
