import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Input,
  Modal,
  Row,
  Select,
  Space,
  theme,
  Typography,
} from "antd";

import Close from "../../../../icons/Close";
import InputContainer from "../../../../atoms/InputContainer";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-header {
    margin-bottom: 19px;
  }

  &.ant-modal .ant-modal-footer {
    margin-top: 32px;
  }

  & .ant-input,
  & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #ebf1f3;
    border-color: transparent;
  }
`;

const ModalFeaturedContentOptions = ({
  activeFileData,
  foldersList,
  handleUpdateFeaturedContent,
  loading,
  onClose,
  open,
  organization,
}) => {
  const { token } = theme.useToken();
  const [featuredContentData, setFeaturedContentData] = useState();
  const [featuredFilesAmount, setFeaturedFilesAmount] = useState(0);

  useEffect(() => {
    setFeaturedFilesAmount(
      foldersList
        ?.map(it => it.marketingMaterials)
        ?.flat()
        ?.filter(it => it?.featureContentStatus === "Visible")?.length
    );

    setFeaturedContentData({
      description: activeFileData?.featureContentDescription,
      folderId: activeFileData?.folderData?.id,
      headline: activeFileData?.featureContentHeadline,
      id: activeFileData?.id,
      order: getOrderValue(),
      featureContentStatus: activeFileData?.featureContentStatus ?? "Hidden",
    });
  }, [open]);

  const handleValueChange = (key, value) =>
    setFeaturedContentData(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const getOrderValue = () => {
    if (!featuredFilesAmount) return 1;

    if (activeFileData?.featureContentStatus === "Visible") {
      if (featuredFilesAmount === 1) {
        return 1;
      } else {
        if (activeFileData?.featureContentOrder > featuredFilesAmount) {
          return featuredFilesAmount;
        } else {
          return activeFileData?.featureContentOrder || 1;
        }
      }
    } else {
      if (featuredFilesAmount === 3) {
        return 3;
      } else {
        return featuredFilesAmount + 1;
      }
    }
  };

  return (
    <StyledModal
      closeIcon={<Close />}
      destroyOnClose={true}
      footer={
        <Row>
          <Space>
            <Button
              type={"primary"}
              shape={"round"}
              onClick={() => handleUpdateFeaturedContent(featuredContentData)}
              style={{
                width: 75,
              }}
              disabled={loading}
            >
              Save
            </Button>
            <Button type="text" shape="round" onClick={onClose}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
      onCancel={onClose}
      open={open}
      title="Featured Content Options"
      width={470}
    >
      <Space direction={"vertical"} size={21}>
        <div
          style={{
            background: "#4897D126",
            padding: "20px 30px 20px 23px",
            borderRadius: 12,
          }}
        >
          <Typography.Paragraph strong style={{ marginBottom: 9 }}>
            {activeFileData?.name}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ margin: 0 }}>
            This will allow your advisors to access this file in their dashboard
            in the section titled “From {organization}”. You can customize the
            order it’s shown as well as a title and description.
          </Typography.Paragraph>
        </div>
        <Space size={28}>
          <InputContainer
            labelText={"Visibility"}
            labelColor={token.colorGrey1}
          >
            <Select
              value={featuredContentData?.featureContentStatus}
              style={{ width: 130 }}
              options={[
                {
                  value: "Hidden",
                  label: "Not Featured",
                },
                {
                  value: "Visible",
                  label: "Featured",
                },
              ]}
              onChange={value =>
                handleValueChange("featureContentStatus", value)
              }
            />
          </InputContainer>
          <InputContainer
            labelText={"Order of Appearance"}
            labelColor={token.colorGrey1}
          >
            <Select
              value={featuredContentData?.order}
              options={[
                {
                  value: 1,
                  label: 1,
                },
                {
                  value: 2,
                  label: 2,
                  disabled:
                    activeFileData?.featureContentStatus === "Visible"
                      ? featuredFilesAmount === 1
                      : featuredFilesAmount === 0,
                },
                {
                  value: 3,
                  label: 3,
                  disabled:
                    activeFileData?.featureContentStatus === "Visible"
                      ? featuredFilesAmount < 3
                      : featuredFilesAmount < 2,
                },
              ]}
              style={{ width: 65 }}
              onChange={value => handleValueChange("order", value)}
            />
          </InputContainer>
        </Space>
        <InputContainer labelText={"Headline"} labelColor={token.colorGrey1}>
          <Input
            value={featuredContentData?.headline}
            onChange={e => handleValueChange("headline", e.target.value)}
          />
        </InputContainer>
        <InputContainer
          labelText={"Short Description"}
          labelColor={token.colorGrey1}
        >
          <Input.TextArea
            value={featuredContentData?.description}
            onChange={e => handleValueChange("description", e.target.value)}
          />
        </InputContainer>
      </Space>
    </StyledModal>
  );
};

export default ModalFeaturedContentOptions;
