import styled from "styled-components";
import { Checkbox, Col, Flex, Image, Row, Typography } from "antd";
import SeeDisclosures from "../SeeDisclosures";

import { checkboxList } from "./constants";

import checkbox_icon from "./images/green_small_icon.svg";
import demo from "./images/demo.png";
import { ReactComponent as GreenLargeIcon } from "./images/green_large_icon.svg";

const StyledCheckBox = styled(Checkbox)`
  font-size: 16px;

  .ant-checkbox-inner {
    transform: scale(1.5);
  }

  &.ant-checkbox-checked .ant-checkbox-inner,
  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
    border-color: #5f6060;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    left: 1px;
    top: 3px;
    background: url(${checkbox_icon});
    background-size: contain;
    height: 10px;
    width: 10px;
    border: transparent;
    transform: unset;
  }
`;

const SectionSeekToElevate = ({
  ButtonsContainer,
  openDisclosuresModal,
  proposalData,
  updateProposalData,
}) => (
  <Flex gap={50} justify={"space-between"} wrap={"wrap"}>
    <Flex vertical style={{ paddingTop: 30, maxWidth: 568 }}>
      <GreenLargeIcon />
      <Typography.Title
        className={"title"}
        style={{ color: "#46B978", margin: "10px 0 3px" }}
      >
        Elevate
      </Typography.Title>
      <p className="description" style={{ margin: "0 0 17px" }}>
        We seek to Elevate companies that demonstrably and consistently promote
        flourishing for their stakeholders.*
      </p>
      <p style={{ fontSize: 16, color: "#939395", margin: "0 0 37px" }}>
        Of the options, select which ones you want to actively support.
      </p>
      <Checkbox.Group
        onChange={selectedValues =>
          updateProposalData("investmentsToAlignWith", selectedValues)
        }
        style={{
          width: "100%",
          marginBottom: 41,
        }}
        value={proposalData.investmentsToAlignWith}
      >
        <Row gutter={[60, 14]}>
          {checkboxList.map(value => (
            <Col key={value} span={12}>
              <StyledCheckBox value={value}>{value}</StyledCheckBox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
      <ButtonsContainer />

      <SeeDisclosures openModal={openDisclosuresModal} />
    </Flex>
    <Image height={600} preview={false} src={demo} width={430} />
  </Flex>
);

export default SectionSeekToElevate;
