import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Flex, theme } from "antd";

import { PENNY_TIPS_LIST } from "../../../../utils/constants";

import { ReactComponent as ArrowRight } from "../../images/icon_arrow_right.svg";

const Container = styled(Flex)`
  .title {
    color: #000;
    font-size: 26px;
    font-weight: 800;
    line-height: 30px;
    margin: 0 12px;
  }

  .divider {
    background: #749b9e10;
    border-radius: 25px;
    height: 6px;
    width: 184px;
  }

  .card {
    background: #e6f4ef;
    border-radius: 12px;
    cursor: pointer;
    padding: 18px;

    .title {
      color: #1e1919;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      margin: 0;
    }

    .description {
      color: #1e1919;
      line-height: 17px;
      margin: 0;
    }

    .icon-container {
      background: #fff;
      border-radius: 50%;
      color: #4f8083;
      height: 24px;
      min-width: 24px;
      padding-left: 2px;
      width: 24px;
    }

    &:hover {
      background: #c3e5d8;

      .icon-container {
        background: #4f8083;
        color: #fff;
      }
    }
  }
`;

const PennyTips = ({ handlePennyTipClick }) => {
  const { token } = theme.useToken();
  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  return (
    <Container gap={isSmallScreen ? 10 : 31} vertical>
      <h2 className={"title"}>Let our AI help answer your money questions</h2>
      <Flex justify={"center"}>
        <div className={"divider"} />
      </Flex>
      <Flex gap={16} vertical>
        {PENNY_TIPS_LIST.map((it, index) => (
          <Flex
            align={"center"}
            className={"card"}
            gap={6}
            justify={"space-between"}
            onClick={() => handlePennyTipClick(it.title)}
            key={index}
          >
            <Flex gap={6} vertical>
              <h3 className={"title"}>{it.title}</h3>
              <p className={"description"}>{it.description}</p>
            </Flex>
            <Flex
              align={"center"}
              className={"icon-container"}
              justify={"center"}
            >
              <ArrowRight />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Container>
  );
};

export default PennyTips;
