import { Space, theme, Typography } from "antd";
import dayjs from "dayjs";

import Folder from "../../../../../icons/Folder";

const FolderCard = ({ name, date, onSelect }) => {
  const { token } = theme.useToken();

  return (
    <Space
      align="center"
      direction="vertical"
      size={0}
      style={{ width: 127, textAlign: "center", cursor: "pointer" }}
      onClick={onSelect}
    >
      <Folder />
      <Typography.Text style={{ fontWeight: 500, color: token.colorUIText }}>
        {name}
      </Typography.Text>
      <Typography.Text style={{ fontSize: 12, color: token.colorGrey1 }}>
        {dayjs(date).format("M/DD/YYYY")}
      </Typography.Text>
    </Space>
  );
};

export default FolderCard;
