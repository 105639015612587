import PageDash from "../../goals/pages/PageDash";
import PageGoal from "../../goals/pages/PageGoal";
import PageGoals from "../../goals/pages/PageGoals";

import GoalsIcon from "../../../icons/GoalsIcon";
import DashboardIconLarge from "../../../icons/DashboardIconLarge";

export default [
  {
    path: "/bluepoint/dashboard",
    Component: PageDash,
    Icon: DashboardIconLarge,
    text: "Dash",
  },
  {
    path: "/bluepoint/goals",
    Component: PageGoals,
    Icon: GoalsIcon,
    text: "Goals",
    relatedPath: "goal",
  },
  {
    path: "/bluepoint/goal/:id",
    Component: PageGoal,
  },
];
