import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../context/manager/State";
import { Avatar, Space, theme } from "antd";
import MenuActions from "../../atoms/MenuActions";

import { logout } from "../../utils/requests/regularApp";
import {
  isPortalOneascentAdvisor,
  openProposalWithConfig,
} from "../../utils/helpers/specialized";

import { UserOutlined } from "@ant-design/icons";

const MenuManagerProfile = ({ logoutLink = "/login" }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [adminState] = useContext(State);

  const getItems = () =>
    [
      {
        key: "1",
        label: (
          <div onClick={() => adminState.openModal("accountSettingsModal")}>
            Settings
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div
            onClick={() =>
              openProposalWithConfig({
                organization:
                  adminState.organization?.name ?? adminState.orgName,
              })
            }
          >
            Demo Mode
          </div>
        ),
        hidden: !isPortalOneascentAdvisor(),
      },
      {
        key: "3",
        label: (
          <div onClick={() => adminState.openModal("artBoardModal")}>
            Back to Launch
          </div>
        ),
        hidden: !isPortalOneascentAdvisor(),
      },
      {
        type: "divider",
      },
      {
        key: "4",
        label: (
          <div
            onClick={() =>
              logout()
                .then(() => navigate(logoutLink))
                .catch(console.log)
            }
          >
            Log Out
          </div>
        ),
      },
    ].filter(it => !it.hidden);

  return (
    <>
      {adminState.loading ? (
        <Avatar
          size={35}
          icon={<UserOutlined />}
          style={{
            backgroundColor: token.colorGrey1,
            cursor: "wait",
          }}
        />
      ) : (
        <MenuActions items={getItems()}>
          <Space style={{ cursor: "pointer" }}>
            <Avatar
              size={35}
              style={{
                backgroundColor: token.sidebarAvatarBackground,
                color: token.sidebarAvatarColor,
              }}
            >
              <b style={{ fontSize: 18 }}>
                {
                  (adminState.personalInfo?.firstName ??
                    adminState.personalInfo?.lastName ??
                    "")[0]
                }
              </b>
            </Avatar>
            <span style={{ color: token.sidebarAvatarLabelColor }}>
              {adminState.personalInfo?.firstName}
              <br />
              {adminState.personalInfo?.lastName}
            </span>
          </Space>
        </MenuActions>
      )}
    </>
  );
};

export default MenuManagerProfile;
