import { useContext } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import State from "../../../../context/manager/State";
import { ConfigProvider, Flex, Tabs, theme } from "antd";
import LeadCard from "./components/LeadCard";
import ProposalCard from "./components/ProposalCard";

import { isOrganizationOneascent } from "../../../../utils/helpers/specialized";

const Container = styled(Flex)`
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs .ant-tabs-tab-btn {
    font-size: 18px;
  }
`;

const RecentUsersSection = ({
  recentProposalsList,
  hideLeads,
  hideClients,
}) => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);

  const isOneascent = isOrganizationOneascent(
    managerState.orgName ?? managerState.organization?.name
  );

  const getDefaultActiveTab = () => {
    if (hideClients) {
      return "leads";
    } else {
      return "clients";
    }
  };

  const items = [
    {
      key: "clients",
      label: isOneascent ? "Recent Proposals" : "Recent Clients",
      children: (
        <Flex gap={15} vertical>
          {recentProposalsList?.map((it, index) => (
            <ProposalCard
              key={index}
              organization={
                managerState.organization?.name ?? managerState.orgName
              }
              userData={it}
            />
          ))}
        </Flex>
      ),
      hidden: hideClients,
    },
    {
      key: "leads",
      label: "Recent Leads",
      children: (
        <Flex gap={15} vertical>
          {managerState.managedUsers
            ?.filter(it => it.userType === "Assigned")
            ?.sort(
              (a, b) =>
                dayjs(b.updateDateTime?.$date) - dayjs(a.updateDateTime?.$date)
            )
            ?.slice(0, 4)
            ?.map((it, index) => (
              <LeadCard key={index} leadData={it} />
            ))}
        </Flex>
      ),
      hidden: hideLeads,
    },
  ].filter(it => !it.hidden);

  return (
    <Container gap={18} vertical>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: token.advisorDashboardPageActiveTabColor,
              lineWidthBold: 5,
            },
          },
        }}
      >
        <Tabs defaultActiveKey={getDefaultActiveTab()} items={items}></Tabs>
      </ConfigProvider>
    </Container>
  );
};

export default RecentUsersSection;
