import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import State from "../../context/user/State";
import CarouselEvents from "./components/CarouselEvents";
import CarouselSponsors from "./components/CarouselSponsors";
import ModalCompetitiveInsight from "./components/ModalCompetitiveInsight";
import ModalSponsorDetails from "./components/ModalSponsorDetails";
import ModalSponsorsList from "./components/ModalSponsorsList";
import SectionCompetitiveInsight from "./components/SectionCompetitiveInsight";
import SectionNews from "./components/SectionNews";
import CardSkeleton from "../../../../atoms/CardSkeleton";
import TableLeague from "./components/TableLeague";

import {
  getChanel,
  getFtsEntity,
  getRecentArticlesForStages,
} from "../../requests/fts";
import { scrollToTop } from "../../../../utils/helpers/general";
import { CHANNEL_WITH_FILTERS_ID } from "../../constants";

const PortalAssetManager = () => {
  const navigate = useNavigate();
  const [userState] = useContext(State);
  const [articles, setArticles] = useState();
  const [activeAssetManagerId, setActiveAssetManagerId] = useState("");
  const [updateAssetManagerId, setUpdateAssetManagerId] = useState("");
  const [changeActiveAdvisorModalOpen, setChangeActiveAdvisorModalOpen] =
    useState(false);
  const [loadingInsights, setLoadingInsights] = useState(false);

  useEffect(() => {
    if (activeAssetManagerId) {
      setLoadingInsights(true);

      const activeCompanyName = userState.leagues?.find(
        it => it._id === activeAssetManagerId
      )?.company;

      getChanel({ channelId: CHANNEL_WITH_FILTERS_ID, token: userState.token })
        .then(filterData => {
          getRecentArticlesForStages({
            requestBody: {
              count: 100,
              offset: 0,
              sorting: "time",
              shouldCluster: true,
              stages: [
                ...filterData.stages,
                {
                  entities: [
                    {
                      type: "keyword",
                      value: `"${activeCompanyName}"`,
                    },
                  ],
                  not: false,
                },
              ],
            },
            token: userState.token,
            userId: userState.userId,
          })
            .then(articles => {
              if (articles?.length) {
                getFtsEntity({
                  ids: Array.from(
                    new Set(
                      articles.map(
                        it => it.contributor ?? it.source ?? it.author
                      )
                    )
                  ).filter(it => it > 0),
                  token: userState.token,
                }).then(sourceList => {
                  setArticles(
                    articles
                      .map(articleData => ({
                        ...articleData,
                        sourceName: sourceList.find(
                          sourceData =>
                            sourceData.id ===
                            (articleData.contributor ??
                              articleData.source ??
                              articleData.author)
                        )?.name,
                      }))
                      .sort((a, b) => dayjs(b.updated) - dayjs(a.updated))
                  );
                  setLoadingInsights(false);
                });
              } else {
                setLoadingInsights(false);
                userState.showWarning(
                  `No news stories were found for ${activeCompanyName}`
                );
                setArticles();
              }
            })
            .catch(error => {
              console.log(error);
              setLoadingInsights(false);
            });
        })
        .catch(error => {
          console.log(error);
          setLoadingInsights(false);
        });
    } else {
      setArticles();
    }
  }, [activeAssetManagerId]);

  const handleChannelChange = assetManagerKey => {
    setUpdateAssetManagerId(assetManagerKey);
    setChangeActiveAdvisorModalOpen(true);
  };

  const handleShowNewsList = (newsList = []) => {
    userState.setKeyValue("newsList", newsList);
    navigate("/mmi/news-list");
    scrollToTop();
  };

  const handleSponsorClick = sponsorData => {
    userState.setKeyValue("activeSponsorData", sponsorData);
    userState.openModal("sponsorDetailsModal");
  };

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <TableLeague handleChannelChange={handleChannelChange} />
      </div>
      <div style={{ marginBottom: 30 }}>
        <CardSkeleton height={165} loading={loadingInsights}>
          <SectionCompetitiveInsight
            articles={articles}
            clearInsights={() => setActiveAssetManagerId("")}
            companyName={
              userState.leagues?.find(it => it._id === activeAssetManagerId)
                ?.company
            }
          />
        </CardSkeleton>
      </div>
      <div style={{ marginBottom: 16 }}>
        <CarouselSponsors
          handleClick={handleSponsorClick}
          openModal={userState.openModal}
          sponsors={userState.sponsors}
        />
      </div>
      <div style={{ marginBottom: 34 }}>
        <CarouselEvents events={userState.events} />
      </div>
      <CardSkeleton height={200} loading={!userState.token}>
        <SectionNews
          handleShowNewsList={handleShowNewsList}
          token={userState.token}
          userId={userState.userId}
        />
      </CardSkeleton>

      <ModalCompetitiveInsight
        activeAssetManagerId={activeAssetManagerId}
        handleClose={() => setChangeActiveAdvisorModalOpen(false)}
        updateAssetManagerId={updateAssetManagerId}
        open={changeActiveAdvisorModalOpen}
        setActiveAssetManagerId={setActiveAssetManagerId}
      />
      <ModalSponsorDetails
        open={userState.sponsorDetailsModal}
        openModal={userState.openModal}
        handleClose={() => userState.closeModal("sponsorDetailsModal")}
        sponsorData={userState.activeSponsorData}
      />
      <ModalSponsorsList
        handleClose={() => userState.closeModal("sponsorsListModal")}
        open={userState.sponsorsListModal}
        sponsorsList={userState.sponsors}
      />
    </>
  );
};

export default PortalAssetManager;
