import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./Routing";
import Trackers from "./Trackers";
import UI from "./UI";
import Loader from "../atoms/Loader";
import SentryProvider from "./SentryProvider";

import themeBasic from "../theme";

const App = ({ children }) => {
  return (
    <SentryProvider>
      <UI themeConfig={themeBasic}>
        <Router>
          <Suspense fallback={<Loader />}>
            <Trackers>
              <Routing>{children}</Routing>
            </Trackers>
          </Suspense>
        </Router>
      </UI>
    </SentryProvider>
  );
};

export default App;
