import { lazy } from "react";

const PageGuide = lazy(() => import("../pages/PageGuide"));
const PageHalo = lazy(() => import("../../halo"));
const PageLogin = lazy(() => import("../pages/PageLogin"));
const PageNotFound = lazy(() => import("../../../pages/PageNotFound"));
const PageSuperAdminWelcome = lazy(
  () => import("../../super_admin/pages/PageWelcome")
);
const PageSymetra = lazy(() => import("../../symetra"));
const PageWelcomeAstor = lazy(() => import("../../astor/pages/PageWelcome"));
const PageWelcomeDefault = lazy(() => import("../pages/PageWelcome"));
const PageWelcomeLevel = lazy(() => import("../../level/pages/PageWelcome"));
const PageWelcomeMmi = lazy(() => import("../../mmi/pages/PageWelcome"));
const PageWelcomeOneascent = lazy(
  () => import("../../oneascent/pages/PageWelcome")
);

export default {
  guide: PageGuide,
  halo: PageHalo,
  login: PageLogin,
  "login-astor": PageWelcomeAstor,
  "login-mmi": PageWelcomeMmi,
  "login-level": PageWelcomeLevel,
  "login-oneascent": PageWelcomeOneascent,
  "login-super-admin": PageSuperAdminWelcome,
  symetra: PageSymetra,
  "": PageWelcomeDefault,
  "*": PageNotFound,
};
