import EmergencySavingsIcon from "../../../icons/goal_type/EmergencySavingsIcon";
import GrowRetirementSavingsIcon from "../../../icons/goal_type/GrowRetirementSavingsIcon";
import OtherInvestmentIcon from "../../../icons/goal_type/OtherInvestmentIcon";
import RetirementIncomeIcon from "../../../icons/goal_type/RetirementIncomeIcon";

export const getGoalTypeIcon = goalName => {
  switch (goalName) {
    case "Retirement income":
      return <RetirementIncomeIcon />;
    case "Emergency Savings":
      return <EmergencySavingsIcon />;
    case "Grow Retirement Savings":
      return <GrowRetirementSavingsIcon />;
    default:
      return <OtherInvestmentIcon />;
  }
};

export const getSavedProgressValuesByGoalId = (goalId, productScenariosMap) =>
  productScenariosMap[goalId][
    productScenariosMap[goalId].length - 1
  ].progress?.map((it, index) => ({
    ...it,
    id: index,
  })) ?? [];
