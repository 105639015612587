import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Button, Flex, Modal, theme } from "antd";
import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import ContainerHelpButtons from "./components/ContainerHelpButtons";
import PennyTips from "./components/PennyTips";
import RateResponseSection from "./components/RateResponseSection";
import TabAskAdvisor from "./components/TabAskAdvisor";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import { PENNY_FEEDBACK_FORM_URL } from "../../utils/constants";

import { isManager, isPortalSuperAdmin } from "../../utils/helpers/specialized";
import {
  createCompletion,
  sendQuestion,
} from "../../utils/requests/regularApp";
import { cleanFromHtmlTags } from "../../utils/helpers/general";

import SendIcon from "./images/send_message.svg";
import CloseOutlined from "../../icons/CloseOutlined";

const StyledModal = styled(Modal)`
  position: fixed;
  top: 10vh;
  right: 20vw;
  margin: unset;
  max-height: 80vh;

  &.ant-modal {
    .ant-modal-close {
      top: 8px;
      right: 8px;
    }

    .ant-modal-content {
      padding: 48px 22px 19px;
    }

    .ant-modal-body {
      height: 653px;
      max-height: 70vh;
      overflow: auto;
    }

    .ant-modal-footer {
      margin: 0;
    }
  }

  .response-image {
    width: 225px;
  }

  @media screen and (max-width: 778px) {
    top: 5px;
    right: 5px;
  }
`;

const StyledChatContainer = styled(MainContainer)`
  &.cs-main-container {
    display: flex;
    flex-direction: column;
    border: unset;
  }

  & .cs-chat-container .cs-message-input {
    border: unset;
  }

  & .cs-message-input__content-editor {
    color: #23292c;
    background-color: #f0f3f4;
    padding-right: 30px;
  }

  & .cs-message-input__content-editor-wrapper {
    min-height: 44px;
    height: fit-content;
    max-height: 99px;
    background-color: #f0f3f4;
    border-radius: 8px;
    margin: 0;
  }

  & .cs-message-input__tools {
    position: absolute;
    right: 7px;
    bottom: 10px;
  }

  & .cs-message--incoming .cs-message__content {
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: #1e1919;
    background-color: #e6f4ef;
    padding: 16px;
    border-radius: 12px 12px 12px 0;
    user-select: text;
  }

  & .cs-message--outgoing .cs-message__content {
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: #1e1919;
    background-color: #c9c8c8;
    padding: 16px;
    border-radius: 12px 12px 0 12px;
    user-select: text;
  }

  & .cs-button--send {
    align-self: center;
    width: 33px;
    height: 33px;
    color: #ffffff;
    background: ${props => props.send_message_button_background};
    padding: unset;
    border-radius: 50%;
    z-index: 1;
  }

  & .cs-button--send .fa-paper-plane {
    display: none;
  }

  & .cs-button--send::before {
    content: "";
    position: absolute;
    left: 2px;
    top: 4px;
    height: 24px;
    width: 24px;
    background: url(${SendIcon});
  }

  & .cs-message:not(:only-child) {
    margin-top: 16px;
  }

  & .cs-message-list__scroll-wrapper {
    padding-left: 0;
  }

  .response-image {
    width: 225px;
  }
`;

const SectionAskAdvice = ({ modalView = true, state, setState }) => {
  const { token } = theme.useToken();
  const [message, setMessage] = useState(state.pennyMessage ?? "");
  const [pennyTyping, setPennyTyping] = useState(false);
  const [showPennyFeedback] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: token.screenMDMax });

  useEffect(() => {
    setMessage(cleanFromHtmlTags(state.pennyMessage));

    if (
      state.openModalAskAdvice &&
      state.getAdvice?.usePenny &&
      state.getAdvice?.questionBody
    ) {
      sendMessageToPenny(state.getAdvice?.questionBody);
      setState(state => ({
        ...state,
        getAdvice: {
          ...state.getAdvice,
          questionBody: "",
        },
      }));
    }
  }, []);

  useEffect(() => {
    setMessage(cleanFromHtmlTags(state.pennyMessage));
  }, [state.pennyMessage]);

  useEffect(() => {
    setTimeout(
      () => document.getElementById("myLastMessage")?.scrollIntoView(),
      50
    );
  }, [state]);

  const handleAskAdvisorSubmit = values => {
    if (!values?.questionBody) {
      state.showWarning("Please type your question before send to advisor");
      return;
    }

    const requestBody = `
    Advice Question:
      Allow Data Access: ${values.giveAdvisorAccess ? "Yes" : "No"};
      Question: ${values.questionBody}.
    `;

    sendQuestion(requestBody)
      .then(() => {
        state.showSuccess("Message Sent Successfully");
        state.setKeyValue("openModalAskAdvice", false);
      })
      .catch(error => {
        state.showError(error.response?.data);
      });
  };

  const handleAskButtonClick = activeTabName => {
    state.setKeyValue("sectionAskAdviceActiveTab", activeTabName);
  };

  const handleMessageInputClick = event => {
    if (!isManager(state) && !state.getPreferenceValue("acceptedPennyTerms")) {
      event.target?.blur();
      state.setKeyValue("openModalPennyTerms", true);
      state.setKeyValue("abortPennyTermsHandler", true);
    }
  };

  const handleModalClose = () => {
    showPennyFeedback &&
      window.open(
        PENNY_FEEDBACK_FORM_URL,
        "mywin",
        `width=${
          window.screen.width > token.screenMDMax
            ? window.screen.width / 2
            : window.screen.width
        },height=${window.screen.height}`
      );
    state.closeModal("openModalAskAdvice");
  };

  const handleKeyDown = e => {
    if (e.key === "Enter" && message) {
      sendMessageToPenny(message);
    }
  };

  const handlePennyTipClick = message => {
    setMessage(message);

    if (!isManager(state) && !state.getPreferenceValue("acceptedPennyTerms")) {
      state.setKeyValue("openModalPennyTerms", true);
      state.setKeyValue("abortPennyTermsHandler", true);
    }
  };

  const parseResponseMessageImages = (responseMessage = "") => {
    const pattern = /\[image\]\s*(https?:\/\/[^\s]+)\s*\[\/image\]/g;

    const outputString = responseMessage.replace(
      pattern,
      '<a href="$1" target="_blank"><img alt="image" class="response-image" src="$1"/></a>'
    );

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: outputString,
        }}
      />
    );
  };

  const renderMessages = data =>
    data.map((responseData, index) => (
      <Message
        id={
          responseData.sender === "Me" &&
          (index + 2 === data.length || index + 1 === data.length) &&
          "myLastMessage"
        }
        key={index}
        model={{
          sender: responseData.sender,
          direction: responseData.sender === "Penny" ? "incoming" : "outgoing",
        }}
      >
        <Message.CustomContent>
          {parseResponseMessageImages(responseData.message)}
          {responseData.sender === "Penny" &&
            (isPortalSuperAdmin() ? (
              <div>
                <Button
                  onClick={() => {
                    state.setKeyValue(
                      "activeConversationId",
                      responseData.mongo_id
                    );
                    state.openModal("conversationModal");
                  }}
                  shape={"round"}
                  size={"small"}
                  style={{ marginTop: 8 }}
                  type={"primary"}
                >
                  Edit Answer
                </Button>
              </div>
            ) : (
              <div>
                <RateResponseSection
                  email={state._id}
                  responseData={responseData}
                  showSuccess={state.showSuccess}
                />
              </div>
            ))}
        </Message.CustomContent>
      </Message>
    ));

  const sendMessageToPenny = dirtyMessage => {
    if (typeof dirtyMessage !== "string") {
      return;
    }

    const cleanMessage = cleanFromHtmlTags(
      dirtyMessage.replaceAll("&nbsp;", "").replaceAll("  ", " ").trim()
    );

    setMessage("");
    setPennyTyping(true);

    setState(oldState => ({
      ...oldState,
      pennyMessages: [
        ...oldState.pennyMessages,
        {
          message: cleanMessage,
          sender: "Me",
        },
      ],
    }));

    createCompletion({
      email: state._id,
      originApp: isPortalSuperAdmin()
        ? "SuperAdmin"
        : isManager(state)
          ? "Advisor"
          : "Goals",
      question: cleanMessage,
      session: state.session,
    })
      .then(({ data }) => {
        setPennyTyping(false);
        setState(oldState => ({
          ...oldState,
          pennyMessage: "",
          pennyMessages: [
            ...oldState.pennyMessages,
            {
              ...data,
              message: data.response,
              sender: "Penny",
            },
          ],
        }));
      })
      .catch(error => {
        state.showError(error?.response?.data?.message);
        setPennyTyping(false);
      });
  };

  return modalView ? (
    <StyledModal
      closeIcon={<CloseOutlined />}
      footer={false}
      onCancel={handleModalClose}
      open={state.openModalAskAdvice}
      title={
        <Flex vertical>
          <span style={{ fontWeight: 500, color: token.colorCleverBlack }}>
            {isPortalSuperAdmin() && "ADMIN MODE"}
          </span>
          {isSmallScreen && (
            <ContainerHelpButtons
              handleButtonClick={handleAskButtonClick}
              isAdvisorButtonActive={
                state.sectionAskAdviceActiveTab === "advisor"
              }
              isAiButtonActive={state.sectionAskAdviceActiveTab !== "advisor"}
            />
          )}
        </Flex>
      }
      width={390}
    >
      {state.sectionAskAdviceActiveTab === "advisor" ? (
        <TabAskAdvisor isModalView={true} onSubmit={handleAskAdvisorSubmit} />
      ) : (
        <StyledChatContainer
          send_message_button_background={
            token.pennySendMessageButtonBackground
          }
        >
          {!state.pennyMessages.length && (
            <PennyTips handlePennyTipClick={handlePennyTipClick} />
          )}
          <ChatContainer>
            <MessageList
              typingIndicator={
                pennyTyping && <TypingIndicator content="Penny is typing" />
              }
            >
              {state.pennyMessages && renderMessages(state.pennyMessages)}
            </MessageList>
            <MessageInput
              attachButton={false}
              disabled={
                pennyTyping ||
                (!isManager(state) &&
                  !state.getPreferenceValue("acceptedPennyTerms"))
              }
              onChange={(innerHtml, textContent) => setMessage(textContent)}
              onClick={e => handleMessageInputClick(e)}
              onKeyDown={handleKeyDown}
              onSend={sendMessageToPenny}
              placeholder={"Type question…"}
              sendDisabled={!message}
              value={cleanFromHtmlTags(message)}
            />
          </ChatContainer>
        </StyledChatContainer>
      )}
    </StyledModal>
  ) : (
    <Flex style={{ height: "100%" }} vertical>
      <div style={{ marginBottom: 31 }}>
        <ContainerHelpButtons
          handleButtonClick={handleAskButtonClick}
          isAdvisorButtonActive={state.sectionAskAdviceActiveTab === "advisor"}
          isAiButtonActive={state.sectionAskAdviceActiveTab !== "advisor"}
        />
      </div>
      {state.sectionAskAdviceActiveTab === "advisor" ? (
        <TabAskAdvisor onSubmit={handleAskAdvisorSubmit} />
      ) : (
        <>
          {!state.pennyMessages.length && (
            <PennyTips handlePennyTipClick={handlePennyTipClick} />
          )}
          <StyledChatContainer
            send_message_button_background={
              token.pennySendMessageButtonBackground
            }
          >
            <ChatContainer>
              <MessageList
                typingIndicator={
                  pennyTyping && <TypingIndicator content="Penny is typing" />
                }
              >
                {state.pennyMessages && renderMessages(state.pennyMessages)}
              </MessageList>
              <MessageInput
                attachButton={false}
                disabled={
                  pennyTyping ||
                  (!isManager(state) &&
                    !state.getPreferenceValue("acceptedPennyTerms"))
                }
                onClick={e => handleMessageInputClick(e)}
                onChange={(innerHtml, textContent) => setMessage(textContent)}
                onKeyDown={handleKeyDown}
                onSend={sendMessageToPenny}
                placeholder={"Type question…"}
                sendDisabled={!message}
                value={cleanFromHtmlTags(message)}
              />
            </ChatContainer>
          </StyledChatContainer>
        </>
      )}
    </Flex>
  );
};

export default SectionAskAdvice;
