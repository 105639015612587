import { useContext, useState } from "react";
import State from "../../../../../../context/admin/State";
import { Button, Flex, Input, Table } from "antd";
import MenuActions from "../../../../../../../../atoms/MenuActions";

import {
  getCurrencyValueWithRoundSign,
  normalizePhoneInput,
} from "../../../../../../../../utils/helpers/general";

import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as CirclePlusGreen } from "../../../../../../images/circle_plus_green.svg";

const getColumns = ({
  handleDelete,
  handleReset,
  handleUpdate,
  searchedText,
}) => [
  {
    title: "Last name",
    dataIndex: "lastName",
    key: "lastName",
    filteredValue: [searchedText],
    onFilter: (value, record) =>
      String(record.firstName).toLowerCase().includes(value.toLowerCase()) ||
      String(record.lastName).toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
  },
  {
    title: "First name",
    dataIndex: "firstName",
    key: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    dataIndex: "email",
    key: "email",
    render: value => <a href={"mailto:" + value}>{value}</a>,
    sorter: (a, b) => a.email.localeCompare(b.email),
    title: "Email",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: value => normalizePhoneInput(value),
    sorter: (a, b) => a.phoneNumber - b.phoneNumber,
  },

  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    sorter: (a, b) => a.company.localeCompare(b.company),
  },
  {
    title: "AUM (Quarterly)",
    dataIndex: "aumQuarterly",
    key: "aumQuarterly",
    render: value => getCurrencyValueWithRoundSign(value),
    sorter: (a, b) => (a.aumQuarterly ?? 0) - (b.aumQuarterly ?? 0),
  },
  {
    title: "AUM (Annually)",
    dataIndex: "aumAnnually",
    key: "aumAnnually",
    render: value => getCurrencyValueWithRoundSign(value),
    sorter: (a, b) => (a.aumAnnually ?? 0) - (b.aumAnnually ?? 0),
  },
  {
    title: "",
    dataIndex: "actionsMenu",
    key: "actionsMenu",
    width: 40,
    className: "actions-menu-table-cell",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          handleDelete,
          handleReset,
          handleUpdate,
          userId: restValues._id,
        })}
      />
    ),
  },
];

const getItems = ({ handleDelete, handleReset, handleUpdate, userId }) => [
  {
    key: "1",
    label: <div onClick={() => handleReset(userId)}>Reset password </div>,
  },
  {
    key: "2",
    label: <div onClick={() => handleUpdate(userId)}>Edit User</div>,
  },
  {
    key: "3",
    label: <div onClick={() => handleDelete(userId)}>Delete User</div>,
  },
];

const TabUsers = () => {
  const [adminState] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  const [loading] = useState(false);

  const handleDelete = userId => {
    adminState.setKeyValue("activeUserId", userId);
    adminState.openModal("deleteUserModal");
  };

  const handleReset = userId => {
    adminState.setKeyValue("activeUserId", userId);
    adminState.openModal("userResetPasswordModal");
  };

  const handleUpdate = userId => {
    adminState.setKeyValue("activeUserId", userId);
    adminState.openModal("userModal");
  };

  return (
    <Flex vertical gap={20}>
      <Flex justify={"space-between"}>
        <Input
          className={"pointer"}
          onChange={event => setSearchedText(event.target.value)}
          placeholder="Search..."
          size="large"
          style={{ width: 236, borderRadius: 23 }}
          suffix={<SearchOutlined style={{ fontSize: 20, color: "#B1B1B1" }} />}
        />
        <Button
          shape={"round"}
          size={"small"}
          style={{ color: "#2E9642" }}
          type={"text"}
          onClick={() => adminState.openModal("userModal")}
        >
          <Flex align={"center"} gap={8}>
            <CirclePlusGreen />
            <span style={{ fontFamily: "Poppins, sans-serif" }}>
              Invite user
            </span>
          </Flex>
        </Button>
      </Flex>
      <Table
        columns={getColumns({
          handleDelete,
          handleReset,
          handleUpdate,
          searchedText,
        })}
        dataSource={adminState.user?.joined
          ?.map((user, index) => {
            const companyData = adminState.companies.find(
              company => company.name === user.company
            );

            return {
              ...user,
              aumAnnually: companyData?.aumAnnually ?? 0,
              aumQuarterly: companyData?.aumQuarterly ?? 0,
              key: index,
            };
          })
          ?.sort((a, b) => b.aumAnnually - a.aumAnnually)}
        loading={loading}
        pagination={{
          position: ["none", "bottomCenter"],
        }}
        size={"small"}
      />
    </Flex>
  );
};

export default TabUsers;
