import { useContext, useEffect, useState } from "react";
import State from "../../../../context/user/State";
import styled from "styled-components";
import { Button, Flex, Table } from "antd";

import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helpers/general";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";
import BroadridgeLogo from "./images/broadridge_logo.png";
import MemberStar from "./images/MemberStar";

const Container = styled(Flex)`
  background: #001141;
  padding: 16px;
  border-radius: 12px;

  .title {
    font-size: 20px;
    color: #ffffff;
  }

  &.view-all {
    background: #081c4f;
  }

  .cell-title {
    line-height: 20px;
    font-size: 18px;
    font-weight: 400;
    color: #4eabe9;
  }

  .cell-value {
    font-family:
      Roboto Condensed,
      sans-serif;
    font-size: 18px;
  }

  .mmi-member-disclaimer {
    font-size: 12px;
    font-weight: 500;
    color: #b9eafd;
  }

  .ant-table-wrapper table {
    border-spacing: 8px 0;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    background: transparent;
    color: #ffffff;
    border-bottom-color: transparent;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    line-height: 14px;
    border-bottom-color: #38456b;
  }

  .ant-table-wrapper .ant-table-thead > tr > th::before,
  .ant-table-wrapper .ant-table-thead > tr > td::before {
    display: none;
  }

  .ant-table-wrapper .ant-table {
    font-weight: bold;
    background: #001141;
    color: #ffffff;
  }

  &.view-all .ant-table-wrapper .ant-table {
    background: #081c4f;
  }

  .ant-table-wrapper .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: unset;
  }

  .ant-table-wrapper
    .ant-table-tbody
    .ant-table-row
    > .ant-table-cell-row-hover {
    background: transparent;
  }

  .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 0;
  }

  .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 8px 0;
  }
`;

const getColumns = handleChannelChange => [
  {
    dataIndex: "company",
    key: "company",
    render: (text, restValues) => (
      <span
        className={"cell-title pointer"}
        onClick={() => handleChannelChange(restValues._id)}
      >
        {restValues.isMmiMember && (
          <span style={{ marginRight: 4 }}>
            <MemberStar />
          </span>
        )}
        {text}
      </span>
    ),
    title: "",
    width: 150,
  },
  {
    align: "right",
    dataIndex: "aumQuarterly",
    key: "aumQuarterly",
    render: value => (
      <b className={"cell-value"}>{getCurrencyValueWithRoundSign(value)}</b>
    ),
    title: (
      <div>
        MAR ’24
        <br />
        AUM
      </div>
    ),
    width: 70,
  },
  {
    align: "right",
    dataIndex: "aumAnnually",
    key: "aumAnnually",
    render: value => (
      <b className={"cell-value"}>{getCurrencyValueWithRoundSign(value)}</b>
    ),
    title: (
      <div>
        2023
        <br />
        AUM
      </div>
    ),
    width: 70,
  },
];

const TableLeague = ({ handleChannelChange }) => {
  const [userState] = useContext(State);
  const [dataSource, setDataSource] = useState(userState.leagues?.slice(0, 3));
  const [isViewAll, setIsViewAll] = useState(false);

  useEffect(() => {
    if (isViewAll) {
      setDataSource(
        userState.leagues?.map((it, index) => ({
          ...it,
          key: index,
        }))
      );
    } else {
      setDataSource(
        userState.leagues?.slice(3, 6)?.map((it, index) => ({
          ...it,
          key: index,
        }))
      );
    }
  }, [isViewAll, userState]);

  const toggleViewAll = () => setIsViewAll(lastState => !lastState);

  return (
    <Container className={isViewAll && "view-all"} vertical>
      <div className={"title"}>League Table</div>
      <Table
        columns={getColumns(handleChannelChange)}
        dataSource={dataSource}
        loading={userState.loadingLeagues}
        pagination={false}
        size={"small"}
        style={{ marginBottom: 20 }}
      />
      {isViewAll ? (
        <>
          <Flex align={"center"} vertical>
            <span style={{ fontSize: 12, color: "#FFFFFF" }}>SPONSORED BY</span>
            <img
              alt={"Broadridge logo"}
              style={{ width: 178 }}
              src={BroadridgeLogo}
            />
          </Flex>
          <Flex align={"center"} justify={"space-between"}>
            <Button
              onClick={toggleViewAll}
              style={{
                fontSize: 16,
                color: "#4EABE9",
              }}
              type={"text"}
            >
              VIEW LESS
              <BlueArrow style={{ rotate: "180deg", marginLeft: 8 }} />
            </Button>
            <span className={"mmi-member-disclaimer"}>
              <MemberStar /> MMI Member
            </span>
          </Flex>
        </>
      ) : (
        <Flex align={"center"} justify={"space-between"}>
          <Button
            onClick={toggleViewAll}
            style={{
              fontSize: 16,
              color: "#4EABE9",
            }}
            type={"text"}
          >
            VIEW MORE
            <BlueArrow style={{ marginLeft: 8 }} />
          </Button>
          <span className={"mmi-member-disclaimer"}>
            <MemberStar /> MMI Member
          </span>
        </Flex>
      )}
    </Container>
  );
};

export default TableLeague;
