import { Typography } from "antd";
import styled from "styled-components";

const { Paragraph } = Typography;

const StyledDisclaimer = styled(Paragraph)`
  font-size: 12px;
  line-height: 16px;
  margin: 40px 0 0;
  color: #1b5568;
`;

const GuideDisclaimer = () => (
  <StyledDisclaimer>
    <b>Important</b>
    <br />
    The projections or other information generated by OnTrajectory® regarding
    the likelihood of various investment outcomes are hypothetical in nature, do
    ot reflect actual investment results and are not guarantees of future
    results.
  </StyledDisclaimer>
);

export default GuideDisclaimer;
