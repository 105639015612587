import { useContext } from "react";
import styled from "styled-components";
import State from "../../../../context/manager/State";
import { Button, Flex, theme } from "antd";

const Container = styled(Flex)`
  background: #4897d110;
  padding: 20px 28px;
  border-radius: 12px;

  .description {
    line-height: 19px;
    color: #516474;
  }
`;

const FeaturedVideoModalToggler = () => {
  const { token } = theme.useToken();
  const [adminState] = useContext(State);

  return (
    <Container gap={25}>
      <span className={"description"}>
        Advisor Dashboard
        <br /> video widget settings.
      </span>
      <Button
        type="secondary"
        shape="round"
        size="small"
        style={{
          width: 118,
          background: token.colorSecondaryButton,
          color: token.colorSecondaryButtonText,
        }}
        onClick={() => adminState.openModal("openModalFeaturedVideo")}
      >
        Configure
      </Button>
    </Container>
  );
};

export default FeaturedVideoModalToggler;
