import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { theme } from "antd";
import ChartSkeleton from "../../atoms/ChartSkeleton";

import { getGrowthChartConfig } from "../../utils/charts";
import { DEFAULT_BIRTH_YEAR } from "../../utils/constants";

const ChartComparisonGrowth = ({
  chartRef,
  loading,
  productsLiveAssessment,
  handleLineMouseOut,
  handleLineHover,
  height,
  productsList,
  proposalData,
  viewMode,
}) => {
  const { token } = theme.useToken();
  const [growthChartSeries, setGrowthChartSeries] = useState([]);
  const localChartRef = useRef(null);

  useEffect(() => {
    if (viewMode === "pdf") {
      const storedData = localStorage.getItem("growthChartSvg");
      if (!storedData) {
        const interval = setInterval(() => {
          const growthChartSvg = (
            chartRef ?? localChartRef
          )?.current?.chart?.getSVG();

          growthChartSvg &&
            localStorage.setItem("growthChartSvg", growthChartSvg);
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [viewMode]);

  useEffect(() => {
    if (productsLiveAssessment) {
      const series = [];

      productsLiveAssessment.map((it, index) => {
        series.push({
          color: it.color || token.comparisonProductsColors[index],
          dashStyle: it.shape,
          data: it.grandTotalColumn,
          lineWidth: 4,
          marker: {
            enabled: false,
          },
          name: productsList.find(product => product._id === it.productId)
            ?.name,
          zoneAxis: "x",
        });

        if (it.gFutureProgress?.length) {
          series.push({
            color: "grey",
            dashStyle: "Dash",
            data: it.gFutureProgress,
            lineWidth: 4,
            name: "Future progress",
          });
        }
      });

      if (proposalData?.targetAmount && productsLiveAssessment?.length) {
        series.push({
          name: "Target Amount",
          color: "#FF6D38",
          data: [
            [
              productsLiveAssessment[0].grandTotalColumn[
                productsLiveAssessment[0].grandTotalColumn.length - 1
              ][0],
              proposalData.targetAmount,
            ],
          ],
          marker: {
            enabled: true,
            symbol: "diamond",
          },
        });
      }

      setGrowthChartSeries(series);
    }
  }, [proposalData, productsLiveAssessment]);

  return (
    <ChartSkeleton loading={loading} height={height}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...getGrowthChartConfig({
            birthYear:
              proposalData?.birthYear ??
              (proposalData?.currentAge
                ? dayjs().format("YYYY") - proposalData.currentAge
                : DEFAULT_BIRTH_YEAR),
            height,
          }),
          plotOptions: {
            series: {
              dashStyle: "Dash",
              marker: {
                enabled: false,
              },
              events: {
                mouseOver: handleLineHover,
                mouseOut: handleLineMouseOut,
              },
            },
            line: {
              marker: {
                enabled: false,
              },
            },
          },
          series: growthChartSeries,
        }}
        ref={chartRef ?? localChartRef}
      />
    </ChartSkeleton>
  );
};

export default ChartComparisonGrowth;
