import styled from "styled-components";
import { Flex, Menu, theme } from "antd";

import { ReactComponent as GetAdviceIcon } from "./images/get_advice.svg";
import { ReactComponent as PennyIcon } from "./images/penny.svg";

const StyledMenu = styled(Menu)`
  &.ant-menu {
    position: relative;
    width: 100%;
    background: transparent;
    margin-top: 28px;

    &.ant-menu-light.ant-menu-root.ant-menu-vertical {
      border-right: unset;
    }

    .ant-menu-item {
      width: 100%;
      height: fit-content;
      line-height: 19px;
      background: transparent;
      color: ${({ token }) => token.sidebarIconColor};
      padding: 0;
      margin: 0;
      border-radius: unset;
      text-align: center;

      &:not(:first-of-type) {
        margin-top: 28px;
      }
    }
  }
`;

const MenuSidebarSub = ({ state }) => {
  const { token } = theme.useToken();

  const handlePennyButtonClick = () => {
    if (state.getPreferenceValue("acceptedPennyTerms")) {
      state.setKeyValue("openModalAskAdvice", true);
      state.setKeyValue("sectionAskAdviceActiveTab", "penny");
    } else {
      state.setKeyValue("openModalPennyTerms", true);
    }
  };

  const handleGetAdviceButtonClick = () => {
    state.setKeyValue("openModalAskAdvice", true);
    state.setKeyValue("sectionAskAdviceActiveTab", "advisor");
  };

  return (
    <StyledMenu
      items={[
        {
          key: 0,
          label: (
            <Flex
              align={"center"}
              justify={"center"}
              onClick={handlePennyButtonClick}
              vertical
            >
              <PennyIcon />
              Penny
            </Flex>
          ),
        },
        {
          key: 1,
          label: (
            <Flex
              align={"center"}
              justify={"center"}
              onClick={handleGetAdviceButtonClick}
              vertical
            >
              <GetAdviceIcon />
              Get
              <br />
              Advice
            </Flex>
          ),
        },
      ]}
      token={token}
    />
  );
};

export default MenuSidebarSub;
