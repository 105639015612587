import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import State from "../../../../context/user/State";
import { Button, Divider, Modal, theme, Typography } from "antd";
import RowUpdateProgress from "./components/RowUpdateProgress";

import { getGoalIdByGoalName } from "../../../../utils/helpers/specialized";
import { getSavedProgressValuesByGoalId } from "../../helpers";

import Close from "../../../../icons/Close";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 21px 20px 10px 32px;
  }

  & .ant-modal-body {
    padding: 24px 2px 2px;
  }

  &.ant-modal .ant-modal-footer {
    text-align: start;
  }

  & .ant-modal-body {
    height: 463px;
    overflow: auto;
  }
`;

const ModalUpdateProgress = ({
  goalName,
  progressValues,
  saveProgress,
  setProgressValues,
}) => {
  const { token } = theme.useToken();
  const [userState] = useContext(State);
  const [updatedProgressValues, setUpdatedProgressValues] =
    useState(progressValues);
  const [newProgressItemData, setNewProgressItemData] = useState({});
  const goalId = getGoalIdByGoalName(
    userState.getPreferenceValue("productMap"),
    goalName
  );

  useEffect(() => {
    setUpdatedProgressValues(progressValues);
  }, [progressValues]);

  const handleCancel = () => {
    setProgressValues(
      getSavedProgressValuesByGoalId(goalId, userState.productScenariosMap)
    );
    userState.closeModal("updateProgressModal");
  };

  const handleSave = () => {
    saveProgress(
      newProgressItemData.date?.$date && newProgressItemData.actualTotal
        ? [...updatedProgressValues, newProgressItemData]
        : updatedProgressValues,
      () => setNewProgressItemData({})
    );
  };

  return (
    <StyledModal
      centered
      className="update-progress-menu"
      closeIcon={<Close />}
      destroyOnClose
      footer={
        <>
          <Button
            id="updateProgressModalSaveButton"
            type="primary"
            shape="round"
            block
            onClick={handleSave}
            style={{ maxWidth: 280 }}
          >
            Save Progress
          </Button>
          <Button
            id="updateProgressModalCancelButton"
            type="text"
            shape="round"
            block
            onClick={handleCancel}
            style={{ maxWidth: 280 }}
          >
            Cancel
          </Button>
        </>
      }
      onCancel={handleCancel}
      open={userState.updateProgressModal}
      title="Update Progress"
      width={348}
    >
      <RowUpdateProgress
        data={newProgressItemData}
        goalName={goalName}
        setNewProgressItemData={setNewProgressItemData}
        showLabels
        showRemoveButton={false}
      />
      <Typography.Text>History</Typography.Text>
      <Divider
        style={{ margin: "4px 0 18px", borderTopColor: token.colorGrey2 }}
      />
      {updatedProgressValues
        ?.sort((a, b) => dayjs(b.date.$date) - dayjs(a.date.$date))
        ?.map((it, index) => (
          <RowUpdateProgress
            key={index}
            goalName={goalName}
            data={it}
            showLabels={index === 0}
            setProgressValues={setUpdatedProgressValues}
          />
        )) ?? ""}
    </StyledModal>
  );
};

export default ModalUpdateProgress;
