import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import State from "../../../../context/admin/State";
import { Button, Flex, Input, Switch, Upload } from "antd";
import ModalPrimary from "../ModalPrimary";
import InputContainer from "../../../../../../atoms/InputContainer";

import {
  addSponsor,
  updateSponsor,
  uploadFile,
} from "../../../../requests/global";
import { MAX_FEATURED_SPONSORS_AMOUNT } from "../../../../constants";

import { ReactComponent as UploadIcon } from "../../../../images/upload.svg";

const StyledSwitch = styled(Switch)`
  &.ant-switch.ant-switch-checked {
    background: #00aeef;
  }
`;

const defaultSponsorData = {
  featured: false,
};

const ModalSponsor = ({ open }) => {
  const [adminState] = useContext(State);
  const [sponsorData, setSponsorData] = useState({});
  const [loading, setLoading] = useState(false);
  const isEditSponsorView = !!adminState.activeSponsorId;

  useEffect(() => {
    if (open) {
      if (adminState.activeSponsorId) {
        setSponsorData(
          adminState.sponsors.find(it => it._id === adminState.activeSponsorId)
        );
      } else {
        setSponsorData(defaultSponsorData);
      }
    }
  }, [open]);

  const updateSponsorData = (key, value) =>
    setSponsorData(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const handleClose = () => {
    adminState.setKeyValue("activeSponsorId", null);
    adminState.closeModal("sponsorModal");
    setSponsorData({});
  };

  const handleSave = () => {
    if (
      sponsorData.featured &&
      adminState.sponsors.filter(it => it.featured)?.length >=
        MAX_FEATURED_SPONSORS_AMOUNT
    ) {
      adminState.showWarning(
        `A maximum of ${MAX_FEATURED_SPONSORS_AMOUNT} sponsors can be featured at once. Please disable a sponsor.`
      );
      return;
    }

    setLoading(true);

    if (isEditSponsorView) {
      if (sponsorData.file) {
        // added new file to the form
        uploadFile({
          file: sponsorData.file,
          folderName: "sponsors",
        }).then(uploadedFile => {
          updateSponsor({
            sponsorId: adminState.activeSponsorId,
            payloadData: {
              ...sponsorData,
              logo: {
                filename: uploadedFile.filename,
                key: uploadedFile.key,
                url: uploadedFile.url,
              },
            },
          })
            .then(() => {
              adminState.reFetch.sponsors().then(() => {
                adminState.showSuccess("Sponsor data updated.");
                handleClose();
              });
            })
            .finally(() => setLoading(false));
        });
      } else {
        updateSponsor({
          sponsorId: adminState.activeSponsorId,
          payloadData: sponsorData,
        })
          .then(() => {
            adminState.reFetch.sponsors().then(() => {
              adminState.showSuccess("Sponsor data updated.");
              handleClose();
            });
          })
          .finally(() => setLoading(false));
      }
    } else {
      uploadFile({
        file: sponsorData.file,
        folderName: "sponsors",
      })
        .then(uploadedFile => {
          addSponsor({
            ...sponsorData,
            logo: {
              filename: uploadedFile.filename,
              key: uploadedFile.key,
              url: uploadedFile.url,
            },
          })
            .then(() => {
              adminState.reFetch.sponsors().then(() => {
                adminState.showSuccess("Sponsor added.");
                handleClose();
              });
            })
            .catch(error => {
              setLoading(false);
              adminState.showWarning(
                (error?.response?.data?.error ?? [])[0].message
              );
            });
        })
        .catch(error => {
          adminState.showWarning(error?.response?.data?.message);
          //TODO remove uploaded file after 'add new sponsor' error
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <ModalPrimary
      destroyOnClose={true}
      footer={
        <Flex justify={"space-between"}>
          <Button
            onClick={handleClose}
            shape={"round"}
            size={"large"}
            type={"text"}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleSave}
            shape={"round"}
            size={"large"}
            style={{ width: 134, background: "#001141", color: "#FFFFFF" }}
          >
            Save
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={isEditSponsorView ? "Edit Sponsor" : "Add New Sponsor"}
      width={492}
    >
      <Flex gap={16} vertical>
        <Flex gap={16}>
          <InputContainer labelColor={"#8396A6"} labelText={"Sponsor Name"}>
            <Input
              onChange={e => updateSponsorData("name", e.target.value)}
              value={sponsorData.name}
            />
          </InputContainer>
          <InputContainer labelColor={"#8396A6"} labelText={"URL"}>
            <Input
              onChange={e => updateSponsorData("url", e.target.value)}
              value={sponsorData.url}
            />
          </InputContainer>
        </Flex>
        <Flex gap={16}>
          <InputContainer labelColor={"#8396A6"} labelText={"Category"}>
            <Input
              onChange={e => updateSponsorData("category", e.target.value)}
              value={sponsorData.category}
            />
          </InputContainer>
          <InputContainer labelColor={"#8396A6"} labelText={"MMI Referral"}>
            <Input
              onChange={e => updateSponsorData("referral", e.target.value)}
              value={sponsorData.referral}
            />
          </InputContainer>
        </Flex>
        <InputContainer labelColor={"#8396A6"} labelText={"Featured"}>
          <StyledSwitch
            size={"small"}
            onChange={checked => updateSponsorData("featured", checked)}
            checked={sponsorData.featured}
          />
        </InputContainer>
        <InputContainer labelColor={"#8396A6"} labelText={"Upload Logo"}>
          <Upload.Dragger
            name={"file"}
            maxCount={1}
            beforeUpload={() => false}
            onChange={file => updateSponsorData("file", file)}
            onRemove={() => updateSponsorData("file", null)}
            style={{ paddingLeft: 30 }}
          >
            <Flex
              align={"center"}
              justify={"center"}
              gap={15}
              style={{ height: 70 }}
            >
              <span className="ant-upload-drag-icon">
                <UploadIcon />
              </span>
              <span className="ant-upload-text" style={{ color: "#105DAE" }}>
                Drag here or <u>Browse</u>
              </span>
            </Flex>
          </Upload.Dragger>
        </InputContainer>
      </Flex>
    </ModalPrimary>
  );
};

export default ModalSponsor;
