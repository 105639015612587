import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ConfigProvider, Flex, Menu, theme } from "antd";

const StyledMenu = styled(Menu)`
  &.ant-menu {
    width: 100%;
    background: transparent;

    &.ant-menu-light.ant-menu-root.ant-menu-vertical {
      border-right: unset;
    }

    .ant-menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 35px;
      height: fit-content;
      line-height: 19px;
      background: transparent;
      color: ${({ token }) => token.sidebarIconColor} !important;
      padding: 0;
      margin: 0;
      border-radius: unset;

      .ant-menu-title-content {
        padding: 9px;
        width: 100%;
      }

      &.ant-menu-item-active .ant-menu-title-content {
        background: ${({ token }) => token.sidebarMenuItemHoverBackground};
        border-radius: 12px;
      }

      &:not(:first-of-type) {
        margin-top: 8px;
      }

      &.ant-menu-item-selected {
        color: ${({ token }) => token.sidebarIconColorActive} !important;

        &::before {
          content: "";
          position: absolute;
          right: 0;
          width: 6px;
          height: 100%;
          border-radius: 3px 0 0 3px;
          background: #ffffff;
        }
      }
    }
  }
`;

const MenuSidebar = ({ itemsLayoutVertical, routes }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const getMenuItems = () =>
    routes
      ?.filter(it => it.text)
      ?.map(({ Icon, path, text, disabled }) => ({
        disabled,
        key: path,
        label: itemsLayoutVertical ? (
          <Flex align={"center"} gap={4} justify={"center"} vertical>
            <Icon />
            {text}
          </Flex>
        ) : (
          <Flex align={"center"} gap={14} justify={"start"}>
            <div style={{ textAlign: "center", width: 24 }}>
              <Icon />
            </div>
            {text}
          </Flex>
        ),
        onClick: () => !disabled && navigate(path),
      }));

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemHoverBg: "transparent",
            itemActiveBg: "transparent",
          },
        },
      }}
    >
      <StyledMenu
        selectedKeys={[location.pathname]}
        items={getMenuItems()}
        token={token}
      />
    </ConfigProvider>
  );
};

export default MenuSidebar;
