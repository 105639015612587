import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Button, Col, Divider, Flex, Row, theme, Typography } from "antd";
import GaugeSection from "../../../../../proposal/components/ProposalGoalDashboard/components/GaugeSection";
import CardProduct from "../../../../../../molecules/CardProduct";
import ChartComparisonGrowth from "../../../../../../molecules/ChartComparisonGrowth";
import ChartIncomeWithTypes from "../../../../../../molecules/ChartIncomeWithTypes";
import Header from "../Header";
import InvestmentAssumptions from "../../../../../proposal/components/ProposalGoalDashboard/components/InvestmentAssumptions";
import SectionExploreGrowthTypes from "../SectionExploreGrowthTypes";
import SliderGoalFunded from "../../../../components/SliderGoalFunded";

import { getGoalTypeIcon } from "../../../../helpers";
import {
  getGoalProgressLabel,
  isLockedByAdminView,
} from "../../../../../../utils/helpers/specialized";

import { LeftOutlined } from "@ant-design/icons";

const { Text } = Typography;

const Container = styled(Flex)`
  .section-title {
    line-height: 32px;
    font-weight: 400;
    font-size: 18px;
    color: #8396a6;
    margin: 0;
  }

  & .ant-divider-horizontal {
    border-top: 3px solid #e7ebee;
    margin: 10px 0 20px;
  }
`;

const WorkspaceGoal = ({
  calculationsData,
  goalData,
  handleCardClick,
  loading,
  saveGoalSettings,
  setCompareMode,
  setIsDeleteModalOpen,
  simulationsData,
  state,
}) => {
  const { token } = theme.useToken();
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });
  const navigate = useNavigate();

  const handleGoalDataChange = useDebouncedCallback((key, value) => {
    saveGoalSettings({
      ...goalData,
      [key]: value,
    });
  }, 500);

  const DesktopView = () => (
    <Container gap={30} vertical>
      <Header
        goalData={goalData}
        progressLabel={getGoalProgressLabel({ goalData, calculationsData })}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        state={state}
      />
      <Flex className={"body"} gap={48}>
        <Col flex={"174px"} className="goal-details-section">
          <h3 className={"section-title"}>Goal Settings</h3>
          <Divider />
          <SliderGoalFunded
            goalData={goalData}
            calculationsData={calculationsData}
            style={{ marginBottom: 16 }}
          />
          <InvestmentAssumptions
            handleProposalDataChange={handleGoalDataChange}
            managerAccess={state.managerAccess}
            openGoalSettingsModal={() => state.openModal("goalSettingsModal")}
            organization={"goals"}
            proposalData={goalData}
          />
        </Col>
        <Col flex={"auto"}>
          <Flex vertical>
            <Flex align={"center"} justify={"space-between"}>
              <h3 className={"section-title"}>Goal Details</h3>
              <Button
                disabled={
                  state.loading ||
                  isLockedByAdminView({ managerAccess: state.managerAccess })
                }
                id={"openUpdateProgressModalButton"}
                shape={"round"}
                size={"small"}
                style={{
                  width: 174,
                  background: token.pageGoalUpdateProgressButtonBackground,
                  color: token.pageGoalUpdateProgressButtonColor,
                }}
                onClick={() => state.openModal("updateProgressModal")}
              >
                Update Progress
              </Button>
            </Flex>
            <Divider />
            <Row gutter={20} wrap={false} style={{ width: "100%" }}>
              <Col flex={"auto"}>
                <Flex gap={10} vertical>
                  <ChartComparisonGrowth
                    height={233}
                    loading={loading}
                    productsList={state.productsList}
                    productsLiveAssessment={
                      calculationsData && [
                        {
                          ...calculationsData,
                          productId: state.selectedProduct,
                        },
                      ]
                    }
                    proposalData={{
                      ...goalData,
                      productId: state.selectedProduct,
                    }}
                  />
                  <ChartIncomeWithTypes
                    loading={loading}
                    productsList={state.productsList}
                    productsLiveAssessment={
                      calculationsData && [
                        {
                          ...calculationsData,
                          productId: state.selectedProduct,
                        },
                      ]
                    }
                    productData={goalData}
                    showLegendAmounts={true}
                  />
                </Flex>
              </Col>
              <Col flex={"170px"}>
                <GaugeSection
                  investmentObjective={goalData?.investmentObjective}
                  loading={loading}
                  productLiveSimulation={simulationsData}
                />
              </Col>
            </Row>
          </Flex>
          <SectionExploreGrowthTypes
            handleCardClick={handleCardClick}
            setCompareMode={setCompareMode}
            state={state}
          />
        </Col>
      </Flex>
    </Container>
  );

  const MobileView = () => (
    <Flex gap={15} vertical>
      <Flex justify={"space-between"}>
        <Button
          onClick={() => navigate(-1)}
          shape={"round"}
          style={{ width: 139, fontSize: 16 }}
          type={"primary"}
        >
          <Flex align={"center"} gap={8} justify={"center"}>
            <LeftOutlined style={{ fontSize: 20 }} />
            <span>All Goals</span>
          </Flex>
        </Button>
        <Button
          disabled={
            state.loading ||
            isLockedByAdminView({ managerAccess: state.managerAccess })
          }
          onClick={() => state.openModal("updateProgressModal")}
          shape={"round"}
          style={{
            width: 157,
            fontSize: 16,
            background: token.pageGoalUpdateProgressButtonBackground,
            color: token.pageGoalUpdateProgressButtonColor,
          }}
        >
          Update Progress
        </Button>
      </Flex>
      <Flex gap={13}>
        {getGoalTypeIcon(goalData.goalType)}
        <Flex vertical>
          <Text style={{ fontSize: 23, color: token.colorNavy }} strong>
            {goalData.goalName ?? goalData.productName}
          </Text>
          <Text style={{ fontSize: 15, color: token.colorNavy }}>
            {getGoalProgressLabel({ goalData, calculationsData })}
          </Text>
        </Flex>
      </Flex>
      <ChartComparisonGrowth
        height={233}
        loading={loading}
        productsList={state.productsList}
        productsLiveAssessment={
          calculationsData && [
            {
              ...calculationsData,
              productId: state.selectedProduct,
            },
          ]
        }
        proposalData={{
          ...goalData,
          productId: state.selectedProduct,
        }}
      />
      <ChartIncomeWithTypes
        loading={loading}
        productsList={state.productsList}
        productsLiveAssessment={
          calculationsData && [
            {
              ...calculationsData,
              productId: state.selectedProduct,
            },
          ]
        }
        productData={goalData}
        showLegendAmounts={true}
      />
      <div
        style={{
          borderRadius: 8,
          boxShadow: isMobile ? "0 3px 6px #00000029" : "",
        }}
      >
        <GaugeSection
          investmentObjective={goalData?.investmentObjective}
          loading={loading}
          productLiveSimulation={simulationsData}
        />
      </div>
      <SliderGoalFunded
        goalData={goalData}
        calculationsData={calculationsData}
        style={{ marginBottom: 16 }}
      />
      <Button
        shape={"round"}
        size={"large"}
        style={{ fontSize: 16 }}
        type={"primary"}
        onClick={() => state.openModal("goalSettingsModal")}
      >
        Goal Settings
      </Button>
      <CardProduct
        handleCardClick={handleCardClick}
        labels={[
          state.productsList?.find(it => it._id === state.selectedProduct)
            ?.investmentPhilosophy,
        ]}
        product={state.productsList?.find(
          it => it._id === state.selectedProduct
        )}
        width={"100%"}
      />
      <Button
        shape={"round"}
        size={"large"}
        type={"primary"}
        onClick={() => state.openModal("otherGrowthTypesModal")}
      >
        Other Growth Types
      </Button>
      <Button
        shape={"round"}
        size={"large"}
        type={"primary"}
        onClick={() => setCompareMode(true)}
      >
        Compare Growth Types
      </Button>
    </Flex>
  );

  return isMobile ? <MobileView /> : <DesktopView />;
};

export default WorkspaceGoal;
