import { useContext } from "react";
import dayjs from "dayjs";
import State from "../../context/manager/State";
import { Button, Flex, Form, Input, InputNumber, Modal, theme } from "antd";

import { addProspect, updateManagedUser } from "../../utils/requests/manager";
import { selectValue } from "../../utils/helpers/general";
import {
  isAdminView,
  isOrganizationOneascent,
} from "../../utils/helpers/specialized";

import Close from "../../icons/Close";

const ModalAddProspect = ({ handleClose, hideBirtYear, open }) => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);
  const instanceLabel = isOrganizationOneascent(managerState.organization?.name)
    ? "Prospect"
    : "Lead";

  const getInitialValues = () => {
    if (managerState.activeEmail) {
      const prospectData = managerState
        .getProspectUsers()
        .find(it => it.email === managerState.activeEmail);

      return {
        ...prospectData,
        birthYear:
          prospectData?.birthYear ??
          prospectData?.valueMap?.prospectObjective?.birthYear,
        oldEmail: prospectData?.email,
      };
    } else {
      return {};
    }
  };

  const onFinish = values => {
    managerState.showLoader();

    if (managerState.activeEmail) {
      updateManagedUser({
        ...values,
        oldEmail: managerState.activeEmail,
      })
        .then(response => {
          managerState.closeModal("addProspectModal");
          managerState.hideLoader();
          managerState.showSuccess(response.data);
          managerState.setAdminData();
        })
        .catch(({ response }) => {
          managerState.showError(response.data);
          managerState.hideLoader();
        });
    } else {
      addProspect(values)
        .then(response => {
          managerState.closeModal("addProspectModal");
          managerState.hideLoader();
          managerState.showSuccess(response.data);
          managerState.setAdminData();
        })
        .catch(({ response }) => {
          managerState.showError(response.data);
          managerState.hideLoader();
        });
    }
  };

  return (
    <Modal
      className={"add-prospect-modal"}
      title={
        managerState.activeEmail
          ? `Edit ${instanceLabel}`
          : `Add ${instanceLabel}`
      }
      open={open}
      onCancel={handleClose}
      closeIcon={<Close />}
      footer={
        <Flex justify={"space-between"}>
          <Button onClick={handleClose} shape={"round"} type={"text"}>
            Cancel
          </Button>
          <Button
            disabled={managerState.loading || isAdminView()}
            form={"addProspectForm"}
            htmlType={"submit"}
            shape={"round"}
            style={{
              color: token.colorPrimaryButtonText,
              backgroundColor: token.colorPrimaryButton,
            }}
            type={"primary"}
          >
            {managerState.activeEmail ? "Save Changes" : `Add ${instanceLabel}`}
          </Button>
        </Flex>
      }
      destroyOnClose={true}
    >
      <Form
        id={"addProspectForm"}
        initialValues={getInitialValues()}
        layout={"vertical"}
        onFinish={onFinish}
        style={{ padding: 40 }}
      >
        <Flex align={"end"} gap={20}>
          <Form.Item
            label={"Name"}
            labelCol={{ span: 24 }}
            name={"firstName"}
            rules={[
              {
                required: true,
                message: "Please enter First Name",
              },
            ]}
          >
            <Input
              onClick={selectValue}
              placeholder={"First Name"}
              size={"large"}
              value={"test"}
            />
          </Form.Item>
          <Form.Item
            label={""}
            labelCol={{ span: 24 }}
            name={"lastName"}
            rules={[
              {
                required: true,
                message: "Please enter Last Name",
              },
            ]}
          >
            <Input
              placeholder={"Last Name"}
              size={"large"}
              onClick={selectValue}
            />
          </Form.Item>
        </Flex>
        <Flex align={"start"} gap={20}>
          <Form.Item
            label={"Email"}
            labelCol={{ span: 24 }}
            name={"email"}
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input
              id={"userEmail"}
              onClick={selectValue}
              placeholder={"you@email.com"}
              size={"large"}
            />
          </Form.Item>
          {!hideBirtYear && (
            <Form.Item
              label={"Birth Year"}
              labelCol={{ span: 24 }}
              name={"birthYear"}
              rules={[
                {
                  type: "number",
                  min: 1940,
                  max: dayjs().format("YYYY") - 5,
                },
              ]}
            >
              <InputNumber
                id={"userBirthYear"}
                onClick={selectValue}
                placeholder={"YYYY"}
                size={"large"}
              />
            </Form.Item>
          )}
        </Flex>
      </Form>
    </Modal>
  );
};

export default ModalAddProspect;
