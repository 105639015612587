import styled from "styled-components";
import { Button, Flex, Modal } from "antd";
import CardSponsor from "../CardSponsor";

import { ReactComponent as CloseIcon } from "../../../../images/blue_light_close.svg";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    background: linear-gradient(180deg, #001141 0%, #081c4f 100%);
    border-radius: 12px;
  }

  &.ant-modal .ant-modal-header {
    background: transparent;
  }

  .ant-modal-title {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 48px;
  }

  &.ant-modal .ant-modal-footer {
    margin-top: 60px;
  }

  .label {
    line-height: 16px;
    font-size: 12px;
    color: #a1aed0;
  }

  .value {
    line-height: 16px;
    font-size: 16px;
    color: #ffffff;
  }
`;

const StyledButton = styled(Button)`
  height: 29px;
  border-color: transparent;
  padding: 0;
`;

const ModalSponsorDetails = ({ openModal, sponsorData, open, handleClose }) => {
  const handleSeeAllSponsors = () => {
    handleClose();
    openModal("sponsorsListModal");
  };

  return (
    <StyledModal
      closeIcon={<CloseIcon />}
      footer={
        <Flex gap={8} vertical>
          <StyledButton
            block
            onClick={handleSeeAllSponsors}
            shape={"round"}
            style={{ background: "#4EABE9", color: "#FFFFFF" }}
          >
            See all sponsors
          </StyledButton>
          <StyledButton
            block
            onClick={handleClose}
            shape={"round"}
            style={{ color: "#105DAE" }}
            type={"text"}
          >
            Close
          </StyledButton>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title={"Sponsor Detail"}
      width={342}
    >
      <CardSponsor sponsorData={sponsorData} />
    </StyledModal>
  );
};

export default ModalSponsorDetails;
