import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Flex, Modal, Radio, Row, Slider, theme } from "antd";

import {
  calculateFinancialRiskToleranceMatrix,
  financialRiskToleranceCalculationData,
} from "../../constants";

import Close from "../../../../../../icons/Close";
import sliderImage from "../../images/investment_objective_slider.svg";

const StyledSlider = styled(Slider)`
  &.ant-slider-horizontal {
    margin: 0 0 8px;
  }

  &.ant-slider .ant-slider-handle {
    z-index: 1;

    &::before {
      background: url("${sliderImage}") center;
      height: 56px;
      left: -25px;
      top: -17px;
      width: 48px;
    }
  }

  &.ant-slider .ant-slider-handle::after {
    display: none;
  }
`;

const RiskToleranceLabel = styled.span`
  color: #354a5f;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  visibility: hidden;
  width: 103px;

  &.active {
    visibility: visible;
  }
`;

const SectionRiskSelection = ({
  ButtonsContainer,
  proposalData,
  updateProposalData,
}) => {
  const { token } = theme.useToken();
  const [sliderValue, setSliderValue] = useState();
  const [
    sliderValueBasedOnRecommendationCalc,
    setSliderValueBasedOnRecommendationCalc,
  ] = useState();
  const [riskToleranceRecommendationName, setRiskToleranceRecommendationName] =
    useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    calculateFinancialRiskToleranceRecommendation();
  }, []);

  useEffect(() => {
    updateProposalData("riskTolerance", riskToleranceRecommendationName);
  }, [riskToleranceRecommendationName]);

  useEffect(() => {
    setSliderValue(sliderValueBasedOnRecommendationCalc);
  }, [sliderValueBasedOnRecommendationCalc]);

  const getChartCalculations = value => {
    if (value < 10) {
      return [5, "preservation"];
    } else if (value < 20) {
      return [15, "conservative"];
    } else if (value < 30) {
      return [25, "moderate"];
    } else if (value < 40) {
      return [35, "growth"];
    } else {
      return [45, "equity"];
    }
  };

  const calculateFinancialRiskToleranceRecommendation = () => {
    const currentAgeRange =
      financialRiskToleranceCalculationData.currentAgeRange[
        proposalData.currentAgeRange
      ];
    const beginWithdrawal =
      financialRiskToleranceCalculationData.beginWithdrawal[
        proposalData.beginWithdrawal
      ];
    const incomeExpectations =
      financialRiskToleranceCalculationData.incomeExpectations[
        proposalData.incomeExpectations
      ];

    const investmentKnowledge =
      financialRiskToleranceCalculationData.investmentKnowledge[
        proposalData.investmentKnowledge
      ];
    const decisionInfluence =
      financialRiskToleranceCalculationData.decisionInfluence[
        proposalData.decisionInfluence
      ];
    const volatilityTolerance =
      financialRiskToleranceCalculationData.volatilityTolerance[
        proposalData.volatilityTolerance
      ];
    const decliningMarketWithholdingPeriod =
      financialRiskToleranceCalculationData.decliningMarketWithholdingPeriod[
        proposalData.decliningMarketWithholdingPeriod
      ];

    const totalPart1 = currentAgeRange + beginWithdrawal + incomeExpectations;
    const totalPart2 =
      investmentKnowledge +
      decisionInfluence +
      volatilityTolerance +
      decliningMarketWithholdingPeriod;

    let matrixPositionY, matrixPositionX;

    if (totalPart1 <= 5) {
      matrixPositionY = 0;
    } else if (totalPart1 <= 10) {
      matrixPositionY = 1;
    } else if (totalPart1 <= 16) {
      matrixPositionY = 2;
    } else if (totalPart1 <= 21) {
      matrixPositionY = 3;
    } else if (totalPart1 <= 25) {
      matrixPositionY = 4;
    } else {
      matrixPositionY = 5;
    }

    if (totalPart2 <= 8) {
      matrixPositionX = 0;
    } else if (totalPart2 <= 11) {
      matrixPositionX = 1;
    } else if (totalPart2 <= 14) {
      matrixPositionX = 2;
    } else if (totalPart2 <= 17) {
      matrixPositionX = 3;
    } else if (totalPart2 <= 20) {
      matrixPositionX = 4;
    } else if (totalPart2 <= 23) {
      matrixPositionX = 5;
    } else if (totalPart2 <= 27) {
      matrixPositionX = 6;
    } else if (totalPart2 <= 31) {
      matrixPositionX = 7;
    } else if (totalPart2 <= 35) {
      matrixPositionX = 8;
    } else if (totalPart2 <= 38) {
      matrixPositionX = 8;
    } else if (totalPart2 <= 41) {
      matrixPositionX = 10;
    } else if (totalPart2 <= 44) {
      matrixPositionX = 11;
    } else if (totalPart2 <= 47) {
      matrixPositionX = 12;
    } else {
      matrixPositionX = 13;
    }

    const riskToleranceStrategyRecommendation =
      calculateFinancialRiskToleranceMatrix[matrixPositionY][matrixPositionX];

    switch (riskToleranceStrategyRecommendation) {
      case "P":
        setSliderValueBasedOnRecommendationCalc(5);
        setRiskToleranceRecommendationName("preservation");
        break;
      case "C":
        setSliderValueBasedOnRecommendationCalc(15);
        setRiskToleranceRecommendationName("conservative");
        break;
      case "M":
        setSliderValueBasedOnRecommendationCalc(25);
        setRiskToleranceRecommendationName("moderate");
        break;
      case "G":
        setSliderValueBasedOnRecommendationCalc(35);
        setRiskToleranceRecommendationName("growth");
        break;
      case "E":
        setSliderValueBasedOnRecommendationCalc(45);
        setRiskToleranceRecommendationName("equity");
        break;
    }
  };

  const handleOk = () => {
    const [roundedSliderValue, riskToleranceType] =
      getChartCalculations(sliderValue);
    setSliderValue(roundedSliderValue);
    updateProposalData("riskTolerance", riskToleranceType);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setSliderValue(sliderValueBasedOnRecommendationCalc);
    updateProposalData("riskTolerance", riskToleranceRecommendationName);
    setIsModalOpen(false);
  };

  return (
    <Flex justify={"space-between"} style={{ marginTop: 50 }} wrap={"wrap"}>
      <Flex vertical style={{ width: 400 }}>
        <h3 className="title" style={{ margin: "0 0 10px" }}>
          Risk Selection
        </h3>
        <p className="description" style={{ margin: "0 0 52px" }}>
          Based on your answers, your calculated risk score is{" "}
          <b style={{ textTransform: "capitalize" }}>
            {proposalData.riskTolerance}.
          </b>
        </p>
        <Radio.Group
          onChange={e =>
            updateProposalData("acceptedRiskLevel", e.target.value)
          }
          value={proposalData.acceptedRiskLevel}
        >
          <Flex gap={20} vertical>
            <Radio value={"recommended"}>
              All accounts should be invested according to my measured risk
              level:{" "}
              <b style={{ textTransform: "capitalize" }}>
                {getChartCalculations(sliderValue)[1]}
              </b>
            </Radio>
            <Radio value={"custom"}>
              I wish to deviate from my measured risk level and request that my
              accounts be invested as indicated below
            </Radio>
          </Flex>
        </Radio.Group>
        <ButtonsContainer />
      </Flex>
      <div style={{ width: 524, marginTop: 30 }}>
        <Row
          justify="space-evenly"
          style={{ color: token.colorNavy, marginBottom: 21 }}
        >
          <RiskToleranceLabel
            className={
              getChartCalculations(sliderValue)[1] === "preservation" &&
              "active"
            }
          >
            Preservation
          </RiskToleranceLabel>
          <RiskToleranceLabel
            className={
              getChartCalculations(sliderValue)[1] === "conservative" &&
              "active"
            }
          >
            Conservative
          </RiskToleranceLabel>
          <RiskToleranceLabel
            className={
              getChartCalculations(sliderValue)[1] === "moderate" && "active"
            }
          >
            Moderate
          </RiskToleranceLabel>
          <RiskToleranceLabel
            className={
              getChartCalculations(sliderValue)[1] === "growth" && "active"
            }
          >
            Growth
          </RiskToleranceLabel>
          <RiskToleranceLabel
            className={
              getChartCalculations(sliderValue)[1] === "equity" && "active"
            }
          >
            Equity
          </RiskToleranceLabel>
        </Row>
        <div style={{ overflow: "hidden" }}>
          <div
            className="risk-tolerance-upper-chart-part"
            style={{
              height: 116,
              background:
                "conic-gradient(transparent 0deg 80deg, #5DCA83 82deg 260deg, transparent 262deg)",
              transform: `translateY(${50 - sliderValue}px) rotateX(${
                (50 - sliderValue) * 1.5
              }deg)`,
            }}
          ></div>
        </div>
        <StyledSlider
          value={sliderValue}
          min={0}
          max={50}
          step={1}
          tooltip={{ open: false }}
          trackStyle={{
            height: 11,
            background: "#E7EBEE",
            borderRadius: 35,
            boxShadow: "inset 0 2px 6px #00000067",
            cursor: "default",
          }}
          railStyle={{
            height: 11,
            background: "#E7EBEE",
            borderRadius: 10,
            boxShadow: "inset 0 2px 6px #00000067",
            cursor: "default",
          }}
          onChange={value => setSliderValue(value)}
          onAfterChange={() => setIsModalOpen(true)}
        />
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              height: 68,
              background:
                "conic-gradient(#D9D9D9 94deg, transparent 96deg 274deg, #D9D9D9 276deg)",
              boxShadow: "inset 0 10px 10px white",
              transform: `translateY(-${(50 - sliderValue) / 2}px) rotateX(${
                (50 - sliderValue) * 0.75
              }deg)`,
            }}
          ></div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        closeIcon={<Close />}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <Row justify={"center"} style={{ gap: 16 }}>
            <Button
              shape={"round"}
              size={"large"}
              style={{
                fontSize: 18,
                backgroundColor: "#192849",
                color: "#FFFFFF",
              }}
              onClick={handleCancel}
            >
              Stick with Recommendation
            </Button>
            <Button
              shape={"round"}
              size={"large"}
              style={{
                fontSize: 18,
                backgroundColor: "#192849",
                color: "#FFFFFF",
              }}
              onClick={handleOk}
            >
              Continue
            </Button>
          </Row>
        }
      >
        <div style={{ textAlign: "center", marginBottom: 30 }}>
          <p style={{ fontSize: 22 }}>
            Changing your risk tolerance will change the investment
            recommendations.
          </p>
          <p style={{ fontSize: 18 }}>You can also change this later.</p>
        </div>
      </Modal>
    </Flex>
  );
};

export default SectionRiskSelection;
