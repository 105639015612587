import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Button, Modal, Row } from "antd";
import CardProduct from "../../../../molecules/CardProduct";

import { BASIC_GROWTH_RATES_ID_LIST } from "../../constants";

import Close from "../../../../icons/CloseOutlined";

const StyledModal = styled(Modal)`
  &.ant-modal .ant-modal-content {
    padding: 32px;
  }

  & .ant-modal-header {
    margin-bottom: 24px;
  }

  & .ant-modal-footer {
    margin-top: 40px;
  }

  .list-title {
    font-size: 18px;
    color: #3e5377;
    border-bottom: 1px solid #7ebaba;
  }

  .list {
    gap: 32px;
    flex-wrap: nowrap;
    overflow: auto;
  }

  @media screen and (max-width: 576px) {
    &.ant-modal .ant-modal-content {
      height: calc(100vh - 36px);
      overflow: auto;
    }

    .list {
      flex-wrap: wrap;
    }
  }
`;

const ModalOtherGrowthTypes = ({
  cardActions,
  compareProducts,
  handleCardClick,
  handleClose,
  open,
  productsList,
  selectedProduct,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 390 });
  const cardWidth = isMobile ? "100%" : "267px";

  return (
    <StyledModal
      centered
      closeIcon={<Close />}
      footer={
        <Row justify={"start"}>
          <Button
            type={"primary"}
            shape={"round"}
            style={{ width: 107 }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Row>
      }
      open={open}
      onCancel={handleClose}
      title={"Growth Types"}
      width={736}
    >
      <p className={"list-title"}>
        <span style={{ fontWeight: 500 }}>Growth Rates</span> (Basic)
      </p>
      <Row className={"list"}>
        {productsList
          ?.filter(product => BASIC_GROWTH_RATES_ID_LIST.includes(product._id))
          ?.filter(product => !compareProducts.includes(product._id))
          ?.map(product => (
            <CardProduct
              key={product._id}
              product={product}
              actions={cardActions}
              height={"190px"}
              width={cardWidth}
              minWidth={cardWidth}
              maxWidth={cardWidth}
              handleCardClick={handleCardClick}
              labels={[product.investmentPhilosophy]}
              isActionButtonActive={selectedProduct === product._id}
            />
          ))}
      </Row>
      <p className={"list-title"}>
        <span style={{ fontWeight: 500 }}>Asset Classes</span> (Intermediate)
      </p>
      <Row className={"list"}>
        {productsList
          ?.filter(product => !BASIC_GROWTH_RATES_ID_LIST.includes(product._id))
          ?.filter(product => !compareProducts.includes(product._id))
          ?.map(product => (
            <CardProduct
              key={product._id}
              product={product}
              actions={cardActions}
              width={cardWidth}
              minWidth={cardWidth}
              maxWidth={cardWidth}
              handleCardClick={handleCardClick}
              labels={[product.investmentPhilosophy]}
              isActionButtonActive={selectedProduct === product._id}
            />
          ))}
      </Row>
    </StyledModal>
  );
};

export default ModalOtherGrowthTypes;
