import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import State from "../../../../context/user/State";
import { Avatar, theme } from "antd";
import MenuActions from "../../../../atoms/MenuActions";

import { logout } from "../../../../utils/requests/regularApp";

import { UserOutlined } from "@ant-design/icons";

const MenuGoalsUser = () => {
  const { token } = theme.useToken();
  const isTablet = useMediaQuery({ maxWidth: token.screenLGMin });
  const navigate = useNavigate();
  const [userState] = useContext(State);

  const getItems = () =>
    [
      {
        key: "1",
        label: (
          <div onClick={() => userState.openModal("profileSettingsModal")}>
            Profile
          </div>
        ),
        hidden: userState.isProposalUser(),
      },
      {
        key: "2",
        label: (
          <div onClick={() => userState.openModal("contactUsModal")}>
            Contact Us
          </div>
        ),
      },
      {
        type: "divider",
      },
      {
        key: "3",
        label: (
          <div
            onClick={() =>
              logout()
                .then(() => navigate("/login"))
                .catch(console.log)
            }
          >
            Log Out
          </div>
        ),
      },
    ].filter(it => !it.hidden);

  return (
    <>
      {userState.personalInfo ? (
        <MenuActions items={isTablet ? [] : getItems()}>
          <Avatar
            onClick={() =>
              isTablet && userState.openModal("profileSettingsModal")
            }
            size={41}
            style={{
              backgroundColor: token.sidebarAvatarBackground,
              color: token.sidebarAvatarColor,
            }}
          >
            {userState.personalInfo &&
              (userState.personalInfo.firstName ??
                userState.personalInfo.lastName)[0]}
          </Avatar>
        </MenuActions>
      ) : (
        <Avatar
          size={35}
          style={{
            backgroundColor: "#ADB7C5",
            color: "#3A5078",
            cursor: "waiting",
          }}
          icon={<UserOutlined />}
        />
      )}
    </>
  );
};

export default MenuGoalsUser;
