const LeadsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.3"
    height="18.334"
    viewBox="0 0 22.3 18.334"
  >
    <g id="Group_9835" data-name="Group 9835" transform="translate(-2 0.387)">
      <g transform="translate(-1259.948 -4074.627)">
        <path
          d="M8.021,9.167A4.583,4.583,0,1,0,3.438,4.583,4.583,4.583,0,0,0,8.021,9.167Zm3.208,1.146h-.6a6.233,6.233,0,0,1-5.221,0h-.6A4.814,4.814,0,0,0,0,15.126v1.49a1.719,1.719,0,0,0,1.719,1.719h12.6a1.719,1.719,0,0,0,1.719-1.719v-1.49A4.814,4.814,0,0,0,11.23,10.313Z"
          transform="translate(1261.948 4074.24)"
          fill="currentColor"
        />
        <path
          d="M5.068,3.435,6.7,1.8a.577.577,0,0,0,0-.816L5.884.169a.577.577,0,0,0-.816,0L3.435,1.8,1.8.169a.577.577,0,0,0-.816,0L.169.986a.577.577,0,0,0,0,.816L1.8,3.435.169,5.068a.577.577,0,0,0,0,.816L.986,6.7a.577.577,0,0,0,.816,0L3.435,5.068,5.068,6.7a.577.577,0,0,0,.816,0L6.7,5.884a.577.577,0,0,0,0-.816Z"
          transform="translate(1274.533 4081.815) rotate(-45)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default LeadsIcon;
