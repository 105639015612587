import LogoOneascent from "../images/logo.svg";

export default {
  chartColors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  colorPrimaryButtonText: "#FFFFFF",
  logoSrc: LogoOneascent,
  modalFinancialProductDetailsHeaderBackground: "#E7EBEE",
  pageLoginButtonSubmitColor: "#192849",
  sidebarBackground:
    "transparent linear-gradient(121deg, #15284b 0%, #2a98d5 100%) 0 0",
  sidebarIconColorActive: "#74C2E6",
  sidebarMenuItemHoverBackground: "#152A4E",
  tenantColorPrimary: "#4897D1",
};
