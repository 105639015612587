import styled from "styled-components";
import { Button, Modal, Row } from "antd";
import CardProduct from "../../molecules/CardProduct";

import Close from "../../icons/Close";

const StyledModal = styled(Modal)`
  & .ant-modal-header {
    margin-bottom: 34px;
  }

  & .ant-modal-footer {
    margin-top: 33px;
  }
`;

const ListTitle = styled.div`
  font-size: 18px;
  color: #3e5377;
  border-bottom: 1px solid #7ebaba;
  margin-bottom: 16px;
`;

const ModalRelatedProducts = ({
  cardActions,
  compareProducts,
  favoriteFinancialProducts,
  handleCardClick,
  handleClose,
  hideFavoriteProducts,
  open,
  productsList,
  selectedProduct,
}) => {
  const onCardClick = cardId => {
    handleCardClick(cardId);
  };

  return (
    <StyledModal
      open={open}
      onCancel={handleClose}
      width={980}
      title={"Related Products"}
      closeIcon={<Close />}
      footer={
        <Row justify={"start"}>
          <Button
            type={"primary"}
            shape={"round"}
            style={{ width: 107 }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Row>
      }
      centered
    >
      {favoriteFinancialProducts?.length && (
        <ListTitle>Similar Products</ListTitle>
      )}
      <Row
        style={{ gap: 32, maxHeight: "70vh", overflow: "auto" }}
        wrap={!favoriteFinancialProducts?.length}
      >
        {productsList
          ?.filter(product => !compareProducts.includes(product._id))
          ?.map(product => (
            <CardProduct
              key={product._id}
              product={product}
              actions={cardActions}
              width={"267px"}
              minWidth={"267px"}
              maxWidth={"267px"}
              handleCardClick={onCardClick}
              labels={[product.riskTolerance, product.allocationType]}
              isActionButtonActive={selectedProduct === product._id}
            />
          ))}
      </Row>
      {favoriteFinancialProducts?.length && !hideFavoriteProducts && (
        <div style={{ marginTop: 18 }}>
          <ListTitle>Advisor Preferred</ListTitle>
          <Row style={{ gap: 32, overflow: "auto" }} wrap={false}>
            {productsList
              .filter(it => favoriteFinancialProducts.includes(it._id))
              .filter(product => !compareProducts.includes(product._id))
              .map(product => (
                <CardProduct
                  key={product._id}
                  product={product}
                  actions={cardActions}
                  width={"267px"}
                  minWidth={"267px"}
                  maxWidth={"267px"}
                  handleCardClick={onCardClick}
                  labels={[product.riskTolerance, product.allocationType]}
                  isActionButtonActive={selectedProduct === product._id}
                />
              ))}
          </Row>
        </div>
      )}
    </StyledModal>
  );
};

export default ModalRelatedProducts;
