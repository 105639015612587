import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import State from "../../../../context/user/State";
import { Button, Flex, Form, Input } from "antd";

import IdentityManager from "../../../../requests/aws/IdentityManager";
import {
  getUserDataByEmail,
  updateUser,
  updateUserEmail,
} from "../../../../requests/global";
import { DEFAULT_PASSWORD } from "../../../../constants";

import { ReactComponent as BlueArrow } from "../../../../images/blue_arrow_down.svg";
import { ReactComponent as Eyeball } from "../../../../images/eyeball.svg";
import { ReactComponent as EyeballOff } from "../../../../images/eyeball_off.svg";

const Container = styled(Flex)`
  padding: 0 29px;

  & .ant-input,
  & .ant-input-affix-wrapper {
    background: #ebf1f3;
    border-color: transparent;
  }

  .ant-input-suffix {
    cursor: pointer;
  }
`;

const PageSettingsSecurity = () => {
  const navigate = useNavigate();
  const [userState] = useContext(State);
  const [form] = Form.useForm();
  const [loading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      firstName: userState.user?.firstName,
      lastName: userState.user?.lastName,
      email: userState.user?.email,
    });
  }, []);

  const handleClose = () => navigate("/mmi/dashboard");

  const onFinish = ({
    email,
    firstName,
    lastName,
    newPassword,
    oldPassword,
    repeatNewPassword,
  }) => {
    if (newPassword !== repeatNewPassword) {
      userState.showWarning("The new password that you entered do not match!");
      return;
    }

    if (oldPassword && !newPassword) {
      userState.showWarning("Need to provide new password!");
      return;
    }

    if (userState.user?.email !== email) {
      // Cognito do not allow email updating. We make deep copy of the old account with new email.
      updateUserEmail({
        userId: userState.user._id,
        newEmail: email,
      })
        .then(() => {
          userState.setKeyValue("showOverlay", true);
          userState.showSuccess(
            "Email changed. Please, create the password and log in."
          );

          setTimeout(() => {
            navigate(
              `/login-mmi?email=${email}&oldPassword=${DEFAULT_PASSWORD}`
            );
          }, 3000);
        })
        .catch(console.log);

      // do not handle other "user" data change in the same time with "email" updating
      return;
    }

    if (
      userState.user?.firstName !== firstName ||
      userState.user?.lastName !== lastName
    ) {
      updateUser({
        userId: userState.user._id,
        payloadData: {
          firstName,
          lastName,
        },
      })
        .then(() => {
          getUserDataByEmail(userState.user?.email).then(data => {
            userState.setKeyValue("user", data);
            userState.showSuccess("User private data updated.");
            handleClose();
          });
        })
        .catch(({ message }) => userState.showWarning(message));
    }

    if (oldPassword && newPassword) {
      IdentityManager.changePassword({
        oldPassword,
        newPassword,
        username: userState.user?.email,
      })
        .then(() => {
          userState.showSuccess("User password updated");
          handleClose();
        })
        .catch(({ message }) => userState.showWarning(message));
    }
  };

  return (
    <Container gap={16} vertical>
      <Button
        onClick={handleClose}
        size={"small"}
        style={{ width: 150, color: "#435275" }}
        type={"text"}
      >
        <Flex align={"center"} gap={6}>
          <BlueArrow style={{ rotate: "90deg" }} />
          Back to home
        </Flex>
      </Button>
      <div style={{ fontSize: 20, fontWeight: 500, marginBottom: 6 }}>
        Settings & Security
      </div>
      <Form
        id={"settingsSecurityForm"}
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
      >
        <Flex gap={16} vertical>
          <div>
            <Form.Item label={"First Name"} name="firstName">
              <Input />
            </Form.Item>
            <Form.Item label={"Last Name"} name="lastName">
              <Input />
            </Form.Item>
            <Form.Item label={"Email (Username)"} name="email">
              <Input />
            </Form.Item>
          </div>
          <Flex vertical>
            <Form.Item label={"Old Password"} name="oldPassword">
              <Input.Password
                iconRender={visible => (visible ? <Eyeball /> : <EyeballOff />)}
              />
            </Form.Item>
            <Form.Item label={"New Password"} name="newPassword">
              <Input.Password
                iconRender={visible => (visible ? <Eyeball /> : <EyeballOff />)}
              />
            </Form.Item>
            <Form.Item label={"Re-type New Password"} name="repeatNewPassword">
              <Input.Password
                iconRender={visible => (visible ? <Eyeball /> : <EyeballOff />)}
              />
            </Form.Item>
          </Flex>
        </Flex>
        <Button
          block
          disabled={loading}
          htmlType="submit"
          shape={"round"}
          type={"text"}
        >
          Save
        </Button>
      </Form>
    </Container>
  );
};

export default PageSettingsSecurity;
