import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../context/manager/State";
import { Button, Input, Row, Table, theme } from "antd";
import ConvertClientDialog from "../ConvertClientDialog";
import ModalProposalGuide from "../../../../organisms/ModalProposalGuide";
import ModalArchiveManagedUser from "../../../../molecules/ModalArchiveManagedUser";
import MenuActions from "../../../../atoms/MenuActions";

import { archiveLead, openProposal } from "../../../../utils/requests/manager";
import {
  getLastSavedProposalProps,
  isAdminView,
  isManagerTypeIpq,
  isOrganizationOneascent,
  openProposalWithConfig,
} from "../../../../utils/helpers/specialized";

const getColumns = ({
  managerState,
  instanceLabel,
  searchedText,
  token,
  handleConvertModalOpen,
  handleArchiveClientActionClick,
  handleEditProposalModalOpen,
  handleCreateProposal,
}) => [
  {
    dataIndex: "name",
    filteredValue: [searchedText],
    key: "name",
    onFilter: (value, record) =>
      String(record.name).toLowerCase().includes(value.toLowerCase()) ||
      String(record.email).toLowerCase().includes(value.toLowerCase()),
    render: (text, restValues) =>
      restValues.proposalGenerated ? (
        <a
          onClick={() =>
            openProposal({
              userEmail: restValues.email,
              organization: managerState.organization?.name,
            })
          }
        >
          <b>{text}</b>
        </a>
      ) : (
        <b>{text}</b>
      ),
    sorter: (a, b) => a.name.localeCompare(b.name),
    title: `${instanceLabel[0].toUpperCase() + instanceLabel.slice(1)} Name`,
  },
  {
    dataIndex: "email",
    key: "email",
    render: value => <a href={"mailto:" + value}>{value}</a>,
    sorter: (a, b) => a.email.localeCompare(b.email),
    title: "Email",
  },
  {
    align: "right",
    dataIndex: "proposalGenerated",
    key: "proposalGenerated",
    render: (value, restValues) =>
      value ? (
        dayjs(value).format("M/DD/YYYY")
      ) : (
        <Button
          shape="round"
          size="small"
          style={{
            borderColor: token.colorSecondaryButton,
            color: token.colorSecondaryButton,
          }}
          onClick={() => handleCreateProposal(restValues.email)}
          disabled={isAdminView()}
        >
          Create Proposal
        </Button>
      ),
    sorter: (a, b) => dayjs(a.proposalGenerated) - dayjs(b.proposalGenerated),
    title: "Proposal Generated",
    width: 150,
  },
  {
    className: "actions-menu-table-cell",
    dataIndex: "actionsMenu",
    key: "actionsMenu",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          email: restValues.email,
          handleConvertModalOpen,
          handleArchiveClientActionClick,
          handleEditProposalModalOpen,
          instanceLabel,
          managerType: managerState.onboardingQuestionnaireType,
        })}
      />
    ),
    title: "",
    width: 40,
  },
];

const getItems = ({
  email,
  handleConvertModalOpen,
  handleArchiveClientActionClick,
  handleEditProposalModalOpen,
  instanceLabel,
  managerType,
}) =>
  [
    {
      key: "edit",
      label: (
        <div onClick={() => handleEditProposalModalOpen(email)}>
          Edit{" "}
          <span style={{ textTransform: "capitalize" }}>{instanceLabel}</span>
        </div>
      ),
    },
    {
      key: "move",
      label: (
        <div
          onClick={() => {
            handleConvertModalOpen(email);
          }}
        >
          Mark as Client
        </div>
      ),
    },
    {
      hidden: !isManagerTypeIpq(managerType),
      label: "New IPQ",
      key: "newIpq",
    },
    {
      hidden: !isManagerTypeIpq(managerType),
      label: "View IPQ",
      key: "viewIpq",
    },
    {
      type: "divider",
    },
    {
      key: "archive",
      label: (
        <div
          style={{
            color: "red",
            textTransform: "capitalize",
          }}
          onClick={() => handleArchiveClientActionClick(email)}
        >
          Archive
        </div>
      ),
    },
  ].filter(it => !it.hidden);

const ProspectsTable = ({ instanceLabel }) => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  const [isConvertModalOpened, setIsConvertModalOpened] = useState(false);
  const [isCreateNewProposalOpened, setIsCreateNewProposalOpened] =
    useState(false);

  const handleArchiveClient = email => {
    managerState.closeModal("archiveManagedUserModal");
    managerState.showLoader();

    archiveLead({
      email: managerState.activeEmail,
      userManagerEmail: managerState._id,
    })
      .then(() =>
        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.setKeyValue("activeEmail", null);
          managerState.showSuccess(
            `Client ${managerState.activeEmail} archived`
          );
        })
      )
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleArchiveClientActionClick = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("archiveManagedUserModal");
  };

  const handleConvertModalOpen = email => {
    managerState.setKeyValue("activeEmail", email);
    setIsConvertModalOpened(true);
  };

  const handleEditProposalModalOpen = email => {
    managerState.setKeyValue("activeEmail", email);

    if (isOrganizationOneascent(managerState.organization?.name)) {
      managerState.openModal("addProspectModal");
    } else {
      managerState.openModal("leadDetailsModal");
    }
  };

  const handleCreateProposal = email => {
    const proposalData = managerState?.managedUsers?.find(
      it => it.email === email
    );

    if (
      !proposalData?.valueMap?.guideInitialData ||
      proposalData?.valueMap?.guideInitialData?.enableOnboardingQuestions
    ) {
      managerState.setKeyValue("activeEmail", email);
      setIsCreateNewProposalOpened(true);
    } else {
      openProposalWithConfig({
        organization: managerState?.organization?.name,
        prospectType: managerState?.onboardingQuestionnaireType,
        userEmail: email,
      });
    }
  };

  const getTableData = () =>
    managerState.getProspectUsers().map(it => ({
      key: it.email,
      name: it.lastName + ", " + it.firstName,
      email: it.email,
      proposalGenerated:
        it.proposalSavedDate?.$date ??
        getLastSavedProposalProps(it.valueMap.productMap)?.timestamp,
      actionsMenu: { email: it.email },
      proposalData: getLastSavedProposalProps(it.valueMap?.productMap),
    }));

  return (
    <>
      <Row>
        <Input
          style={{
            maxWidth: 290,
            height: 37,
            marginBottom: 16,
            borderRadius: 25,
            textTransform: "capitalize",
          }}
          placeholder={`Search ${instanceLabel}s...`}
          onChange={event => setSearchedText(event.target.value)}
        />
      </Row>
      <Table
        dataSource={getTableData()}
        columns={getColumns({
          instanceLabel,
          managerState,
          handleConvertModalOpen,
          handleCreateProposal,
          handleArchiveClientActionClick,
          handleEditProposalModalOpen,
          searchedText,
          token,
        })}
        pagination={false}
        loading={managerState.loading}
      />

      <ConvertClientDialog
        email={managerState.activeEmail}
        open={isConvertModalOpened}
        handleClose={() => setIsConvertModalOpened(false)}
      />
      <ModalArchiveManagedUser
        instanceName={instanceLabel}
        loading={managerState.loading}
        onCancel={() => managerState.closeModal("archiveManagedUserModal")}
        onConfirm={handleArchiveClient}
        open={managerState.archiveManagedUserModal}
      />
      <ModalProposalGuide
        email={managerState.activeEmail}
        open={isCreateNewProposalOpened}
        handleClose={() => setIsCreateNewProposalOpened(false)}
      />
    </>
  );
};

export default ProspectsTable;
