import { useContext } from "react";
import styled from "styled-components";
import State from "../../../../context/manager/State";
import { Button, Flex, Tabs } from "antd";
import ModalArchiveManagedUser from "../../../../molecules/ModalArchiveManagedUser";
import ModalImportManagedUser from "../../components/ModalImportManagedUser";
import ModalLeadDetails from "../../components/ModalLeadDetails";
import ModalLeadQuestion from "../../components/ModalLeadQuestion";
import ModalReleaseLead from "../../components/ModalReleaseLead";
import SectionMyLeads from "./components/SectionMyLeads";
import SectionNextLeads from "./components/SectionNextLeads";

import { DEFAULT_BIRTH_YEAR } from "../../../../utils/constants";

import {
  addLead,
  archiveLead,
  convertLeadToClient,
  editLead,
  importCSVManagedUserLead,
  openGoalsManagedUser,
  openManagedUser,
  openProposal,
  releaseLead,
  setLastAdvisorLogin,
} from "../../../../utils/requests/manager";

import AskPennyLogo from "../../images/AskPennyLogo";
import { ReactComponent as DownloadIcon } from "./images/download.svg";

const StyleProvider = styled.div`
  .header {
    border-bottom: 1px solid #c9c8c8;
    padding: 23px 63px 17px 35px;

    .title {
      font-size: 20px;
      font-weight: 800;
      color: #1b5568;
      margin: 0;
    }
  }

  .body {
    padding: 42px 78px 27px 35px;
  }

  .ant-tabs {
    .ant-tabs-tab-btn {
      font-size: 16px;
    }

    .ant-tabs-nav {
      margin-bottom: 30px;

      &::before {
        bottom: 3px;
        border-bottom-color: #c9c8c8;
      }

      .ant-tabs-ink-bar {
        height: 8px;
        background: #02494e;
        border-radius: 11px;
      }
    }
  }

  .ant-table-wrapper .ant-table-thead > tr > * {
    font-weight: 500;
    background: transparent;

    &::before {
      display: none;
    }
  }
`;

const isMyLeadUser = userData => {
  if (userData?.valueMap?.leadInitial?.isConverted) {
    return false;
  } else if (
    userData?.entryPoint === "addedByUserManager" &&
    (userData?.userType === "Assigned" || userData?.userType === "Prospect")
  ) {
    return true;
  } else if (
    userData?.entryPoint === "addedByItself" &&
    userData?.userType === "Affiliated"
  ) {
    return true;
  } else {
    return false;
  }
};

const PageLeads = () => {
  const [managerState] = useContext(State);

  const myLeadsList =
    managerState.managedUsers
      ?.filter(it => isMyLeadUser(it))
      ?.map((it, index) => ({ ...it, key: index })) ?? [];

  const handleArchiveLead = () => {
    managerState.closeModal("archiveLeadModal");
    managerState.showLoader();

    archiveLead({
      email: managerState.activeEmail,
      userManagerEmail: managerState._id,
    })
      .then(() =>
        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.setKeyValue("activeEmail", null);
          managerState.showSuccess(`Lead ${managerState.activeEmail} archived`);
        })
      )
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleArchiveLeadModalClose = () => {
    managerState.closeModal("archiveLeadModal");
    managerState.setKeyValue("activeEmail", null);
  };

  const handleLeadQuestionModalClose = () => {
    managerState.closeModal("leadQuestionModal");
  };

  const handleMoveLeadToClient = email => {
    convertLeadToClient(email)
      .then(() =>
        managerState
          .setAdminData()
          .then(() =>
            managerState.showSuccess(`Lead ${email} converted into client.`)
          )
      )
      .catch(console.log);
  };

  const handleReleaseLead = () => {
    managerState.showLoader();

    releaseLead({ email: managerState.activeEmail })
      .then(response => {
        managerState.showSuccess(response?.data);
        managerState.closeModal("releaseLeadModal");

        managerState.setAdminData(() => {
          managerState.setKeyValue(managerState.activeEmail, null);
          managerState.hideLoader();
        });
      })
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleReleaseLeadModalClose = () => {
    managerState.closeModal("releaseLeadModal");
    managerState.setKeyValue("activeEmail", null);
  };

  const handleExportLeads = () => {
    window.open(
      `${process.env.REACT_APP_API_GATEWAY}/userManager/export?email=${encodeURIComponent(managerState._id)}&userType=Lead`
    );
  };

  const handleImportLeads = file => {
    importCSVManagedUserLead(file)
      .then(response => {
        if (typeof response?.data === "object") {
          Object.keys(response.data).map(key =>
            managerState.showWarning(`${key} ${response.data[key]}`)
          );
        }

        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.closeModal("importManagedUserModal");
          managerState.showSuccess("Leads list imported.");
        });
      })
      .catch(error => {
        managerState.hideLoader();
        managerState.showError(error.response?.data);
      });
  };

  const handleLeadQuestionAnswerSubmit = values => {
    const leadData = managerState.managedUsers.find(
      it => it.email === managerState.activeEmail
    );

    if (leadData) {
      managerState.showLoader();

      editLead({
        ...leadData,
        leadInitial: {
          ...leadData.valueMap?.leadInitial,
          ...values,
        },
      })
        .then(() =>
          managerState.setAdminData().then(() => {
            managerState.showSuccess("Answer sent.");
            managerState.closeModal("leadQuestionModal");
            managerState.hideLoader();
          })
        )
        .catch(error => {
          managerState.hideLoader();
          managerState.showError(error?.response?.data);
        });
    }
  };

  const tabsItems = [
    {
      children: (
        <SectionMyLeads
          handleMoveLeadToClient={handleMoveLeadToClient}
          myLeadsList={myLeadsList}
        />
      ),
      hidden:
        managerState.access?.next &&
        !managerState.access?.affiliate &&
        !managerState.access?.level &&
        !managerState.access?.proportal,
      key: 1,
      label: `My Leads (${myLeadsList?.length ?? 0})`,
    },
    {
      disabled: !managerState.access?.next,
      children: (
        <SectionNextLeads handleMoveLeadToClient={handleMoveLeadToClient} />
      ),
      key: 2,
      label: `Next Leads (${
        managerState.managedUsers?.filter(
          it =>
            it.entryPoint === "addedByOrgManager" &&
            (it.userType === "Assigned" || it.userType === "Prospect") &&
            !it.valueMap?.leadInitial?.isConverted
        )?.length ?? 0
      })`,
    },
  ].filter(it => !it.hidden);

  const handleOpenClientView = ({ planningType, userEmail }) => {
    if (managerState.access?.level) {
      openProposal({ userEmail });
    } else {
      planningType === "cashflow"
        ? openManagedUser(userEmail)
        : openGoalsManagedUser(userEmail);
    }

    setLastAdvisorLogin(userEmail).then(() => managerState.setAdminData());
  };

  const handleUpdateLeadDetails = values => {
    managerState.showLoader();

    if (managerState.activeEmail) {
      const leadData = managerState.managedUsers.find(
        it => it.email === managerState.activeEmail
      );

      editLead({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        birthYear: leadData.birthYear,
        phoneNumber: values.phoneNumber,
        leadInitial: {
          ...leadData?.valueMap?.leadInitial,
          planningType: values.planningType,
          assets: values.assets,
          phoneAlt: values.phoneAlt,
        },
      }).then(() =>
        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.closeModal("leadDetailsModal");
          managerState.setKeyValue("activeEmail", null);
        })
      );
    } else {
      addLead({
        ...values,
        birthYear: values.birthYear ?? DEFAULT_BIRTH_YEAR,
        leadInitial: {
          rate: 0,
          isNotifyAdvisor: values.isNotifyAdvisor,
        },
        phoneNumber: `${values.phoneNumber}`,
        userManagerEmail: managerState._id,
      })
        .then(response =>
          managerState.setAdminData().then(() => {
            managerState.showSuccess(response.data);
            managerState.closeModal("leadDetailsModal");
          })
        )
        .catch(error => {
          managerState.hideLoader();
          managerState.showError(error.response?.data);
        });
    }
  };

  return (
    <StyleProvider>
      <Flex align={"center"} className={"header"} justify={"space-between"}>
        <h2 className={"title"}>Leads</h2>
        <Button
          disabled={!managerState.access?.penny}
          onClick={() => managerState.openModal("openModalAskAdvice")}
          shape={"round"}
          type={"primary"}
        >
          <Flex align={"center"}>
            <AskPennyLogo />
          </Flex>
        </Button>
      </Flex>
      <div className="body">
        <Tabs
          tabBarExtraContent={
            <Button
              disabled={
                !managerState.managedUsers?.filter(
                  it =>
                    (it.userType === "Assigned" ||
                      it.userType === "Unassigned") &&
                    !it.valueMap?.leadInitial?.isConverted
                )?.length
              }
              onClick={handleExportLeads}
              shape={"round"}
              style={{ color: "#1B5568" }}
              type={"text"}
            >
              <Flex align={"center"} gap={6}>
                <DownloadIcon />
                Export All Leads
              </Flex>
            </Button>
          }
          items={tabsItems}
        />
      </div>

      <ModalArchiveManagedUser
        instanceName={"lead"}
        loading={managerState.loading}
        onCancel={handleArchiveLeadModalClose}
        onConfirm={handleArchiveLead}
        open={managerState.archiveLeadModal}
      />
      <ModalImportManagedUser
        instanceLabel={"lead"}
        loading={managerState.loading}
        onCancel={() => managerState.closeModal("importManagedUserModal")}
        onFinish={handleImportLeads}
        open={managerState.importManagedUserModal}
      />
      <ModalLeadDetails
        activeLeadData={myLeadsList?.find(
          it => it.email === managerState.activeEmail
        )}
        handleOpenClientView={handleOpenClientView}
        handleUpdateLeadDetails={handleUpdateLeadDetails}
        loading={managerState.loading}
        managerAccess={managerState.access}
        onCancel={() => managerState.closeModal("leadDetailsModal")}
        open={managerState.leadDetailsModal}
      />
      <ModalLeadQuestion
        activeUserData={managerState.managedUsers?.find(
          it => it.email === managerState.activeEmail
        )}
        loading={managerState.loading}
        open={managerState.leadQuestionModal}
        onCancel={handleLeadQuestionModalClose}
        onSubmit={handleLeadQuestionAnswerSubmit}
      />
      <ModalReleaseLead
        loading={managerState.loading}
        onCancel={handleReleaseLeadModalClose}
        onConfirm={handleReleaseLead}
        open={managerState.releaseLeadModal}
      />
    </StyleProvider>
  );
};

export default PageLeads;
