import { useContext, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import State from "../../../../context/manager/State";
import { Button, ConfigProvider, Flex, Input, Table } from "antd";
import MenuActions from "../../../../atoms/MenuActions";
import ModalArchiveManagedUser from "../../../../molecules/ModalArchiveManagedUser";
import ModalConvertIntoLead from "../../components/ModalConvertIntoLead";
import ModalImportManagedUser from "../../components/ModalImportManagedUser";
import ModalNextManagedUserData from "../../components/ModalNextManagedUserData";

import {
  archiveLead,
  importCSVManagedUser,
  markAsProspect,
  notifyManagedUser,
  openGoalsManagedUser,
  openManagedUser,
  openManagedUserAsThem,
} from "../../../../utils/requests/manager";
import { getCurrencyValue } from "../../../../utils/helpers/general";
import { isManagerAccessAffiliateOnly } from "../../../../utils/helpers/specialized";

import { SearchOutlined } from "@ant-design/icons";
import AskPennyLogo from "../../images/AskPennyLogo";
import { ReactComponent as ImportIcon } from "../../images/import.svg";

const StyleProvider = styled.div`
  .header {
    border-bottom: 1px solid #c9c8c8;
    padding: 23px 63px 17px 35px;

    .title {
      font-size: 20px;
      font-weight: 800;
      color: #1b5568;
      margin: 0;
    }
  }

  .body {
    padding: 42px 78px 27px 35px;
  }

  .ant-table-wrapper .ant-table-thead > tr > td,
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-weight: 500;
    background: transparent;
    border-bottom-color: #8093a3;

    &::before {
      display: none;
    }
  }
`;

const getColumns = ({
  handleActionArchive,
  handleActionConvert,
  handleClientNameClick,
  handleNotify,
  managerAccess,
  searchedText,
}) =>
  [
    {
      dataIndex: "firstName",
      filteredValue: [searchedText],
      key: "name",
      render: (value, restValues) => (
        <a
          className={"pointer"}
          onClick={() => handleClientNameClick(restValues)}
        >
          {restValues.firstName} {restValues.lastName}
        </a>
      ),
      onFilter: (value, record) =>
        String(record.firstName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.lastName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      title: "Client",
    },
    {
      dataIndex: "email",
      key: "email",
      render: value => <a href={"mailto:" + value}>{value}</a>,
      title: "Email",
    },
    {
      align: "right",
      dataIndex: "valueMap",
      key: "assets",
      render: (valueMap, restValues) =>
        getCurrencyValue(
          restValues.lastLoginTime
            ? restValues.currentAssets
            : valueMap?.leadInitial?.assets ?? 0
        ),
      title: "Assets",
    },
    {
      dataIndex: "planningType",
      key: "planningType",
      render: (value, restValues) => (
        <span style={{ textTransform: "capitalize" }}>
          {value ??
            (restValues.valueMap?.leadInitial?.planningType === "goals" ||
            isManagerAccessAffiliateOnly(managerAccess)
              ? "goals"
              : "cashflow")}
        </span>
      ),
      title: "Planning Type",
    },
    {
      align: "right",
      dataIndex: "lastLoginTime",
      key: "updateDateTime",
      render: value =>
        value ? dayjs(value.$date).format("M/DD/YYYY") : "Never",
      title: "Last Client Login",
    },
    {
      align: "right",
      dataIndex: "lastAdvisorLoginTime",
      key: "lastAccessedDate",
      render: value =>
        value ? dayjs(value.$date).format("M/DD/YYYY") : "Never",
      title:
        managerAccess?.affiliate || managerAccess?.proportal
          ? "Last Advisor Login"
          : "Last Modified",
    },
    {
      align: "right",
      dataIndex: "status",
      hidden: isManagerAccessAffiliateOnly(managerAccess),
      key: "status",
      render: (value, restValues) => {
        if (
          (
            restValues.planningType ??
            restValues.valueMap?.leadInitial?.planningType
          )?.toLowerCase() === "goals"
        ) {
          return "N/A";
        } else {
          return value === "NotNotified" ? "Not Notified" : value;
        }
      },
      title: "Status",
    },
    {
      className: "actions-menu-table-cell",
      dataIndex: "actionsMenu",
      key: "actionsMenu",
      render: (value, restValues) => (
        <MenuActions
          items={
            (
              restValues.planningType ??
              restValues.valueMap?.leadInitial?.planningType
            )?.toLowerCase() === "goals"
              ? getGoalsActions({
                  email: restValues.email,
                  handleActionArchive,
                  handleActionConvert,
                  managerAccess,
                })
              : getCashflowActions({
                  email: restValues.email,
                  handleNotify,
                  handleActionArchive,
                  handleActionConvert,
                  managerAccess,
                })
          }
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

const getCashflowActions = ({
  email,
  handleNotify,
  handleActionArchive,
  handleActionConvert,
  managerAccess,
}) =>
  [
    {
      hidden:
        managerAccess?.affiliate &&
        !managerAccess?.next &&
        !managerAccess?.proportal,
      key: "open",
      label: (
        <div onClick={() => openManagedUserAsThem(email)}>Open Client View</div>
      ),
    },
    {
      hidden: !managerAccess?.affiliate && !managerAccess?.proportal,
      key: "move",
      label: <div onClick={() => handleActionConvert(email)}>Move to Lead</div>,
    },
    {
      hidden:
        managerAccess?.affiliate &&
        !managerAccess?.next &&
        !managerAccess?.proportal,
      key: "sendEmail",
      label: <div onClick={() => handleNotify(email)}>Send Welcome Email</div>,
    },
    {
      type: "divider",
    },
    {
      key: "delete",
      label: (
        <div
          onClick={() => handleActionArchive(email)}
          style={{ color: "red" }}
        >
          Archive
        </div>
      ),
    },
  ].filter(it => !it.hidden);

const getGoalsActions = ({
  email,
  handleActionArchive,
  handleActionConvert,
  managerAccess,
}) =>
  [
    {
      hidden: !managerAccess?.affiliate && !managerAccess?.proportal,
      key: "move",
      label: <div onClick={() => handleActionConvert(email)}>Move to Lead</div>,
    },
    {
      key: "delete",
      label: (
        <div
          onClick={() => handleActionArchive(email)}
          style={{ color: "red" }}
        >
          Archive Client
        </div>
      ),
    },
  ].filter(it => !it.hidden);

const PageNextClients = () => {
  const [managerState] = useContext(State);
  const [searchedText, setSearchedText] = useState("");

  const handleAddClientClick = () => {
    managerState.setKeyValue("activeEmail", null);
    managerState.openModal("addManagedUserModal");
  };

  const handleArchiveClient = () => {
    managerState.closeModal("archiveManagedUserModal");
    managerState.showLoader();

    archiveLead({
      email: managerState.activeEmail,
      userManagerEmail: managerState._id,
    })
      .then(() =>
        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.setKeyValue("activeEmail", null);
          managerState.showSuccess(
            `Client ${managerState.activeEmail} archived`
          );
        })
      )
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleActionArchive = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("archiveManagedUserModal");
  };

  const handleActionConvert = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("openModalConvertIntoLead");
  };

  const handleClientNameClick = clientData => {
    const clientEmail = clientData.email;

    if (clientData.metaData?.keyValue?.AffiliateUrl) {
      const clientPlanningType = clientData.planningType;

      clientPlanningType === "goals"
        ? openGoalsManagedUser(clientEmail)
        : openManagedUser(clientEmail);
    } else {
      managerState.setKeyValue("activeEmail", clientEmail);
      managerState.openModal("addManagedUserModal");
    }
  };

  const handleImportClients = file => {
    importCSVManagedUser(file)
      .then(response => {
        if (typeof response?.data === "object") {
          Object.keys(response.data).map(key =>
            managerState.showWarning(`${key} ${response.data[key]}`)
          );
        }

        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.closeModal("importManagedUserModal");
          managerState.showSuccess("Clients list imported.");
        });
      })
      .catch(error => {
        managerState.hideLoader();
        managerState.showError(error.response?.data);
      });
  };

  const handleImportClientsClick = () => {
    managerState.openModal("importManagedUserModal");
  };

  const handleNotify = email => {
    managerState.showLoader();

    notifyManagedUser(email)
      .then(() =>
        managerState.setAdminData().then(() => {
          managerState.showSuccess(`Welcome email to the ${email} sent`);
          managerState.hideLoader();
        })
      )
      .catch(error => {
        managerState.showError(error?.response?.data);
        managerState.hideLoader();
      });
  };

  const handleConvertIntoLead = () => {
    managerState.showLoader();
    managerState.closeModal("openModalConvertIntoLead");

    markAsProspect(managerState.activeEmail)
      .then(() => {
        managerState.showSuccess("Client converted into Lead!");
        managerState.setAdminData();
      })
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  return (
    <StyleProvider>
      <Flex align={"center"} className={"header"} justify={"space-between"}>
        <h2 className={"title"}>Clients</h2>
        <Button
          disabled={!managerState.access?.penny}
          onClick={() => managerState.openModal("openModalAskAdvice")}
          shape={"round"}
          type={"primary"}
        >
          <Flex align={"center"}>
            <AskPennyLogo />
          </Flex>
        </Button>
      </Flex>
      <div className="body">
        <Flex justify={"space-between"} style={{ marginBottom: 20 }}>
          <Input
            style={{
              maxWidth: 228,
              height: 40,
              borderRadius: 25,
            }}
            placeholder="Search my clients..."
            onChange={event => setSearchedText(event.target.value)}
            suffix={<SearchOutlined />}
          />
          <Flex gap={20}>
            <Button
              icon={<ImportIcon />}
              onClick={handleImportClientsClick}
              shape={"round"}
              size={"small"}
              type={"text"}
            >
              Import Clients
            </Button>
            <Button
              onClick={handleAddClientClick}
              shape={"round"}
              size={"small"}
              type={"primary"}
            >
              + Add New Client
            </Button>
          </Flex>
        </Flex>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerColor: "#6C6C6C",
                colorText: "#516474",
              },
            },
          }}
        >
          <Table
            columns={getColumns({
              handleActionArchive,
              handleNotify,
              handleClientNameClick,
              handleActionConvert,
              managerAccess: managerState.access,
              searchedText,
            })}
            dataSource={managerState.managedUsers
              ?.filter(
                it =>
                  it.userType === "Registered" ||
                  it.valueMap?.leadInitial?.isConverted
              )
              ?.map((it, index) => ({
                ...it,
                key: index,
              }))}
            loading={managerState.loading}
            pagination={false}
            scroll={{
              x: 1250,
            }}
          />
        </ConfigProvider>
      </div>

      <ModalArchiveManagedUser
        loading={managerState.loading}
        onCancel={() => managerState.closeModal("archiveManagedUserModal")}
        onConfirm={handleArchiveClient}
        open={managerState.archiveManagedUserModal}
      />
      <ModalConvertIntoLead
        email={managerState.activeEmail}
        onCancel={() => managerState.closeModal("openModalConvertIntoLead")}
        onSuccess={handleConvertIntoLead}
        open={managerState.openModalConvertIntoLead}
      />
      <ModalImportManagedUser
        instanceLabel={"client"}
        loading={managerState.loading}
        onCancel={() => managerState.closeModal("importManagedUserModal")}
        onFinish={handleImportClients}
        open={managerState.importManagedUserModal}
      />
      <ModalNextManagedUserData
        handleClose={() => managerState.closeModal("addManagedUserModal")}
        open={managerState.addManagedUserModal}
      />
    </StyleProvider>
  );
};

export default PageNextClients;
