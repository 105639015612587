import dayjs from "dayjs";
import { Space, Typography } from "antd";

import { openFile } from "../../../../../utils/requests/manager";

import { ReactComponent as File } from "../../../../../icons/file.svg";

const FileCard = ({ fileData }) => (
  <Space
    align="center"
    size={20}
    style={{ width: 240, cursor: "pointer" }}
    onClick={() => openFile(fileData?.id)}
  >
    <File />
    <Space direction="vertical" size={6}>
      <Typography.Text style={{ fontWeight: 500 }}>
        {fileData?.name}
      </Typography.Text>
      <Typography.Text>
        {dayjs(fileData?.createDateTime?.$date).format("M/DD/YYYY")}
      </Typography.Text>
    </Space>
  </Space>
);

export default FileCard;
