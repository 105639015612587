import styled from "styled-components";
import { Button, Flex, Modal } from "antd";

import Close from "../../icons/Close";

const StyledModal = styled(Modal)`
  &.ant-modal {
    .ant-modal-close {
      top: 26px;
      right: 32px;
    }

    .ant-modal-content {
      padding: 30px 30px 36px;
    }

    .ant-modal-title {
      font-weight: 800;
    }

    .ant-modal-header {
      margin-bottom: 25px;
    }

    .ant-modal-body {
      color: #0d1238;
      background: #edf0f2;
      padding: 49px 110px 40px;
      text-align: center;
      border-radius: 12px;

      .title {
        margin: 0 0 8px;
      }

      .description {
        margin: 0;
      }
    }

    .ant-modal-footer {
      margin-top: 26px;
    }
  }
`;

const ModalArchiveManagedUser = ({
  instanceName = "client",
  loading,
  onCancel,
  onConfirm,
  open,
}) => (
  <StyledModal
    closeIcon={<Close />}
    footer={
      <Flex justify={"space-between"}>
        <Button onClick={onCancel} shape={"round"} type={"text"}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={onConfirm}
          shape={"round"}
          style={{ background: "#03494E", color: "#fff" }}
        >
          Yes, archive this {instanceName}
        </Button>
      </Flex>
    }
    onCancel={onCancel}
    open={open}
    title={"Confirm archive"}
    width={561}
  >
    <h3 className={"title"}>Do you wish to archive this {instanceName}?</h3>
    <p className={"description"}>
      They will not show up in your {instanceName}s section moving forward.
    </p>
  </StyledModal>
);

export default ModalArchiveManagedUser;
