import styled from "styled-components";
import { Input, Radio, Space, Typography } from "antd";

const { Text } = Typography;

const StyledSpace = styled(Space)`
  width: 100%;
`;

const AddAdvisorInformationTab = ({
  newAdvisorData,
  handleValueChange,
  organization,
}) => (
  <Space direction="vertical" size={20}>
    <StyledSpace direction="vertical">
      <Text>Name</Text>
      <Space>
        <Input
          className="input-custom"
          placeholder="First Name"
          value={newAdvisorData.firstName}
          onChange={event => handleValueChange("firstName", event.target.value)}
        />
        <Input
          className="input-custom"
          placeholder="Last Name"
          value={newAdvisorData.lastName}
          onChange={event => handleValueChange("lastName", event.target.value)}
        />
      </Space>
    </StyledSpace>
    <StyledSpace direction="vertical">
      <Text>Email</Text>
      <Input
        className="input-custom"
        placeholder="example@provider.com"
        value={newAdvisorData.email}
        onChange={event => handleValueChange("email", event.target.value)}
      />
    </StyledSpace>
    <StyledSpace direction="vertical">
      <Text>Phone Number (optional)</Text>
      <Input
        className="input-custom"
        onChange={e => handleValueChange("phoneNumber", e.target.value)}
        placeholder="Enter phone number"
        style={{ padding: "4px 11px" }}
        value={newAdvisorData.phoneNumber}
      />
    </StyledSpace>
    {organization === "oneascent" && (
      <StyledSpace direction="vertical">
        <Text>Choose Onboarding Questionnaire</Text>
        <Radio.Group
          value={newAdvisorData.onboardingQuestionnaireType}
          onChange={event =>
            handleValueChange("onboardingQuestionnaireType", event.target.value)
          }
        >
          <Radio value="wealthManagement">Wealth Management</Radio>
          <Radio value="IPQ">IPQ (Investor Profile Questionnaire)</Radio>
        </Radio.Group>
      </StyledSpace>
    )}
    <StyledSpace direction="vertical">
      <Text>Type</Text>
      <Radio.Group
        onChange={event =>
          handleValueChange("independent", event.target.value === "independent")
        }
        value={newAdvisorData.independent ? "independent" : "brokerDealer"}
      >
        <Radio value="brokerDealer">Broker/Dealer</Radio>
        <Radio value="independent">Independent</Radio>
      </Radio.Group>
    </StyledSpace>
    {newAdvisorData.independent ? (
      <StyledSpace direction="vertical">
        <Text>Firm Name (optional)</Text>
        <Input
          className="input-custom"
          placeholder="Agency Name"
          value={newAdvisorData.agencyName}
          onChange={event =>
            handleValueChange("agencyName", event.target.value)
          }
        />
      </StyledSpace>
    ) : (
      <StyledSpace direction="vertical">
        <Text>Broker/Dealer Name (optional)</Text>
        <Input
          className="input-custom"
          placeholder="Agency Name"
          value={newAdvisorData.brokerDealerName}
          onChange={event =>
            handleValueChange("brokerDealerName", event.target.value)
          }
        />
      </StyledSpace>
    )}
  </Space>
);

export default AddAdvisorInformationTab;
