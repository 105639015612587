import { useContext, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import State from "../../../../../../context/manager/State";
import { Button, Flex, Input, Row, Table, theme } from "antd";
import MenuActions from "../../../../../../atoms/MenuActions";
import ModalArchiveManagedUser from "../../../../../../molecules/ModalArchiveManagedUser";
import ModalConvertClient from "../ModalConvertClient";

import {
  archiveLead,
  openProposal,
} from "../../../../../../utils/requests/manager";
import {
  handleCreateProposal,
  isAdminView,
} from "../../../../../../utils/helpers/specialized";
import { getCurrencyValue } from "../../../../../../utils/helpers/general";

const StyledTable = styled(Table)`
  &.ant-table-wrapper tr > td:nth-child(5),
  &.ant-table-wrapper tr > th:nth-child(5),
  &.ant-table-wrapper
    .ant-table-tbody
    > tr.ant-table-row:hover
    > td:nth-child(5) {
    background: #6dc1c730;
  }
`;

const getColumns = ({
  managerState,
  handleConvert,
  handleArchiveClientActionClick,
  searchedText,
  token,
}) => [
  {
    dataIndex: "clientName",
    filteredValue: [searchedText],
    key: "clientName",
    onFilter: (value, record) =>
      String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
      String(record.email).toLowerCase().includes(value.toLowerCase()),
    render: (text, restValues) =>
      restValues.proposalSaved ? (
        <a onClick={() => openProposal({ userEmail: restValues.email })}>
          {text}
        </a>
      ) : (
        text
      ),
    sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    title: "Client Name",
  },
  {
    dataIndex: "email",
    key: "email",
    render: value => <a href={"mailto:" + value}>{value}</a>,
    sorter: (a, b) => a.email.localeCompare(b.email),
    title: "Email",
  },
  {
    title: "Lead Type",
  },
  {
    render: () => "Goals",
    title: "Planning Type",
  },
  {
    align: "right",
    dataIndex: "assets",
    key: "assets",
    render: value => getCurrencyValue(value),
    sorter: (a, b) => a.assets - b.assets,
    title: "Assets",
  },
  {
    align: "right",
    dataIndex: "updateDateTime",
    key: "updateDateTime",
    render: (value, restValues) =>
      restValues.proposalSaved ? (
        dayjs(value).format("M/DD/YYYY")
      ) : (
        <Flex justify={"end"}>
          <Button
            shape="round"
            size="small"
            style={{
              borderColor: token.colorSecondaryButton,
              color: token.colorSecondaryButton,
            }}
            onClick={() => handleCreateProposal(restValues.email, managerState)}
            disabled={isAdminView()}
          >
            Create Proposal
          </Button>
        </Flex>
      ),
    sorter: (a, b) => dayjs(a.updateDateTime) - dayjs(b.updateDateTime),
    title: "Proposal Generated",
  },
  {
    align: "right",
    dataIndex: "lastAdvisorLoginTime",
    key: "lastAdvisorLoginTime",
    render: it => it?.$date && dayjs(it.$date).format("M/DD/YYYY"),
    title: "Last Log-in",
  },
  {
    align: "right",
    dataIndex: "lastAdvisorUpdateTime",
    key: "lastAdvisorUpdateTime",
    render: it => it?.$date && dayjs(it.$date).format("M/DD/YYYY"),
    title: "Last Update",
  },
  {
    className: "actions-menu-table-cell",
    dataIndex: "actionsMenu",
    key: "actionsMenu",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          email: restValues.email,
          handleArchiveClientActionClick,
          handleConvert,
        })}
      />
    ),
    title: "",
    width: 40,
  },
];

const getItems = ({ email, handleConvert, handleArchiveClientActionClick }) => [
  {
    key: "move",
    label: (
      <div
        onClick={() => {
          handleConvert(email);
        }}
      >
        Move to client
      </div>
    ),
  },
  {
    type: "divider",
  },
  {
    key: "archive",
    label: (
      <div
        style={{ color: "red" }}
        onClick={() => handleArchiveClientActionClick(email)}
      >
        Archive
      </div>
    ),
  },
];

const TableLevelClients = () => {
  const { token } = theme.useToken();
  const [managerState] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  useState(false);
  const [isConvertModalOpened, setIsConvertModalOpened] = useState(false);

  const handleConvert = email => {
    managerState.setKeyValue("activeEmail", email);
    setIsConvertModalOpened(true);
  };

  const handleArchiveClient = () => {
    managerState.closeModal("archiveManagedUserModal");
    managerState.showLoader();

    archiveLead({
      email: managerState.activeEmail,
      userManagerEmail: managerState._id,
    })
      .then(() =>
        managerState.setAdminData().then(() => {
          managerState.hideLoader();
          managerState.setKeyValue("activeEmail", null);
          managerState.showSuccess(
            `Client ${managerState.activeEmail} archived`
          );
        })
      )
      .catch(error => {
        console.log(error);
        managerState.hideLoader();
      });
  };

  const handleArchiveClientActionClick = email => {
    managerState.setKeyValue("activeEmail", email);
    managerState.openModal("archiveManagedUserModal");
  };

  const getTableData = () =>
    managerState?.getManagedUsers()?.map(it => ({
      ...it,
      actionsMenu: { email: it.email },
      assets: it.valueMap?.productMap
        ? Object.keys(it.valueMap.productMap)
            .map(
              key =>
                it.valueMap.productMap[key][
                  it.valueMap.productMap[key].length - 1
                ].investmentAmount
            )
            .reduce((acc, value) => acc + value, 0)
        : it.proposedAmount ?? 0,
      clientName: it.lastName + ", " + it.firstName,
      key: it.email,
      updateDateTime: it.updateDateTime?.$date,
      proposalSaved: it.proposalSavedDate ?? it.valueMap?.productMap,
    }));

  return (
    <>
      <Row>
        <Input
          style={{
            maxWidth: 290,
            height: 37,
            marginBottom: 16,
            borderRadius: 25,
          }}
          placeholder="Search Clients..."
          onChange={event => setSearchedText(event.target.value)}
        />
      </Row>
      <StyledTable
        dataSource={getTableData()}
        columns={getColumns({
          managerState,
          handleConvert,
          handleArchiveClientActionClick,
          searchedText,
          token,
        })}
        pagination={false}
        loading={managerState.loading}
      />

      <ModalArchiveManagedUser
        loading={managerState.loading}
        onCancel={() => managerState.closeModal("archiveManagedUserModal")}
        onConfirm={handleArchiveClient}
        open={managerState.archiveManagedUserModal}
      />
      <ModalConvertClient
        email={managerState.activeEmail}
        handleClose={() => setIsConvertModalOpened(false)}
        open={isConvertModalOpened}
      />
    </>
  );
};

export default TableLevelClients;
